import React from 'react';
import { Grid, Container } from '@material-ui/core';
import { SeoHelmet } from '../../utils/seoHelmet';
import SeoTexts from '../../constants/seoConstants';
import './style.js';
import { Privacysec } from './style';

const TermsOfUse = () => (
  <>
    <SeoHelmet title={SeoTexts.TERMS_OF_USE_TITLE} />
    <Privacysec>
      <Container textAlign="center" className="privacy-sec">
        <Grid container spacing={3}>
          <Grid item xs={12} >
            <h1>Terms and Conditions</h1>
            <p> PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE </p>
            <p className="color-light"> Last Updated: July 2021 </p>
          </Grid>
          <Grid item xs={12} className="content">
            <h3> PLEASE READ THESE TERMS CAREFULLY BEFORE USING THIS SITE </h3>
            <h3> Terms of use </h3>
            <p>The terms of use (together with the documents referred to in them) define the terms on which you may make use of our website <a href="https://stage.gymclass.net/">GymClass</a> (“our site”), whether as a guest or a member. Use of our site includes accessing, browsing, making a search, creating an alert, and creating an account </p>
            <p> Please read these terms of use carefully before you start to use our site, as these will apply to your use of our site. </p>
            <p> By using our site, you confirm that you accept these terms of use and that you agree to comply with them. </p>
            <p> If you do not agree to these terms of use, you must not use our site. </p>
            <br />
            <h3> Other applicable terms </h3>
            <p> These terms of use refer to the following additional terms, which also apply to your use of our site: </p>
            <ul>
              <li>Our <a href="/privacy-policy">Privacy Policy</a>, which sets out the terms on which we process any personal data we collect from you, or that you provide to us. By using our site, you consent to such processing and you warrant that all data provided by you is accurate. </li>
              <li>Our <a href="/cookie-policy">Cookie Policy</a>, which sets out information about the cookies on our site. </li>
            </ul>
            <br />
            <h3> Information about us </h3>
            <p>
              <a href="https://stage.gymclass.net/">GymClass</a>&nbsp; is a site operated by GymClass is the trading name (formerly BA Redemption Finder). Our registered office is International House, 12 Constance Street, London, E16 2DQ.&nbsp;
            </p>
            <p> GymClass is not affiliated with British Airways, Avios or any other airline. We are registered with the Information Commissioner’s Office and for VAT (GB 330181250) </p>
            <br />
            <h2> Changes to these terms </h2>
            <p> We may revise these terms of use at any time by amending this page. </p>
            <p> Please check this page from time to time to take notice of any changes we made, as they are binding on you to the extent you use our site.</p>
            <br />
            <h3> Subscription Pricing </h3>
            <p> Our site is made available on the pricing terms outlined on our Pricing Page (link). These pricing terms may change from time to time. You can cancel your monthly payments at any time. You will receive your current benefits for the remainder of the billing period. No refunds will be given. </p>
            <p> With all promotional offers, cancelling or changing your subscription class will terminate the discount offered under the promotion. </p>
            <p> We reserve the right to increase the renewal fee upon our giving to you in writing at least 28 days’ notice prior to a renewal period. </p>
            <br />
            <h2> Access and Use </h2>
            <p> We do not guarantee that our site will always be available or be uninterrupted. We may suspend, withdraw, discontinue or change all or any part of our site without notice. We will not be liable to you if, for any reason, our site is unavailable at any time or for any period. </p>
            <p> We may update our site from time to time and may change the content at any time. </p>
            <p> Access to our site is permitted on a temporary basis only. </p>
            <br />
            <h2> Intellectual property rights</h2>
            <p>We are the owners or licensee of all intellectual property rights in our site. This includes, but is not limited to, copyright (including copyright in computer software) patents, trademarks, business names, design rights (the look, feel, layout, design, graphics, user flow, colours, and general appearance), and database rights. All rights are reserved. </p>
            <p> You must not use any part of the content on our site for commercial purposes (for example, by selling to third parties the services we offer on our site).</p>
            <br />
            <h2> No reliance on information </h2>
            <p> The content on our site, including our alerts, is provided for information only. It is not intended to amount to advice on which you should rely. </p>
            <p> We do not guarantee that our site or any content on it, will be free from errors or omissions, and content may sometimes be out of date. We make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up to date. </p>
            <br />
            <h2> Limitation of our liability </h2>
            <p> To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to our site or any content on it, whether express or implied. </p>
            <p> We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with: </p>
            <ul>
              <li className="plan-icon"> use of, or inability to use, our site; </li>
              <li className="plan-icon"> use of or reliance on any content (including our alerts) </li>
            </ul>
            <p> If you are a consumer user, please note that we only provide our site for domestic and private use. You agree not to use our site for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity. </p>
            <p> We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our site and or to your downloading of any content on it, or on any website linked to it. </p>
            <p> We assume no responsibility for the content of websites linked on our site. Such links should not be interpreted as endorsement by us of those linked websites. We will not be liable for any loss or damage that may arise from your use of them. </p>
            <p> Subject to these Terms and to the extent permitted by law, our maximum aggregate liability to you arising out of or in connection with these Terms shall be limited to one month’s subscription. </p>
            <br />
            <h3> Viruses </h3>
            <p> We do not guarantee that our site will be secure or free from bugs or viruses. </p>
            <p> You are responsible for configuring your information technology, computer programs and platform in order to access our site. You should use your own virus protection software. </p>
            <p> You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of-service attack. </p>
            <p> By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately. </p>
            <br />
            <h3> Termination </h3>
            <p> GymClass may in its absolute discretion immediately terminate any agreement with you under these Terms at any time upon notice to you and/or, if you have a log-in account, by cancelling your membership and your access to your account. </p>
            <h2> Other Important Terms </h2>
            <p> We may transfer our rights and obligations under these terms to another organisation in the event of an acquisition. </p>
            <p> You may only transfer your rights or your obligations under these terms to another person if we agree to this in writing. </p>
            <p> This contract is between you and us. No other person shall have any rights to enforce any of its terms. Neither of us will need to get the agreement of any other person in order to end the contract or make any changes to these terms. </p>
            <h2> These Terms and any dispute arising out of these Terms are governed by the law of England. </h2>
          </Grid>
        </Grid>
      </Container>
    </Privacysec>
  </>
    );

export default TermsOfUse;
