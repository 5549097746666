import { createMuiTheme } from '@material-ui/core/styles';
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#f4bdac'
    },
    secondary: {
      main: '#313B67'
    },
    default: {
      main: '#ffffff'
    }
  },
  status: {
    danger: 'orange'
  },
  text: {
    primary: '#121729',
    secondary: '#5d678e',
    disabled: '#A1A1A1'
  },
  typography: {
    fontFamily: [
      'Fira Sans',
      'sans-serif'
    ].join(','),
    fontSize: 16,
    fontWeight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    useNextVariants: true
  },
  overrides: {
    MuiTypography: {
      body1: {
          fontSize: '14px'
        }
    },
    MuiMenuItem: {
      root: {
        fontSize: '14px'
      }
    },
    search: {
      root: {
        borderRadius: '888px'
      }
    },
    MuiFormControl: {
      root: {

      }
    },
    MuiButton: {
      root: {
        borderRadius: '5px',
        fontWeight: 300,
        '&:hover': {
          boxShadow: '0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)'
        }
      },
      contained: {
        boxShadow: 'none'
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#ffffff'
      }
    },
    MuiBackdrop: {
      root: {
        background: 'rgba(0, 0, 0, 0.75)'
      }
    },
    MuiDialog: {
      paper: {
        borderRadius: '5px',
        boxShadow: 'none'
      },
      paperWidthMd: {
        maxWidth: '650px'
      }
    },
    MuiDialogTitle: {
      root: {
        padding: '26px 24px 12px',
        color: '#E34C58',
        fontSize: '22px',
        fontWeight: 500
      }
    },
    MuiDialogContent: {
      root: {
        color: '#A1A1A1',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 300
      }
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'flex-start',
        padding: '12px 24px'
      }
    },
    MuiDialogContentText: {
      root: {
        color: '#A1A1A1',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 300
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: '5px'
      },
      elevation8: {
        boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.05)',
        border: '0.5px solid #DDDDDD'
      },
      elevation4: {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
      }
    }
  }
});

export default theme;
