import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { bestTimeData, gymsName } from '../../../utils/globalConstants';
import { Button } from '@material-ui/core';
import { FilterPopper, TagWrap } from '../assets/style';
import CreatableSelect from 'react-select/creatable';

const Filters = (props) => {
  const { handlerSetFilterState, gymsNameList, toggalFilters, filterData,
    filterSelectedGymsData } = props;

  const handlerSelect = (name, value) => {
    const arr = [...filterData];
    const index = arr.findIndex((data) => data.value === value.value);

    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push(value);
    }
    handlerSetFilterState(arr);
    filterSelectedGymsData(arr);
  };

  const handlerUnSelectValue = (newValue, actionMeta) => {
    if (actionMeta && actionMeta.action && actionMeta.action === 'remove-value' && actionMeta.removedValue && actionMeta.removedValue.value) {
      const arr = [...filterData];
      const index = arr.findIndex((data) => data.value === actionMeta.removedValue.value);
      if (index > -1) {
        arr.splice(index, 1);
      }
      handlerSetFilterState(arr);
      filterSelectedGymsData(arr);
    } else if (actionMeta && actionMeta.action && actionMeta.action === 'clear') {
      handlerSetFilterState([]);

    }
  };

  return (
    <Fragment>
      <TagWrap>
        <CreatableSelect
          value={filterData}
          isSearchable={false}
          noOptionsMessage={() => null}
          isMulti
          onChange={(newValue, actionMeta) => handlerUnSelectValue(newValue, actionMeta)}
          options={[]}
          className="tagSelect"
        />
      </TagWrap>
      <div style={{ display: toggalFilters ? 'block' : 'none' }}>
        <FilterPopper>
          <div className="bg-wrapper">
            <strong>Gyms</strong>
            {gymsName.map((data) => (<Button className={filterData.filter(item => item.value === data.value).length ? 'selected' : ''} key={data.value} onClick={() => handlerSelect('gymsFilter', data)}>{data.label}</Button>))}
            <strong>Locations</strong>
            {gymsNameList.map((data) => (<Button className={filterData.filter(item => item.value === data.value).length ? 'selected' : ''} key={data.value} onClick={() => handlerSelect('locationFilter', data)}>{data.label.gymBrand || '-'}</Button>))}
            <strong>Best Time</strong>
            {bestTimeData.map((time, index) => (<Button key={index + 1} className={filterData.filter(item => item.value === time.value).length ? 'selected' : ''} onClick={() => handlerSelect('bestTimeFilter', time)}>{time.label}</Button>))}
          </div>
        </FilterPopper>
      </div>
    </Fragment>
  );
};


Filters.propTypes = {
  gymsNameList: PropTypes.array,
  toggalFilters: PropTypes.bool,
  filterData: PropTypes.array,
  handlerSetFilterState: PropTypes.func,
  filterSelectedGymsData: PropTypes.func
};

export default Filters;
