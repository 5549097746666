export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_LOADING = 'LOGIN_REQUEST_LOADING';
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_FAILURE = 'LOGIN_REQUEST_FAILURE';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_LOADING = 'REGISTER_USER_LOADING';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';

export const INITIALIZE_USER = 'INITIALIZE_USER';

export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_LOADING = 'GET_USER_DETAILS_LOADING';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILURE = 'GET_USER_DETAILS_FAILURE';

export const GYMBOX_LOGIN = 'GYMBOX_LOGIN';
export const GYMBOX_LOGIN_LOADING = 'GYMBOX_LOGIN_LOADING';
export const GYMBOX_LOGIN_SUCCESS = 'GYMBOX_LOGIN_SUCCESS';
export const GYMBOX_LOGIN_FAILURE = 'GYMBOX_LOGIN_FAILURE';

export const FORGOT_PASSWORD_INITIATE = 'FORGOT_PASSWORD_INITIATE';
export const FORGOT_PASSWORD_INITIATE_LOADING = 'FORGOT_PASSWORD_INITIATE_LOADING';
export const FORGOT_PASSWORD_INITIATE_SUCCESS = 'FORGOT_PASSWORD_INITIATE_SUCCESS';
export const FORGOT_PASSWORD_INITIATE_FAILURE = 'FORGOT_PASSWORD_INITIATE_FAILURE';

const Texts = {

  // Common texts
  EMAIL_FIELD_LABEL: 'Email Address',
  EMAIL_FIELD_PLACEHOLDER: 'Email Address',
  PASSWORD_FIELD_LABEL: 'Password',
  PASSWORD_FIELD_PLACEHOLDER: 'Password',
  CONFIRM_PASSWORD_FIELD_LABEL: 'Confirm Password',
  CONFIRM_PASSWORD_FIELD_PLACEHOLDER: 'Password'
};

export default Texts;
