
// Reset password
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_REQUEST_LOADING = 'RESET_PASSWORD_REQUEST_LOADING';
export const RESET_PASSWORD_REQUEST_SUCCESS = 'RESET_PASSWORD_REQUEST_SUCCESS';
export const RESET_PASSWORD_REQUEST_FAILURE = 'RESET_PASSWORD_REQUEST_FAILURE';


const Texts = {
  // Common texts
  PASSWORD_FIELD_LABEL: 'Password',
  PASSWORD_FIELD_PLACEHOLDER: 'New Password',
  CONFIRM_PASSWORD_FIELD_LABEL: 'Confirm Password',
  CONFIRM_PASSWORD_FIELD_PLACEHOLDER: 'Confirm Password'
};

export default Texts;
