import axios from 'axios';
import browserHistory from './utils/history';
import { retrieveFromLocalStorage, removeFromLocalStorage } from './utils/helpers';
import { pushNotification } from './utils/notifications';
import Messages from './constants/messages';
import env from './utils/env_variables';

const axiosAuth = axios.create({
    baseURL: env.BASE_URL,
    withCredentials: false,
    crossDomain: true,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
});

axiosAuth.interceptors.request.use(config => {
    const token = retrieveFromLocalStorage('token');

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

axiosAuth.interceptors.response.use(
    response => {
        if (response && response.data && response.data.error) {
            return Promise.reject(response);
        }
        return response;
    },
    error => {
        if (error && error.response && error.response.status === 401) {
            removeFromLocalStorage('token');
            pushNotification(Messages.SESSION_EXPIRE, 'error');
            browserHistory.replace('/');
        }
        return Promise.reject(error);
    }
);

export default axiosAuth;
