import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import {
  Grid, Container, IconButton, Divider, InputBase, Select, MenuItem, FormControl, InputLabel
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

import {
  SiteTitle, CalenderBTN, ThemeTable, SearchComponent, PageFilter, CalenderBTNModule
} from './assets/style.js';
import {
  SearchButtonSvg, CalenderButtonSvg
} from '../../utils/svgs';
import { instructoreDropDown, gymsName } from '../../utils/globalConstants';
import Modal from './components/modal';
import Table from './components/table';
import { pushNotification } from '../../utils/notifications';
import Messages from '../../constants/messages';
import SortByRating from '../Common/components/sortByRating';
import { SeoHelmet } from '../../utils/seoHelmet';

const MySchedule = (props) => {
  const { myScheduleList, getMyScheduleList, gymsNameList, getGymsList, cancelClass,
    cancelClassLoading, cancelPrebookClass } = props;
  const [scheduleDetails, setScheduleDetails] = useState({
    toggalCalender: false,
    toggalModal: false,
    toggleSearchBar: false,
    toggleButton: false,
    toggalSortMenu: false,
    gymClass: '',
    gymLocation: '',
    gymInstructore: '',
    selectedGymDetails: {},
    gymClassId: null
  });

  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    if (!gymsNameList.length) {
      getGymsList('GymBox');
    }
    getMyScheduleList();
     // eslint-disable-next-line
  }, []);

  const handlerSetToggleDetails = (name) => {
    setScheduleDetails({
      ...scheduleDetails,
      [name]: !scheduleDetails[name]
    });
  };

  const handleDateChange = date => {
    setSelectedDate(date);
    handlerSetToggleDetails('toggalCalender');
  };

  const sortedClasses = (value) => {
    console.log('value', value);
  };

  const handlerToggleButton = (value, details) => {
    if (value === 'cancelClass') {
      if (details.status === 'BOOKED') {
        const cancelDetails = {
          gymClassId: details.gymClassId,
          userId: details.userId,
          userScheduleId: details.userScheduleId
        };
        cancelClass(details.gymBrand, cancelDetails)
          .then(response => {
            if (response && response.action && response.action.payload && response.action.payload.data &&
              response.action.payload.data.status === 'SUCCESS') {
              setScheduleDetails({
                ...scheduleDetails,
                gymClassId: null,
                toggleButton: !scheduleDetails.toggleButton
              });
              getMyScheduleList();
            }
          });
      } else {
        pushNotification(Messages.PREBOOK_CLASS, 'error', 'TOP_CENTER', 3000);
      }
    } else if (value === 'cancellRecurring') {
      if (details.status === 'AUTOBOOK' || details.status === 'PREBOOK') {
        const cancelDetails = {
          gymClassId: details.gymClassId,
          userId: details.userId
        };
        cancelPrebookClass(cancelDetails)
          .then(response => {
            if (response && response.action && response.action.payload && response.action.payload.data &&
              response.action.payload.data.status === 'SUCCESS') {
              setScheduleDetails({
                ...scheduleDetails,
                gymClassId: null,
                toggleButton: !scheduleDetails.toggleButton
              });
              getMyScheduleList();
            }
          });
      } else {
        pushNotification(Messages.CLASS_BOOK, 'error', 'TOP_CENTER', 3000);
      }

    } else {
      details = {
        ...details,
        buttonType: value
      };
      scheduleDetails.selectedGymDetails = details;
      scheduleDetails.toggalModal = !scheduleDetails.toggalModal;
      setScheduleDetails({
        ...scheduleDetails
      });
    }
  };

  const handlerFilterDetails = (name, value) => {
    setScheduleDetails({
      ...scheduleDetails,
      [name]: value
    });
  };

  const handlerButtonMenu = (details) => {
    setScheduleDetails({
      ...scheduleDetails,
      gymClassId: details.gymClassId,
      toggleButton: !scheduleDetails.toggleButton
    });
  };

  const { toggleSearchBar, toggalCalender, gymClass, gymInstructore,
    selectedGymDetails, gymLocation, toggalModal, toggleButton, gymClassId, toggalSortMenu } = scheduleDetails;
  return (
    <Container>
      <SeoHelmet title="My-schedule" />
      <SiteTitle as="h2" color="secondary">My Schedule</SiteTitle>
      <Grid container spacing={3} direction="row">
        <Grid item xs={1}>
          <CalenderBTNModule>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <CalenderBTN onClick={() => handlerSetToggleDetails('toggalCalender')}>
                <CalenderButtonSvg />
              </CalenderBTN>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                classes={{ root: 'hidden-calender' }}
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Date picker inline"
                value={selectedDate}
                onChange={(date) => handleDateChange(date, 'toggalCalender')}
                open={toggalCalender}
                onClose={() => handlerSetToggleDetails('toggalCalender')}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />

            </MuiPickersUtilsProvider>
          </CalenderBTNModule>
        </Grid>
        <Grid item xs={7}>
          <PageFilter>
            {gymsName &&
              <FormControl>
                <InputLabel variant="outlined">Gym</InputLabel>
                <Select
                  value={gymClass}
                  onChange={e => handlerFilterDetails('gymClass', e.target.value)}
                  inputProps={{
                    name: 'age',
                    id: 'age-native-simple'
                  }}
                >
                  {gymsName.map((data) => (<MenuItem value={data} key={data}>{data.label}</MenuItem>))}
                </Select>
              </FormControl>}

            {gymsNameList &&
              <FormControl>
                <InputLabel variant="outlined">Location</InputLabel>
                <Select
                  value={gymLocation}
                  onChange={e => handlerFilterDetails('gymLocation', e.target.value)}
                >
                  {gymsNameList.map((data) => (<MenuItem value={data} key={data}>{data.label}</MenuItem>))}
                </Select>
              </FormControl>}

            <FormControl>
              <InputLabel variant="outlined">Instructor</InputLabel>
              <Select
                value={gymInstructore}
                onChange={e => handlerFilterDetails('gymInstructore', e.target.value)}
              >
                {instructoreDropDown.map((data) => (<MenuItem value={data.value} key={data.value}>{data.label}</MenuItem>))}
              </Select>
            </FormControl>
          </PageFilter>
        </Grid>
        <Grid item xs={4} container justify="flex-end">
          <SearchComponent className={toggleSearchBar ? 'searchInputShow' : 'searchInputhidden'}>
            <InputBase
              placeholder="Search…"
            // putProps={{ 'aria-label': 'search' }}
            />
            <IconButton type="submit" aria-label="search" onClick={() => handlerSetToggleDetails('toggleSearchBar')}>
              <SearchButtonSvg />
            </IconButton>
          </SearchComponent>
          <SortByRating
            toggalSortMenu={toggalSortMenu}
            handlerSetToggleDetails={handlerSetToggleDetails}
            sortedClasses={sortedClasses}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
          <ThemeTable>
            {myScheduleList.length &&
              <Table
                myScheduleList={myScheduleList}
                handlerToggleButton={handlerToggleButton}
                toggleButton={toggleButton}
                handlerSetToggleDetails={handlerSetToggleDetails}
                gymClassId={gymClassId}
                handlerButtonMenu={handlerButtonMenu}
                cancelClassLoading={cancelClassLoading}
              />}
          </ThemeTable>
        </Grid>
      </Grid>
      <Modal
        selectedGymDetails={selectedGymDetails}
        handlerSetToggleDetails={handlerSetToggleDetails}
        toggalModal={toggalModal}

      />
    </Container>
  );
};

MySchedule.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  classTimeTableData: PropTypes.array,
  myScheduleList: PropTypes.array,
  getMyScheduleList: PropTypes.func,
  gymsNameList: PropTypes.array,
  getGymsList: PropTypes.array,
  cancelClass: PropTypes.func,
  cancelClassLoading: PropTypes.bool,
  cancelPrebookClass: PropTypes.func
};

export default MySchedule;
