const Texts = {
  // Auth texts
  FORGOT_MY_PASSWORD: 'Forgot Password?',

  // Common texts
  EMAIL_FIELD_LABEL: 'Email Address',
  EMAIL_FIELD_PLACEHOLDER: 'Email Address',
  PASSWORD_FIELD_LABEL: 'Password',
  PASSWORD_FIELD_PLACEHOLDER: 'Password',
  CONFIRM_PASSWORD_FIELD_LABEL: 'Confirm Password',
  CONFIRM_PASSWORD_FIELD_PLACEHOLDER: 'Password',
  PASSWORD_ACCEPTANCE_LENGTH: 8

};

export default Texts;

