import {
  GET_MY_SCHEDULE_LIST_FAILURE, GET_MY_SCHEDULE_LIST_LOADING, GET_MY_SCHEDULE_LIST_SUCCESS,
  CANCEL_CLASS_FAILURE, CANCEL_CLASS_LOADING, CANCEL_CLASS_SUCCESS,
  CANCEL_PREBOOK_CLASS_LOADING, CANCEL_PREBOOK_CLASS_SUCCESS, CANCEL_PREBOOK_CLASS_FAILURE
} from './MyScheduleConstants';
import { pushNotification } from '../../utils/notifications';
import Messages from '../../constants/messages';

const initialState = {
  myScheduleList: [],
  myScheduleLoading: false,
  cancelClassLoading: false
};

const getMyScheduleListStart = (state) => ({
  ...state,
  myScheduleLoading: true
});

const getMyScheduleListSuccess = (state, action) => {
  if (action && action.payload && action.payload.status && action.payload.status === 204) {
    state.myScheduleList = [];
  } else if (action && action.payload && action.payload.data && action.payload.data.payload) {
    state.myScheduleList = action.payload.data.payload;
  }
  state.myScheduleLoading = false;
  return {
    ...state
  };
};

const getMyScheduleListFailure = (state) => ({
  ...state,
  myScheduleLoading: false
});

// cancel booked class reducers
const cancelClassStart = (state) => ({
  ...state,
  cancelClassLoading: true
});

const cancelClassSuccess = (state, action) => {
  if (action && action.payload && action.payload.data && action.payload.data.status === 'SUCCESS') {
    pushNotification(Messages.CLASS_CANCEL_SUCCESS, 'success', 'TOP_CENTER', 3000);
  }
  return ({
    ...state,
    cancelClassLoading: false
  });
};

const cancelClassFailure = (state) => ({
  ...state,
  cancelClassLoading: false
});

// cancel pre booked class reducers
const cancelPrebookClassStart = (state) => ({
  ...state,
  cancelClassLoading: true
});

const cancelPrebookClassSuccess = (state, action) => {
  if (action && action.payload && action.payload.data && action.payload.data.status === 'SUCCESS') {
    pushNotification(Messages.CLASS_CANCEL_SUCCESS, 'success', 'TOP_CENTER', 3000);
  }
  return ({
    ...state,
    cancelClassLoading: false
  });
};

const cancelPrebookClassFailure = (state) => ({
  ...state,
  cancelClassLoading: false
});

const classMyScheduleReducer = (state = initialState, action = {}) => {
  switch (action.type) {

    case GET_MY_SCHEDULE_LIST_LOADING: return getMyScheduleListStart(state, action);
    case GET_MY_SCHEDULE_LIST_SUCCESS: return getMyScheduleListSuccess(state, action);
    case GET_MY_SCHEDULE_LIST_FAILURE: return getMyScheduleListFailure(state, action);
    case CANCEL_CLASS_LOADING: return cancelClassStart(state, action);
    case CANCEL_CLASS_SUCCESS: return cancelClassSuccess(state, action);
    case CANCEL_CLASS_FAILURE: return cancelClassFailure(state, action);
    case CANCEL_PREBOOK_CLASS_LOADING: return cancelPrebookClassStart(state, action);
    case CANCEL_PREBOOK_CLASS_SUCCESS: return cancelPrebookClassSuccess(state, action);
    case CANCEL_PREBOOK_CLASS_FAILURE: return cancelPrebookClassFailure(state, action);
    default:
      return {
        ...state
      };
  }
};

export default classMyScheduleReducer;
