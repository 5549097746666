import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

export const SeoHelmet = ({ title }) => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>{title}</title>
  </Helmet>
);

SeoHelmet.propTypes = {
  title: PropTypes.string.isRequired
};
