import api from '../../api';
import URLs from '../../constants/urls';
import env from '../../utils/env_variables';
import {
  GET_TIME_TABLE_LIST, BOOK_GYM_CLASS, PRE_BOOK_GYM_CLASS,
  FILTER_SELECTED_GYMS_DATA_LOADING, FILTER_SELECTED_GYMS_DATA_SUCCESS
  , FILTER_SELECTED_GYMS_DATA_FAILURE
} from './ClassTimeTableConstants';
import { retrieveFromLocalStorage } from '../../utils/helpers';
import axios from 'axios';
var call;
export const getClassTimeTableList = (gymLocation) => {
  const auth = retrieveFromLocalStorage('token');
  let url = `${URLs.GET_TIME_TABLE_LIST}/${gymLocation}`;
  if (!auth) {
    url = `publicTimeTable?gymBrands=["GymBox"]&gymLocations=["${gymLocation}"]`;
  }
  return ({
    type: GET_TIME_TABLE_LIST,
    payload: api.get(url)
  });
};

export const bookGymClass = (gymBrand, gymDetails) => ({
  type: BOOK_GYM_CLASS,
  payload: api.post(`${URLs.BOOK_GYM_CLASS_URL}?gymBrand=${gymBrand}`, gymDetails)
});

export const preBookGymClass = (gymDetails) => ({
  type: PRE_BOOK_GYM_CLASS,
  payload: api.post(URLs.PRE_BOOK_GYM_CLASS_URL, gymDetails)
});

// filter data

export const startFilterSelectedGymsData = () => ({
  type: FILTER_SELECTED_GYMS_DATA_LOADING
});

export const successFilterSelectedGymsData = (data) => ({
  type: FILTER_SELECTED_GYMS_DATA_SUCCESS,
  data: data
});

export const failFilterSelectedGymsData = (err) => ({
  type: FILTER_SELECTED_GYMS_DATA_FAILURE,
  error: err
});

export const filterSelectedGymsData = (filterDetails) => {
  const gymsArray = [];
  const locationArray = [];
  const timeArray = [];

  filterDetails.map((item) => {
    if (item.type === 'location') {
      locationArray.push(item.label);
    } else if (item.type === 'gyms') {
      gymsArray.push(item.value);
    } else {
      timeArray.push(item.value);
    }
    return true;
  });

  const auth = retrieveFromLocalStorage('token');

  let url = `${env.BASE_URL}${URLs.FILTER_DATA_AFTER_LOGIN_URL}?`;
  if (!auth) {
    url = `${env.BASE_URL}${URLs.FILTER_DATA_BEFORE_LOGIN_URL}?`;
  }

  if (!gymsArray.length && !locationArray.length && !timeArray.length) {
    url = `${env.BASE_URL}${URLs.GET_TIME_TABLE_LIST}/Bank`;
    if (!auth) {
      url = `${env.BASE_URL}${URLs.FILTER_DATA_BEFORE_LOGIN_URL}?gymBrands=["GymBox"]&gymLocations=["Bank"]`;
    }
  } else {
    if (gymsArray.length) {
      url = `${url}gymBrands=${JSON.stringify(gymsArray)}`;
    }
    if (locationArray.length) {
      url = `${url}${gymsArray.length ? '&' : ''}gymLocations=${JSON.stringify(locationArray)}`;
    }
    if (timeArray.length) {
      url = `${url}${(gymsArray.length || locationArray.length) ? '&' : ''}time=${JSON.stringify(timeArray)}`;
    }
  }

  if (call) {
    call.cancel();
  }
  call = axios.CancelToken.source();
  return dispatch => {
    dispatch(startFilterSelectedGymsData());
    return api.get(url, { cancelToken: call.token }).then((response) => {
      if (response && response.data && response.data.payload) {
        dispatch(successFilterSelectedGymsData(response.data.payload));
      }
      return response;
    }).catch(error => {
      dispatch(failFilterSelectedGymsData(error));
      return error;
    });
  };
};
