import { connect } from 'react-redux';
import ClassTimeTable from './index';
import { getClassTimeTableList, bookGymClass, preBookGymClass, filterSelectedGymsData } from './ClassTimeTableActions';
import { gymBoxLogin, getUserDetails } from '../Auth/AuthActions';
import { updateReducerState, getGymsList } from '../Common/CommonActions';

const mapStateToProps = state => ({
  timeTableList: state.timeTable.timeTableList,
  timeTableDates: state.timeTable.timeTableDates,
  timeTableLoading: state.timeTable.timeTableLoading,
  timeTableArray: state.timeTable.timeTableArray,
  userDetails: state.auth.userDetails,
  bookClassLoading: state.timeTable.bookClassLoading,
  toggalAuthModal: state.header.toggalAuthModal,
  gymsNameList: state.common.gymsNameList
});

const mapDispatchToProps = dispatch => ({
  getClassTimeTableList: (gymLocation) => dispatch(getClassTimeTableList(gymLocation)),
  bookGymClass: (gymBrand, gymDetails) => dispatch(bookGymClass(gymBrand, gymDetails)),
  preBookGymClass: (gymDetails) => dispatch(preBookGymClass(gymDetails)),
  gymBoxLogin: (loginDetails) => dispatch(gymBoxLogin(loginDetails)),
  getUserDetails: () => dispatch(getUserDetails()),
  getGymsList: (gymBrand) => dispatch(getGymsList(gymBrand)),
  updateReducerState: (reducerKey, key, value) => dispatch(updateReducerState(reducerKey, key, value)),
  filterSelectedGymsData: (filterDetails) => dispatch(filterSelectedGymsData(filterDetails))
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassTimeTable);
