const URLs = {
  GET_GYMS_NAME: '/gymLocations?gymBrand=',
  GET_TIME_TABLE_LIST: '/timetable/GymBox',
  GET_MY_SCHEDULE_LIST: '/schedule',
  BOOK_GYM_CLASS_URL: '/bookClass',
  PRE_BOOK_GYM_CLASS_URL: '/prebookclass',
  INITIALIZE_USER_URL: '/gymbox/init',
  GET_USER_DETAILS_URL: '/user',
  LOGIN_URL: '/login',
  REGISTER_USER_URL: '/user',
  GYMBOX_LOGIN_URL: '/addGymBrandCreds',
  CANCEL_CLASS_URL: '/cancel',
  CANCEL_PRE_BOOK_CLASS: '/cancelprebook',
  FORGOT_PASSWORD_URL: '/forgotPassword',
  RESET_PASSWORD_URL: '/resetPassword',
  FACEBOOK_LOGIN_URL: '/auth/facebook/token',
  GOOGLE_LOGIN_URL: '/auth/google/token',
  FILTER_DATA_AFTER_LOGIN_URL: '/searchGymClasses',
  FILTER_DATA_BEFORE_LOGIN_URL: '/publicTimetable',
  VERIFY_USER_EMAIL_URL: '/email/verify'
};

export default URLs;

