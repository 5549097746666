
export const classTimeTableData = [
  { time: ' 11:15am', timeM: '45mins', class: 'BIKE & BEATS', booking: 'BOOK', name: 'Richard Simmons', content: 'Bike & Beats is the class to make spin everyone’s thing', gymLocation: 'Bank' },
  { time: ' 12:15am', timeM: '45mins', class: 'BEATS', booking: 'PRBOOK', name: 'Richard Simmons', content: 'Bike & Beats is the class to make spin everyone’s thing', gymLocation: 'Bank' },
  { time: ' 13:15am', timeM: '45mins', class: 'SUSPENDERS', booking: 'BOOK', name: 'Richard Simmons', content: 'Bike & Beats is the class to make spin everyone’s thing', gymLocation: 'Bank' },
  { time: ' 14:15am', timeM: '45mins', class: 'GYMBOXING', booking: 'PRBOOK', name: 'Richard Simmons', content: 'Bike & Beats is the class to make spin everyone’s thing', gymLocation: 'Bank' },
  { time: ' 15:15am', timeM: '45mins', class: 'SWINGERS CLUB', booking: 'PRBOOK', name: 'Richard Simmons', content: 'Bike & Beats is the class to make spin everyone’s thing', gymLocation: 'Bank' },
  { time: ' 16:15am', timeM: '45mins', class: 'BIKE & BEATS', booking: 'BOOK', name: 'Richard Simmons', content: 'Bike & Beats is the class to make spin everyone’s thing', gymLocation: 'Bank' },
  { time: ' 17:15am', timeM: '45mins', class: 'SWINGERS CLUB', booking: 'PRBOOK', name: 'Richard Simmons', content: 'Bike & Beats is the class to make spin everyone’s thing', gymLocation: 'Bank' }
];


// export const sliderDateFliter = [
//   { date: '29th Jan,20', week: 'Sunday' },
//   { date: '30th Jan,20', week: 'Monday' },
//   { date: '31th Jan,20', week: 'Tuesday' },
//   { date: '1st Feb,20', week: 'Wednesday' },
//   { date: '2th Feb,20', week: 'Thursday' },
//   { date: '3rd Feb,20', week: 'Friday' },
//   { date: '4th Feb,20', week: 'Saturday' },
//   { date: '29th Jan,20', week: 'Sunday' },
//   { date: '30th Jan,20', week: 'Monday' },
//   { date: '31th Jan,20', week: 'Tuesday' },
//   { date: '1st Feb,20', week: 'Wednesday' },
//   { date: '2th Feb,20', week: 'Thursday' },
//   { date: '3rd Feb,20', week: 'Friday' },
//   { date: '4th Feb,20', week: 'Saturday' }

// ];

export const modalsliderDateFliter = [
  { date: 'Sep 19, 2019 , 11:15am (45mins)' },
  { date: 'Sep 20, 2019 , 11:15am (45mins)' },
  { date: 'Sep 21, 2019 , 11:15am (45mins)' },
  { date: 'Sep 22, 2019 , 11:15am (45mins)' },
  { date: 'Sep 23, 2019 , 11:15am (45mins)' },
  { date: 'Sep 24, 2019 , 11:15am (45mins)' },
  { date: 'Sep 25, 2019 , 11:15am (45mins)' },
  { date: 'Sep 26, 2019 , 11:15am (45mins)' },
  { date: 'Sep 27, 2019 , 11:15am (45mins)' },
  { date: 'Sep 28, 2019 , 11:15am (45mins)' }
];

export const SideBarNavAfterLogin = [
  { Link: 'Home', path: '/' },
  { Link: 'About Us', path: '/about-us' },
  { Link: 'Timetable', path: '/time-table' },
  { Link: 'My Schedule', path: '/schedule' }
];

export const SideBarNavBeforeLogin = [
  { Link: 'Home', path: '/' },
  { Link: 'About Us', path: '/about-us' },
  { Link: 'Timetable', path: '/time-table' }
];

export const topGyms = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 }
];

export const gymScheduleDropDown = [
  { title: 'Does not repeat', value: 'notRepeat' },
  { title: 'Repeat weekly', value: 'repeat' },
  { title: 'Custom', value: 'custom' }
];

export const instructoreDropDown = [
  { label: 'Douglas', value: 'notRepeat' },
  { label: 'Mark', value: 'repeat' },
  { label: 'Jennifer', value: 'custom' }
];

export const manageButton = [
  { title: 'View', value: 'view' },
  { title: 'Cancel this class', value: 'cancelClass' },
  { title: 'Cancel Recurring', value: 'cancellRecurring' },
  { title: 'Book Recurring', value: 'bookRecurring' }
];

export const timePeriodDropDown = [
  { title: 'Days', value: 'days' },
  { title: 'Weeks', value: 'weeks' },
  { title: 'Months', value: 'months' },
  { title: 'Years', value: 'years' }
];

export const sortByPopularity = [
  { title: 'Most Popular', value: 'popular' },
  { title: 'Highly Rated', value: 'rated' }
];

export const bestTimeData = [
  { label: 'Morning', value: 'morning', type: 'time' },
  { label: 'Afternoon', value: 'afternoon', type: 'time' },
  { label: 'Evening', value: 'evening', type: 'time' }
];

export const gymsName = [
  { label: 'GYMBOX', value: 'gymBox_15', type: 'gyms' }
];
