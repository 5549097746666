import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, DialogContent,
  DialogTitle, Backdrop, Dialog, Button, Grid
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { BtnModalClose } from '../assets/style';
import { InputBox } from '../../../utils/FormUtils';
import textContent from '../../Auth/AuthConstants';

const GymBoxLoginModal = (props) => {
  const { gymBoxLogin, getUserDetails } = props;
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: ''
  });

  const onChangeHandler = (value, name) => {
    setLoginDetails({ ...loginDetails, [name]: value });
  };

  const submitLoginrequest = () => {
    const loginData = {
      gymBrand: 'GymBox',
      gymEmail: loginDetails.email,
      gymPassword: loginDetails.password
    };

    gymBoxLogin(loginData)
      .then(response => {
        if (response && response.action && response.action.payload &&
          response.action.payload.data && response.action.payload.data.status === 'SUCCESS') {
          getUserDetails();
          handlerSetToggleDetails('toggalGymBoxModal');
        }
      });
  };

  const { toggalGymBoxModal, handlerSetToggleDetails } = props;
  const { email, password } = loginDetails;

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      classes={{ root: 'theme-modal' }}
      open={toggalGymBoxModal}
      onClose={() => handlerSetToggleDetails('toggalGymBoxModal')}
      closeAfterTransition
      BackdropComponent={Backdrop}
      maxWidth="md"
      BackdropProps={{
        timeout: 500
      }}
    >
      <DialogTitle classes={{ root: 'modal-title' }} onClose={() => handlerSetToggleDetails('toggalGymBoxModal')} disableTypography>
        Gym Box Login Credentials
        <BtnModalClose aria-label="close" onClick={() => handlerSetToggleDetails('toggalGymBoxModal')} >
          <CloseIcon />
        </BtnModalClose>
      </DialogTitle>
      <DialogContent >
        <Grid container justify="center">
          <Grid item xs={12} >
            <InputBox
              label={textContent.EMAIL_FIELD_LABEL}
              placeholder={textContent.EMAIL_FIELD_PLACEHOLDER}
              type={'text'}
              name={'email'}
              onChange={onChangeHandler}
              value={email}
              maxLength={30}
            />
          </Grid>
          <Grid item xs={12}>
            <InputBox
              label={textContent.PASSWORD_FIELD_LABEL}
              placeholder={textContent.PASSWORD_FIELD_PLACEHOLDER}
              type={'password'}
              onChange={onChangeHandler}
              name={'password'}
              value={password}
              maxLength={30}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={submitLoginrequest}>Submit</Button>
      </DialogActions>
    </Dialog >
  );
};


GymBoxLoginModal.propTypes = {
  toggalGymBoxModal: PropTypes.bool,
  handlerSetToggleDetails: PropTypes.func,
  gymBoxLogin: PropTypes.func,
  getUserDetails: PropTypes.func
};

export default GymBoxLoginModal;
