import {
  FACEBOOK_LOGIN_LOADING, FACEBOOK_LOGIN_SUCCESS, FACEBOOK_LOGIN_FAILURE
} from './FacebookLoginConstants';
import Messages from '../../constants/messages';
import { pushNotification } from '../../utils/notifications';

const initialState = {
  facebookLoginLoading: false
};

// Forgot password
const facebookLoginStart = (state) => ({
  ...state,
  facebookLoginLoading: true
});

const facebookLoginSuccess = (state) => ({
    ...state,
    facebookLoginLoading: false
  });

const facebookLoginFailure = (state, action) => {
  if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
    pushNotification(action.payload.response.data.error, 'error', 'TOP_CENTER', 2000);
  } else {
    pushNotification(Messages.FORGOT_PASSWORD_FAILED, 'error', 'TOP_CENTER', 2000);
  }
  return ({
    ...state,
    facebookLoginLoading: false
  });
};

const authReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FACEBOOK_LOGIN_LOADING: return facebookLoginStart(state, action);
    case FACEBOOK_LOGIN_SUCCESS: return facebookLoginSuccess(state, action);
    case FACEBOOK_LOGIN_FAILURE: return facebookLoginFailure(state, action);
    default:
      return {
        ...state
      };
  }
};

export default authReducer;
