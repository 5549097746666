import React, { useEffect } from 'react';
import LoginForm from './components/loginForm';
import RegisterForm from './components/registerForm';
import ForgotPassword from './components/forgotPasswordForm';
import PropTypes from 'prop-types';
import { retrieveFromLocalStorage } from '../../utils/helpers';

const Auth = (props) => {
  const { updateReducerState, isLogin, authModalType } = props;

  useEffect(() => {
    if (!retrieveFromLocalStorage('token') && isLogin) {
      updateReducerState('auth', 'isLogin', false);
    }
     // eslint-disable-next-line
  }, []);

  return (
    <div>
      {authModalType === 'forgot' ?
        <ForgotPassword {...props} /> :
        authModalType === 'login' ?
          <LoginForm {...props} />
          :
          <RegisterForm {...props} />
      }
    </div>
  );
};

Auth.propTypes = {
  match: PropTypes.object,
  updateReducerState: PropTypes.func,
  isLogin: PropTypes.bool,
  authModalType: PropTypes.string
};

export default Auth;
