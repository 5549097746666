import styled from 'styled-components';

export const BtnGoogle = styled.div`
    background: #F44236;
    border: 0;
    border-radius: 5px;
    color: #FFFFFF;
    height: 44px;
    font-weight: 500;
    font-size: 14px;
    padding: 0 30px;
    min-width: 85;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content:center;
    @media (max-width: 414px) {
        position: relative;
        left: -16px;
    }
    @media (max-width: 414px) {
        left: -14px;
    }
`;
