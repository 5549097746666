export const GET_TIME_TABLE_LIST = 'GET_TIME_TABLE_LIST';
export const GET_TIME_TABLE_LIST_LOADING = 'GET_TIME_TABLE_LIST_LOADING';
export const GET_TIME_TABLE_LIST_SUCCESS = 'GET_TIME_TABLE_LIST_SUCCESS';
export const GET_TIME_TABLE_LIST_FAILURE = 'GET_TIME_TABLE_LIST_FAILURE';

// Book gym class
export const BOOK_GYM_CLASS = 'BOOK_GYM_CLASS';
export const BOOK_GYM_CLASS_LOADING = 'BOOK_GYM_CLASS_LOADING';
export const BOOK_GYM_CLASS_SUCCESS = 'BOOK_GYM_CLASS_SUCCESS';
export const BOOK_GYM_CLASS_FAILURE = 'BOOK_GYM_CLASS_FAILURE';

// Pre book gym class
export const PRE_BOOK_GYM_CLASS = 'PRE_BOOK_GYM_CLASS';
export const PRE_BOOK_GYM_CLASS_LOADING = 'PRE_BOOK_GYM_CLASS_LOADING';
export const PRE_BOOK_GYM_CLASS_SUCCESS = 'PRE_BOOK_GYM_CLASS_SUCCESS';
export const PRE_BOOK_GYM_CLASS_FAILURE = 'PRE_BOOK_GYM_CLASS_FAILURE';

// Filter gym class data
export const FILTER_SELECTED_GYMS_DATA = 'FILTER_SELECTED_GYMS_DATA';
export const FILTER_SELECTED_GYMS_DATA_LOADING = 'FILTER_SELECTED_GYMS_DATA_LOADING';
export const FILTER_SELECTED_GYMS_DATA_SUCCESS = 'FILTER_SELECTED_GYMS_DATA_SUCCESS';
export const FILTER_SELECTED_GYMS_DATA_FAILURE = 'FILTER_SELECTED_GYMS_DATA_FAILURE';
