import styled from 'styled-components';

export const BtnFacebook = styled.div`
    background: #3A5D9E;
    border: 0;
    border-radius: 5px;
    color: #FFFFFF;
    height: 44px;
    font-weight: 500;
    font-size: 14px;
    padding: 0 30px;
    min-width: 85px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    @media (max-width: 414px) {
        justify-content:center;
    }
    @media (max-width: 375px) {
        
    }
`;
