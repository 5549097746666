import { connect } from 'react-redux';
import Layout from './index';
import { updateReducerState } from '../../feature/Common/CommonActions';
import { getUserDetails, initializeUser } from '../../feature/Auth/AuthActions';

const mapStateToProps = state => ({
  isLogin: state.auth.isLogin,
  userDetails: state.auth.userDetails
});

const mapDispatchToProps = dispatch => ({
  updateReducerState: (reducerKey, key, value) => dispatch(updateReducerState(reducerKey, key, value)),
  getUserDetails: () => dispatch(getUserDetails()),
  initializeUser: () => dispatch(initializeUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
