import {
  RESET_PASSWORD_REQUEST_LOADING, RESET_PASSWORD_REQUEST_SUCCESS, RESET_PASSWORD_REQUEST_FAILURE
} from './ResetPasswordConstants';
import { pushNotification } from '../../utils/notifications';
import Messages from '../../constants/messages';

const initialState = {
  resetPasswordLoading: false
};

// Reset Password
const resetPasswordStart = (state) => ({
  ...state,
  resetPasswordLoading: true
});

const resetPasswordSuccess = (state, action) => {
  if (action && action.payload && action.payload.status && action.payload.status === 200 &&
    action.payload.data && action.payload.data.message) {
    pushNotification(action.payload.data.message, 'success', 'TOP_CENTER', 3000);
  } else {
    pushNotification(Messages.RESET_PASSWORD_SUCCESS, 'success', 'TOP_CENTER', 3000);
  }
  return ({
    ...state,
    resetPasswordLoading: false
  });
};

const resetPasswordFailure = (state, action) => {
  if (action.payload && action.payload.response && action.payload.response.data
    && action.payload.response.data.error) {
    pushNotification(action.payload.response.data.error, 'error', 'TOP_CENTER', 3000);
  } else {
    pushNotification(Messages.RESET_PASSWORD_FAILED, 'error', 'TOP_CENTER', 3000);
  }
  return ({
    ...state,
    resetPasswordLoading: false
  });
};

const ResetPasswordReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST_LOADING: return resetPasswordStart(state, action);
    case RESET_PASSWORD_REQUEST_SUCCESS: return resetPasswordSuccess(state, action);
    case RESET_PASSWORD_REQUEST_FAILURE: return resetPasswordFailure(state, action);
    default:
      return {
        ...state
      };
  }
};

export default ResetPasswordReducer;
