import {
  LOGOUT_USER_LOADING, LOGOUT_USER_SUCCESS, LOGOUT_USER_FAILURE
} from './HeaderConstants';
import { removeFromLocalStorage } from '../../utils/helpers';
import { pushNotification } from '../../utils/notifications';
import Messages from '../../constants/messages';
const initialState = {
  logOutUserLoading: false,
  toggalAuthModal: false,
  authModalType: 'login'
};

const logoutUserStart = (state) => ({
  ...state,
  logOutUserLoading: true
});

const logoutUserSuccess = (state, action) => {
  if (action && action.payload && action.payload.data && action.payload.data.status === 'SUCCESS') {
    removeFromLocalStorage('token');
    pushNotification(Messages.LOGOUT_SUCCESS, 'success', 'TOP_CENTER', 2000);
  }
  state.logOutUserLoading = false;
  return {
    ...state
  };
};

const logoutUserFailure = (state) => ({
  ...state,
  logOutUserLoading: false
});

const headerReducer = (state = initialState, action = {}) => {
  switch (action.type) {

    case LOGOUT_USER_LOADING: return logoutUserStart(state, action);
    case LOGOUT_USER_SUCCESS: return logoutUserSuccess(state, action);
    case LOGOUT_USER_FAILURE: return logoutUserFailure(state, action);
    default:
      return {
        ...state
      };
  }
};

export default headerReducer;
