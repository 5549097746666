import { connect } from 'react-redux';
import Auth from './index';
import { login, registerUser, initializeUser, getUserDetails, initiateForgotPassword } from './AuthActions';
import { updateReducerState } from '../../feature/Common/CommonActions';
import { getClassTimeTableList } from '../ClassTimeTable/ClassTimeTableActions';

const mapStateToProps = state => ({
  isLogin: state.auth.isLogin,
  loginLoading: state.auth.loginLoading,
  registerUserLoading: state.auth.registerUserLoading,
  authModalType: state.header.authModalType,
  toggalAuthModal: state.header.toggalAuthModal,
  forgotPasswordLoading: state.auth.forgotPasswordLoading,
  facebookLoginLoading: state.facebook.facebookLoginLoading,
  googleLoginLoading: state.google.googleLoginLoading
});

const mapDispatchToProps = dispatch => ({
  login: (loginData) => dispatch(login(loginData)),
  registerUser: (userDetails) => dispatch(registerUser(userDetails)),
  updateReducerState: (reducerKey, key, value) => dispatch(updateReducerState(reducerKey, key, value)),
  initializeUser: () => dispatch(initializeUser()),
  getUserDetails: () => dispatch(getUserDetails()),
  getClassTimeTableList: (gymLocation) => dispatch(getClassTimeTableList(gymLocation)),
  initiateForgotPassword: (email) => dispatch(initiateForgotPassword(email))
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
