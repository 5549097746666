import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Grid, Container, IconButton, Divider, InputBase, CircularProgress, LinearProgress, Box
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import Slider from 'react-slick';
import {
  SiteTitle, FilterBTN, CalenderBTN,
  SliderWrap, SliderUI, ThemeTable, SearchComponent, LoadingWrap, CalenderBTNModule,
  FilterWrapBox, BackdropWrapper
} from './assets/style.js';
import { FilterButtonSvg, SearchButtonSvg, CalenderButtonSvg } from '../../utils/svgs';
import Filters from './components/filters';
import Modal from './components/modal';
import SortByRating from '../Common/components/sortByRating';
import GymBoxLoginModal from './components/gymBoxLoginModal';
import Table from './components/table';
import { SeoHelmet } from '../../utils/seoHelmet';
import Messages from '../../constants/messages';
import { pushNotification } from '../../utils/notifications';

const ClassTimeTable = (props) => {

  const { timeTableList, timeTableDates, timeTableLoading, timeTableArray, bookGymClass,
    preBookGymClass, userDetails, gymBoxLogin, getUserDetails, bookClassLoading,
    updateReducerState, toggalAuthModal, gymsNameList, getClassTimeTableList, filterSelectedGymsData } = props;
  const [timeTableDetails, setTimeTableDetails] = useState({
    toggalCalender: false,
    toggalModal: false,
    toggalFilters: false,
    sliderIndex: 0,
    toggleSearchBar: false,
    classDetails: {},
    toggalSortMenu: false,
    toggalGymBoxModal: false,
    gymlassId: null,
    searchParam: ''
  });

  const [filterData, setFilterData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dateValue, setDateValue] = useState(timeTableDates && timeTableDates[0] ? timeTableDates[0].value : '');

  useEffect(() => {
    const { getClassTimeTableList, getGymsList } = props;
    getClassTimeTableList('Bank');
    getGymsList('GymBox');
     // eslint-disable-next-line
  }, []);

  const handlerSetToggleDetails = (name) => {
    timeTableDetails[name] = !timeTableDetails[name];
    setTimeTableDetails({
      ...timeTableDetails
    });
  };

  const handleDateChange = date => {
    const index = timeTableDates.findIndex((item) => item.value === moment(date).format('DD-MM-YYYY'));
    if (index >= 0) {
      setDateValue(timeTableDates[index].value);
      timeTableDetails.sliderIndex = index;
      setTimeTableDetails({
        ...timeTableDetails
      });
      setSelectedDate(moment(date).format('DD/MMM/YYYY'));
    } else {
      pushNotification(Messages.TIME_TABLE_DATA_NOT_AVAILABLE, 'error', 'TOP_CENTER', 2000);
    }
    handlerSetToggleDetails('toggalCalender');
  };

  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    // autoplaySpeed: 20000,
    draggable: false,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const handlerDetailModal = (value) => {
    timeTableDetails.toggalModal = !timeTableDetails.toggalModal;
    if (value) {
      timeTableDetails.classDetails = value;
    }
    setTimeTableDetails({
      ...timeTableDetails
    });
  };

  const onChangeDateHandler = (value) => {
    setDateValue(value.value);
    const index = timeTableDates.findIndex((data) => data.value === value.value);
    setTimeTableDetails({
      ...timeTableDetails,
      sliderIndex: index
    });
    const momentObj = moment(value.value, 'DD-MM-YYYY');
    setSelectedDate(moment(momentObj).format('DD/MMM/YYYY'));
  };

  const sortedClasses = (value) => {
    console.log('value', value);
  };

  const handlerSearch = (value) => {
    timeTableDetails.searchParam = value;
    setTimeTableDetails({
      ...timeTableDetails
    });
  };

  const { toggleSearchBar, sliderIndex, classDetails, toggalModal, toggalSortMenu,
    toggalGymBoxModal, gymClassId, toggalFilters, searchParam } = timeTableDetails;

  return (
    <Container>
      <SeoHelmet title="Time-table" />
      <Grid container spacing={3} direction="row">
        <Grid item xs={6}>
          <SiteTitle as="h2" color="secondary">Classes Timetable</SiteTitle>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end" alignItems="center" style={{ 'height': '100%' }}>
            <SortByRating
              toggalSortMenu={toggalSortMenu}
              handlerSetToggleDetails={handlerSetToggleDetails}
              sortedClasses={sortedClasses}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3} direction="row">
        <Grid item md={8} xs={12}>
          <FilterWrapBox className={toggalFilters ? 'active' : ''}>
            <BackdropWrapper className="overlay" onClick={() => handlerSetToggleDetails('toggalFilters')} />
            <FilterBTN className="tag-btn" color="primary" onClick={() => handlerSetToggleDetails('toggalFilters')}>
              Filter
              <FilterButtonSvg />
            </FilterBTN>
            <Filters
              toggalFilters={toggalFilters}
              gymsNameList={gymsNameList}
              filterData={filterData}
              handlerSetFilterState={data => setFilterData(data)}
              filterSelectedGymsData={filterSelectedGymsData}
            />
          </FilterWrapBox>

        </Grid>
        <Grid item md={4} xs={12} container justify="flex-end">
          <SearchComponent className={toggleSearchBar ? 'searchInputShow' : 'searchInputhidden'}>
            <InputBase
              onChange={e => handlerSearch(e.target.value)}
              placeholder="Search…"
              value={searchParam}
            // putProps={{ 'aria-label': 'search' }}
            />
            <IconButton type="submit" aria-label="search" onClick={() => handlerSetToggleDetails('toggleSearchBar')}>
              <SearchButtonSvg />
            </IconButton>
          </SearchComponent>

        </Grid>
        <Grid item xs={1}>
          <CalenderBTNModule>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <CalenderBTN onClick={() => handlerSetToggleDetails('toggalCalender')}>
                <CalenderButtonSvg />
              </CalenderBTN>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                classes={{ root: 'hidden-calender' }}
                margin="normal"
                id="date-picker-inline"
                label="Date picker inline"
                minDate={new Date()}
                value={selectedDate}
                onChange={handleDateChange}
                open={timeTableDetails.toggalCalender}
                onClose={() => handlerSetToggleDetails('toggalCalender')}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </MuiPickersUtilsProvider>
          </CalenderBTNModule>
        </Grid>
        <Grid item xs={11}>
          <SliderUI>
            {timeTableLoading ?
              <LinearProgress className="mt-10" primary />
              :
              <Slider {...settings} afterChange={(index) => console.log('value', index)}>
                {timeTableDates.map((value, index) => (
                  <SliderWrap key={index + 1} onClick={() => onChangeDateHandler(value)} className={sliderIndex === index ? 'sliderActive' : 'sliderDeActive'}>
                    <strong>{value.week}</strong>
                    <span>{value.date}</span>
                  </SliderWrap>
                ))}
              </Slider>}
          </SliderUI>
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <ThemeTable>
            {timeTableLoading ? <LoadingWrap><CircularProgress /></LoadingWrap> :
              timeTableDates.length && timeTableList &&
              <Table
                timeTableDates={timeTableDates}
                handlerSetToggleDetails={handlerSetToggleDetails}
                dateValue={dateValue}
                handlerDetailModal={handlerDetailModal}
                timeTableList={timeTableList}
                userDetails={userDetails}
                updateReducerState={updateReducerState}
                toggalAuthModal={toggalAuthModal}
              />
            }
          </ThemeTable>
        </Grid>
      </Grid>
      {
        toggalModal && <Modal
          openModal={toggalModal}
          handlerDetailModal={handlerDetailModal}
          classDetails={classDetails}
          timeTableDates={timeTableDates}
          timeTableArray={timeTableArray}
          bookGymClass={bookGymClass}
          preBookGymClass={preBookGymClass}
          bookClassLoading={bookClassLoading}
          gymClassId={gymClassId}
          getClassTimeTableList={getClassTimeTableList}
          setGymClassId={id => setTimeTableDetails({
            ...timeTableDetails,
            gymClassId: id
          })}
                       />
      }
      <GymBoxLoginModal
        toggalGymBoxModal={toggalGymBoxModal}
        handlerSetToggleDetails={handlerSetToggleDetails}
        gymBoxLogin={gymBoxLogin}
        getUserDetails={getUserDetails}
      />
    </Container >
  );
};

ClassTimeTable.propTypes = {
  getClassTimeTableList: PropTypes.func,
  timeTableList: PropTypes.object,
  timeTableDates: PropTypes.array,
  timeTableLoading: PropTypes.bool,
  timeTableArray: PropTypes.array,
  bookGymClass: PropTypes.func,
  preBookGymClass: PropTypes.func,
  userDetails: PropTypes.object,
  gymBoxLogin: PropTypes.func,
  getUserDetails: PropTypes.func,
  bookClassLoading: PropTypes.bool,
  updateReducerState: PropTypes.func,
  toggalAuthModal: PropTypes.bool,
  getGymsList: PropTypes.func,
  gymsNameList: PropTypes.array,
  filterSelectedGymsData: PropTypes.func
};

export default ClassTimeTable;
