import { connect } from 'react-redux';
import Home from './index';
import { getGymsList } from '../Common/CommonActions';
import { updateReducerState } from '../../feature/Common/CommonActions';

const mapStateToProps = state => ({
  isLogin: state.auth.isLogin
});

const mapDispatchToProps = dispatch => ({
  getGymsList: (gymBrand) => dispatch(getGymsList(gymBrand)),
  updateReducerState: (reducerKey, key, value) => dispatch(updateReducerState(reducerKey, key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
