import React from 'react';
import { Container, Button } from '@material-ui/core';
import history from '../../utils/history';
import './assets/style.css';

const ErrorPage = () => {

  const redirectToHomepage = () => {
    history.push('/');
  };

  return (
    <Container textAlign="center" className="error-pageui">
      <div className="error-pageui-code">
        <picture>
          <source srcSet={require('./assets/error_404.webp')} type="image/webp" />
          <source srcSet={require('./assets/error_404.png')} type="image/png" />
          <img src={require('./assets/error_404.png')} alt="page not found" />
        </picture>
        <div className="contentUpdate-nptf">
          <p> Sorry but the page you are looking for does not exist.</p>
          <div className="cta404">
            <Button onClick={redirectToHomepage} className="custom_static_btn" primary type={'submit'} content={'BACK TO HOME'} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ErrorPage;
