import React from 'react';
import PropTypes from 'prop-types';
import { AvatarGroup, Rating } from '@material-ui/lab';
import { TableRow, TableCell, Tooltip, MenuItem, CircularProgress, Box, Menu } from '@material-ui/core';

import Image1 from '../assets/01.png';
import Image2 from '../assets/02.png';
import Image3 from '../assets/03.png';

import { TableCloDis, TimeCol, AvatarUser, TableBTN } from '../assets/style';
import { NameArrowSvg } from '../../../utils/svgs';
import { manageButton } from '../../../utils/globalConstants';

const Table = (props) => {
  const { myScheduleList, gymClassId, cancelClassLoading, handlerToggleButton,
    handlerButtonMenu, toggleButton, handlerSetToggleDetails } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event, details) => {
    setAnchorEl(event.currentTarget);
    handlerButtonMenu(details);
  };

  const handleClose = () => {
    setAnchorEl(null);
    handlerSetToggleDetails('toggleButton');
  };

  const handleSetDetails = (value, details) => {
    if (value && details) {
      handlerToggleButton(value, details);
    }
    setAnchorEl(null);
    handlerSetToggleDetails('toggleButton');
  };

  return (
    myScheduleList && myScheduleList.map((details, index) => (
      <TableRow key={index} hover>
        <TableCell>
          <TimeCol>
            {details.time}
            <span>{`(${details.duration})`}</span>
          </TimeCol>
        </TableCell>
        <TableCell>
          <TableCloDis>
            <strong>{details.name}</strong>
            <Rating name="half-rating" defaultValue={2.5} precision={0.2} size="small" classes={{ root: 'small-star' }} />
            <div className="TableCloDis_name">
              <NameArrowSvg />
              {details.instructorName}
            </div>
          </TableCloDis>
        </TableCell>
        <TableCell> {details.gymLocation}</TableCell>
        <TableCell>
          <AvatarGroup>
            <AvatarUser small alt="Remy Sharp" src={Image1} />
            <AvatarUser small alt="Remy Sharp" src={Image2} />
            <AvatarUser small alt="Remy Sharp" src={Image3} />
            <Tooltip title="Foo • Bar • Baz">
              <AvatarUser>+3</AvatarUser>
            </Tooltip>
          </AvatarGroup>
        </TableCell>
        <TableCell align="right">
          <Box className="manageBox">
            {cancelClassLoading && gymClassId === details.gymClassId ?
              <CircularProgress />
              :
              <div>
                <TableBTN
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={event => handleClick(event, details)}
                >
                  MANAGE
                </TableBTN>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={toggleButton && gymClassId && gymClassId === details.gymClassId}
                  onClose={handleClose}
                >
                  {manageButton.map((value) => (<MenuItem key={value.titile} onClick={() => handleSetDetails(value.value, details)}>{value.title}</MenuItem>))}
                </Menu>
              </div>
            }
          </Box>
        </TableCell>
      </TableRow>
    ))
  );
};


Table.propTypes = {
  myScheduleList: PropTypes.array,
  toggleButton: PropTypes.bool
};

export default Table;
