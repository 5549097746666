import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { oneLowerCase, digitRegex, oneUpperCase } from '../../constants/globalConstants';
import Texts from '../../constants/staticText';
import Messages from '../../constants/messages';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { PasswordAccept } from './style';

const PasswordAcceptance = (props) => {
    const { value } = props;
    return (
      <PasswordAccept>
        <p className="subHead"><b> {Messages.PASSWORD_CRITERIA} </b></p>
        <List className="password-list" >
          <ListItem>
            {oneLowerCase.test(value) ? <CheckIcon className="success" /> : <ClearIcon className="cross-red"/> }
            <ListItemText>{Messages.ATLEAST_ONE_LOWERCASE}</ListItemText>
          </ListItem>
          <ListItem>
            {oneUpperCase.test(value) ? <CheckIcon className="success"/> : <ClearIcon className="cross-red"/> }
            <ListItemText>{Messages.ATLEAST_ONE_CAPITAL}</ListItemText>
          </ListItem>
          <ListItem>
            {digitRegex.test(value) ? <CheckIcon className="success"/> : <ClearIcon className="cross-red"/> }
            <ListItemText>{Messages.ATLEAST_ONE_NUMBER}</ListItemText>
          </ListItem>
          <ListItem>
            {value.length < Texts.PASSWORD_ACCEPTANCE_LENGTH ? <ClearIcon className="cross-red"/> : <CheckIcon className="success"/> }
            <ListItemText>{Messages.MINIMUM_8_CHARS}</ListItemText>
          </ListItem>
        </List>
      </PasswordAccept>
    );
};

PasswordAcceptance.propTypes = {
  value: PropTypes.string
};

export default PasswordAcceptance;
