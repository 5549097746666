export const AppRoutes = {
    HOME: '/',
    SIGN_IN: '/sign-in',
    SCHEDULE: '/schedule',
    VERIFY_TOKEN: '/verify/:token',
    RESET_PASSWORD: '/reset_password/:token',
    TIME_TABLE: '/time-table',
    TERMS_OF_USE: '/terms-and-condition',
    PRIVACY_POLICY: '/privacy-policy'
};
