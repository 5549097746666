import styled from 'styled-components';

export const InputController = styled.div`
    &.input-box-controller{
        width:100%;
        max-width:330px;
        margin:0 auto 20px auto;
        .error-message{
            left:37px;
        }
    }
     text-align: center;
     padding-bottom:13px;
    position: relative;
    /* margin-bottom: 14px; */
    .MuiTypography-root {
        width: 85px;
        margin: auto;
        margin-left: 67px;
    }
    .error-message {
        position:absolute;
        top: 36px;
        left: 10px;
        background: #fff;
        width: auto !important;
        padding: 0 5px;
        margin: 0 !important;

    }
    .MuiInput-root {
        width:100%;
        max-width: 280px;
        margin: 0 auto;
    }
    .MuiInput-underline:before {
        display:none;
    }
    
    input {
        width: 332px;
        margin: auto;
        height: 44px;
        border-radius: 5px;
        border: 1px solid #E0E0E0;
        padding: 0 14px;
    &::placeholder {
        font-size:14px;
    }
        @media (max-width: 852px) {
            width: 100%;
        }
    }
    .MuiInput-underline.Mui-error:after {
        border: 1px solid #f44336;
        width: auto;
        margin: auto;
        height: 44px;
        border-radius: 5px;
    }
    .error-message {
        display: flex;
        width: 303px;
        margin: 5px auto;
        font-size: 12px;
        color: #f44336;
    }
    .MuiInput-underline.Mui-focused:after {
        width: auto;
        margin: auto;
        height: 44px;
        border: 2px solid #f4bdac;
        pointer-events: none;
        border-radius: 5px;
    }


`;

export const BtnPrimary = styled.div`
    background: #49589B;
    border: 0;
    border-radius: 5px;
    color: white;
    height: 44px;
    font-size: 14px;
    padding: 0 30px;
    width: 135px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    
`;

export const PasswordListWrap = styled.div`
display: flex;
justify-content: center;
`;

export const Title = styled.div` 
    width: 280px;
    margin: 0 auto;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #49589b;
`;
