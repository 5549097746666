import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Validator from '../../../utils/Validator';
import { InputBox } from '../../../utils/FormUtils';
import textContent from '../AuthConstants';
import { AuthImgWrap, FormWrap, InputController, BtnPrimary, BtnWhite } from '../components/assets/style';
import Typography from '@material-ui/core/Typography';
import { Box, CircularProgress } from '@material-ui/core';
import { SeoHelmet } from '../../../utils/seoHelmet';

const RegisterForm = (props) => {

  // state manupulation
  const [userDetails, setUserDetails] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  });

  const [error, setErrors] = useState({});

  const _isValid = (field = null, value) => {
    if (field) {
      userDetails[field] = value;
    }
    const validate = Validator.createValidator(
      {
        email: ['required', 'email'],
        password: ['required', 'minLength|4'],
        confirmPassword: ['required', 'match|password']
      },
      {
        email: userDetails.email,
        password: userDetails.password,
        confirmPassword: userDetails.confirmPassword
      },
      field,
      {
        email: '',
        password: ''
      }
    );

    return validate;
  };


  // Validations on blur
  const validateOnBlur = (name, value) => {
    const { errors } = _isValid(name, value);
    setErrors({ ...error, [name]: errors[name] });
  };

  const handleSignUpClick = () => {
    const { isValid } = _isValid();
    if (isValid) {
      const { registerUser, updateReducerState } = props;
      const userData = {
        email: userDetails.email,
        password: userDetails.password
      };
      registerUser(userData)
        .then(response => {
          if (response && response.value && response.value.data && response.value.data.status === 'SUCCESS') {
            updateReducerState('header', 'authModalType', 'login');
          }
        });
    } else {
      const { errors } = _isValid();
      setErrors({ ...errors });
    }
  };

  const onChangeHandler = (value, name) => {
    setUserDetails({ ...userDetails, [name]: value });
    validateOnBlur(name, value);
  };

  const { email, password, confirmPassword } = userDetails;
  const { updateReducerState, registerUserLoading } = props;
  return (
    <Grid container spacing={1}>
      <SeoHelmet title="Register" />
      <Grid item md={5} xs={12} className="reorder">
        <AuthImgWrap>
          <Box className="rightWrap">
            <p className="noAccount">You already have an account</p>
            <BtnWhite onClick={() => updateReducerState('header', 'authModalType', 'login')}>Login</BtnWhite>
          </Box>
        </AuthImgWrap>
      </Grid>
      <Grid item md={7} xs={12}>
        <FormWrap>
          <Typography align="center" variant="h6" component="h2">Registration</Typography>
          <p className="subhead">Create your Gymbook account</p>
          <InputController>
            <InputBox
              errorMessage={error.email}
              label={textContent.EMAIL_FIELD_LABEL}
              placeholder={textContent.EMAIL_FIELD_PLACEHOLDER}
              type={'text'}
              name={'email'}
              onChange={onChangeHandler}
              value={email}
              maxLength={30}
            />
          </InputController>
          <InputController className="">
            <InputBox
              errorMessage={error.password}
              label={textContent.PASSWORD_FIELD_LABEL}
              placeholder={textContent.PASSWORD_FIELD_PLACEHOLDER}
              type={'password'}
              onChange={onChangeHandler}
              name={'password'}
              value={password}
              maxLength={30}
            />
          </InputController>
          <InputController className="pb-26">
            <InputBox
              errorMessage={error.confirmPassword}
              label={textContent.PASSWORD_FIELD_LABEL}
              placeholder={textContent.PASSWORD_FIELD_PLACEHOLDER}
              type={'password'}
              onChange={onChangeHandler}
              name={'confirmPassword'}
              value={confirmPassword}
              maxLength={30}
            />
          </InputController>

          <InputController>
            <Typography align="center">
              {registerUserLoading ?
                <CircularProgress />
                :
                <BtnPrimary variant="contained" color="secondary" onClick={() => handleSignUpClick()}>Register</BtnPrimary>
              }
            </Typography>
          </InputController>
        </FormWrap>
      </Grid>
    </Grid>

  );
};

RegisterForm.propTypes = {
  registerUser: PropTypes.func,
  updateReducerState: PropTypes.func,
  registerUserLoading: PropTypes.bool
};

export default RegisterForm;
