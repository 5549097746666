// get schedule gym class list constants
export const GET_MY_SCHEDULE_LIST = 'GET_MY_SCHEDULE_LIST';
export const GET_MY_SCHEDULE_LIST_LOADING = 'GET_MY_SCHEDULE_LIST_LOADING';
export const GET_MY_SCHEDULE_LIST_SUCCESS = 'GET_MY_SCHEDULE_LIST_SUCCESS';
export const GET_MY_SCHEDULE_LIST_FAILURE = 'GET_MY_SCHEDULE_LIST_FAILURE';

// cancel book class constants
export const CANCEL_CLASS = 'CANCEL_CLASS';
export const CANCEL_CLASS_LOADING = 'CANCEL_CLASS_LOADING';
export const CANCEL_CLASS_SUCCESS = 'CANCEL_CLASS_SUCCESS';
export const CANCEL_CLASS_FAILURE = 'CANCEL_CLASS_FAILURE';

// cancel PRE book class constants
export const CANCEL_PREBOOK_CLASS = 'CANCEL_PREBOOK_CLASS';
export const CANCEL_PREBOOK_CLASS_LOADING = 'CANCEL_PREBOOK_CLASS_LOADING';
export const CANCEL_PREBOOK_CLASS_SUCCESS = 'CANCEL_PREBOOK_CLASS_SUCCESS';
export const CANCEL_PREBOOK_CLASS_FAILURE = 'CANCEL_PREBOOK_CLASS_FAILURE';
