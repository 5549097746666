import api from '../../api';
import {
  INITIALIZE_USER, LOGIN_REQUEST, GET_USER_DETAILS, REGISTER_USER, GYMBOX_LOGIN,
  FORGOT_PASSWORD_INITIATE
} from './AuthConstants';
import URLs from '../../constants/urls';

/* action for logging in to the app */
export const login = (loginData) => {
  const data = {
    'email': loginData.email,
    'password': loginData.password
  };
  return {
    type: LOGIN_REQUEST,
    payload: api.post(URLs.LOGIN_URL, data)
  };
};

export const registerUser = (userDetails) => ({
  type: REGISTER_USER,
  payload: api.post(URLs.REGISTER_USER_URL, userDetails)
});

export const initializeUser = () => ({
  type: INITIALIZE_USER,
  payload: api.post(URLs.INITIALIZE_USER_URL)
});

export const getUserDetails = () => ({
  type: GET_USER_DETAILS,
  payload: api.get(URLs.GET_USER_DETAILS_URL)
});

export const gymBoxLogin = (loginDetails) => ({
  type: GYMBOX_LOGIN,
  payload: api.post(URLs.GYMBOX_LOGIN_URL, loginDetails)
});

export const initiateForgotPassword = (email) => ({
  type: FORGOT_PASSWORD_INITIATE,
  payload: api.post(URLs.FORGOT_PASSWORD_URL, email)
});
