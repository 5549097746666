import styled from 'styled-components';
import {
    Typography,
    Button,
    Table,
    Avatar,
    Drawer,
    Dialog,
    FormControl,
    IconButton
} from '@material-ui/core';


export const PageFilter = styled.div
    `
    >div{
        margin:0 0.5em;
        min-width:150px;        
        &:first-child{
            margin-left:0px;
        }
        .MuiSelect-select.MuiSelect-select {
            padding-right: 24px;
            border: 1px solid #CCCCCC;
            border-radius: 5px;
            font-size: 14px;
            padding-left:12px;
        }
        
        .MuiInputLabel-outlined{
            font-size: 14px;
            transform: translate(14px, 28px) scale(1);
        }
        .MuiInputLabel-outlined.MuiInputLabel-shrink{
            transform: translate(0px, -8px) scale(1);
        }
        .MuiInput-underline:after, .MuiInput-underline:before{
            content:none;
        }
    }
    
`;

export const BtnModalClose = styled(IconButton)
    `
    position: absolute !important;
    right: 20px;
    top: 15px;
    
`;

export const MdSelectBTN = styled(FormControl)
    `  
    .MuiSelect-root{
        background: #EEEEEE;
        padding: 10px 14px !important;        
        padding-right: 24px !important;
        min-width: 130px;
        font-size: 15px;
    }
    .MuiInput-underline:after, .MuiInput-underline:before{
      content: none !important;
    }
 
    
`;

export const ModalBTN = styled(Button)
    `
    background: #${({ theme }: any) => theme.palette.primary.main} !important;    
    border: 0;
    min-width: 130px;
    color: #ffffff !important;
    text-transform: capitalize !important;    
    padding: 7px 14px !important;   
    
`;

export const SearchComponent = styled.div
    `  
    
    input{
        transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        width: 1px;
        box-sizing: content-box;
        animation-name: mui-auto-fill-cancel;
        -webkit-tap-highlight-color: transparent;
        border-bottom: 1px solid #ffffff;    
    }
    &.searchInputhidden{
        input{
            width: 1px;
        }        
    } 
    &.searchInputShow{
        
        input{
            width: auto;
            border-bottom: 1px solid #F3F3F3;    
        }
    }      
`;

export const LoadingWrap = styled.div
    `  
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
`;



export const GymsSelectBTN = styled(FormControl)
    `  
    .MuiSelect-root{
        background: #EEEEEE;
        padding: 10px 14px !important;        
        padding-right: 24px !important;
        min-width: 130px;
        font-size: 15px;
    }
    .MuiInput-underline:after, .MuiInput-underline:before{
      content: none !important;
    }
 
    
`;
export const AvatarUser = styled(Avatar)
    `
    width: 34px !important;
    height: 34px !important;
    margin-left: -10px !important;
`;
export const DialogTheme = styled(Dialog)
    `
    width: 34px !important;
    height: 34px !important;
    margin-left: -10px !important;
`;
export const FilterModal = styled(Drawer)
    `
    .FilterWrapUI {        
        overflow-y: auto;
        padding-top: 70px;
        padding-bottom: 70px;
        box-sizing: border-box;
    }
`;

export const ThemeTable = styled(Table)
    `
    td {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #313b67;
        border-bottom: 2px solid #f3f3f3;
    }
`;

export const TimeCol = styled.div`
    span {
        margin-left: 4px;
        color: ${({ theme }: any) => theme.palette.secondary.main};
    }
`;

export const TableCloDis = styled.div`
    strong {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #${({ theme }: any) => theme.palette.primary.main};
        text-transform: uppercase;
    }
    .small-star {
        transform: scale(0.8) translate(-7px, 6px);
    }
    .TableCloDis_name {
        font-style: italic;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #a3a8c2;
        margin-top: 3px;
        svg {
            margin-right: 6px;
        }
    }
    .TableCloDis_content {
        font-family: Fira Sans;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        margin-top: 8px;
        color: #${({ theme }: any) => theme.palette.primary.main};
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width:300px;
        position: relative;
        padding-right: 64px;
        span{
            position: absolute;
            right: 0;
            color: ${({ theme }: any) => theme.palette.secondary.main};
            cursor: pointer;
            &:hover{
                color: #${({ theme }: any) => theme.palette.primary.main};                
                text-shadow: 0.2px 0.2px 1px #${({ theme }: any) => theme.palette.primary.main};
            }
        }
    }
`;

export const SliderUI = styled.div`
    padding: 0 45px;
    .slick-prev,
    .slick-next {
        background: #ffe9e3;
        width: 42px;
        height: 35px;
        border-radius: 5px;
        right: -40px;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        &:before,
        &:before {
            opacity: 1;
            width: 0;
            height: 0;
            border-left: 12px solid #${({ theme }: any) => theme.palette.primary.main};
            border-bottom: 6px solid transparent;
            border-top: 6px solid transparent;
            font-size: 0;
        }
        &:hover{
            box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
        }
    }
    .slick-prev {
        transform: translate(0, -50%) rotate(180deg);
        left: -40px;
    }
`;


export const SliderUIModal = styled.div`
    padding: 0 45px;
    max-width: 312px;
    box-sizing: border-box;
    margin-bottom: 18px;
    .slick-prev,
    .slick-next {
        background: #fff;
        width: 26px;
        height: 25px;
        border-radius: 5px;
        border: 1px solid #DDDDDD;
        right: -40px;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        &:before,
        &:before {
            opacity: 1;
            width: 0;
            height: 0;
            border-left: 10px solid #E34C58;
            border-bottom: 6px solid transparent;
            border-top: 6px solid transparent;
            font-size: 0;
        }
        &:hover{
            box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
        }
    }
    .slick-prev {
        transform: translate(0, -50%) rotate(180deg);
        left: -40px;
    }
`;


export const SliderWrap = styled.div`
    color: #e2aa9a;
    text-align: center;
    position:relative;
    padding-bottom: 9px;
    cursor:pointer;
    &:focus{
        outline:none;
    }
    &:after{
        content: "";
        border-radius: 5px;        
        height: 2px;
        display: block;
        max-width: 55%;
        left: 0;
        right: 0;
        margin: 0 auto;
        position: absolute;
        bottom: 0px;
    }
    &.sliderActive{
        color: #313B67;
        :after{
            background: #${({ theme }: any) => theme.palette.primary.main};
        }
    }
    strong {
        display: block;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 2px;
    }
    span {
        font-size: 14px;
        font-weight: 100;
    }
`;
export const SliderWrapModal = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #434343;    
    text-align: center;
`;

export const SiteTitle = styled(Typography)
    `
    color: #434343;
    font-weight: 500;
    font-size: 28px;
`;

export const FilterBTN = styled(Button)
    `
    background: #${({ theme }: any) => theme.palette.primary.main} !important;    
    border: 0;
    width: 93px;
    color: #ffffff !important;
    text-transform: capitalize !important;
    font-weight: 300 !important;
    padding: 4px 8px !important;
    svg {
        margin-left: 8px;
    }   
`;

export const SortByBTN = styled(Button)
    `
    background: #ffffff !important;
    border: 0;
    color: #a1a1a1 !important;
    text-transform: capitalize !important;
    font-weight: 300 !important;
    padding: 0px 8px !important;
    margin-left: 22px !important;
    svg {
        margin-right: 8px;
    }
`;
export const CalenderBTN = styled(Button)
    `
    background: #ffffff !important;
    max-width: 42px !important;
    min-width: 42px !important;
    height: 36px;
    padding: 0px !important;
    border: 1px solid #dddddd !important;
    box-sizing: border-box;
    position:relative;
    .hidden-calender{
        position:absolute;
        visibility: hidden;
    }
`;

export const TableBTN = styled(Button)
    `
    background: #49589B !important;
    min-width: 140px !important;
    height: 34px;
    font-size:14px !important;
    color:#fff !important;
    padding: 0px !important;
    border: 0 !important;
    box-sizing: border-box;    
`;

export const CalenderBTNModule = styled.div
    `
    background: #ffffff !important;
    max-width: 42px !important;
    min-width: 42px !important;
    height: 36px;
    padding: 0px !important;    
    box-sizing: border-box;
    margin: 16px auto;
    margin-right: 0;
    .hidden-calender{
        position:absolute;
        visibility: hidden;
    }
`;
