import api from '../../api';
import URLs from '../../constants/urls';
import { GET_MY_SCHEDULE_LIST, CANCEL_CLASS, CANCEL_PREBOOK_CLASS } from './MyScheduleConstants';

export const getMyScheduleList = () => ({
  type: GET_MY_SCHEDULE_LIST,
  payload: api.get(`${URLs.GET_MY_SCHEDULE_LIST}`)
});

export const cancelClass = (gymBrand, cancelDetails) => ({
  type: CANCEL_CLASS,
  payload: api.post(`${URLs.CANCEL_CLASS_URL}?gymBrand=${gymBrand}`, cancelDetails)
});

export const cancelPrebookClass = (cancelDetails) => ({
    type: CANCEL_PREBOOK_CLASS,
    payload: api.post(URLs.CANCEL_PRE_BOOK_CLASS, cancelDetails)
  });
