import React, { Fragment } from 'react';
import env from '../../utils/env_variables';
import FacebookLoginButton from 'react-facebook-login';
import FbIcon from './assets/facebook.svg';
import { BtnFacebook } from './assets/style';
import PropTypes from 'prop-types';
import { pushNotification } from '../../utils/notifications';
import Messages from '../../constants/messages';
import { setInLocalStorage } from '../../utils/helpers';

const FacebookLogin = (props) => {
  const handleFacebookResponse = (response) => {
    const { facebookLoginRequest, updateReducerState, toggalAuthModal, getUserDetails, initializeUser, getClassTimeTableList } = props;
    if (response && response.accessToken && response.email && response.name) {
      const { accessToken, email, name } = response;
      facebookLoginRequest({ email, name }, accessToken)
        .then(response => {
          if (response && response.action && response.action.payload && response.action.payload.data
            && response.action.payload.data.status && response.action.payload.data.status === 'SUCCESS'
            && response.action.payload.headers && response.action.payload.headers.authorization) {
            setInLocalStorage('token', response.action.payload.headers.authorization);
            updateReducerState('auth', 'isLogin', true);
            pushNotification(Messages.LOGIN_SUCCESS, 'success', 'TOP_CENTER', 1000);
            updateReducerState('header', 'toggalAuthModal', !toggalAuthModal);
            getUserDetails()
              .then(response => {
                if (response && response.value && response.value.data && response.value.data.payload &&
                  response.value.data.payload.gymCredentials && response.value.data.payload.gymCredentials.length) {
                  initializeUser();
                }
              })
              .catch(error => {
                if (error && error.response && error.response.status &&
                  error.response.status === 401 && props && props.history && props.history.location &&
                  props.history.location.pathname === '/') {
                  updateReducerState('auth', 'isLogin', false);
                }
              });
            if (props && props.history && props.history.location &&
              props.history.location.pathname === '/time-table') {
              getClassTimeTableList('Bank');
            }
          }
        });
    } else {
      pushNotification(Messages.FACEBOOK_LOFIN_FAILED, 'error', 'TOP_CENTER', 1000);
    }
  };

  const handleFacebookLoginFailure = (error) => {
    console.log('respoerrornse', error);
    pushNotification(Messages.FACEBOOK_LOFIN_FAILED, 'error', 'TOP_CENTER', 1000);
  };

  return (
    <FacebookLoginButton
      appId={env.FACEBOOK_APP_IDE}
      fields={'name,email,picture'}
      callback={handleFacebookResponse}
      onFailure={handleFacebookLoginFailure}
      // cssClass={`ui facebook ${sinUpClass} ${this.props.buttonSize || 'big'} fluid button`}
      textButton={
        <Fragment>
          <BtnFacebook variant="contained" color="secondary"><img src={FbIcon} alt="FbIcon" />Facebook</BtnFacebook>
        </Fragment>
      }
    />
  );
};

FacebookLogin.propTypes = {
  facebookLoginRequest: PropTypes.func,
  updateReducerState: PropTypes.func,
  getUserDetails: PropTypes.func,
  initializeUser: PropTypes.func,
  getClassTimeTableList: PropTypes.func,
  toggalAuthModal: PropTypes.bool,
  history: PropTypes.object
};

export default FacebookLogin;

