import { connect } from 'react-redux';
import FacebookLogin from './index';
import { facebookLoginRequest } from './FacebookLoginActions';
import { updateReducerState } from '../../feature/Common/CommonActions';
import { getClassTimeTableList } from '../ClassTimeTable/ClassTimeTableActions';
import { initializeUser, getUserDetails } from '../Auth/AuthActions';

const mapStateToProps = state => ({
  facebookLoginLoading: state.facebook.facebookLoginLoading,
  toggalAuthModal: state.header.toggalAuthModal
});

const mapDispatchToProps = dispatch => ({
  facebookLoginRequest: (value, token) => dispatch(facebookLoginRequest(value, token)),
  updateReducerState: (reducerKey, key, value) => dispatch(updateReducerState(reducerKey, key, value)),
  initializeUser: () => dispatch(initializeUser()),
  getUserDetails: () => dispatch(getUserDetails()),
  getClassTimeTableList: (gymLocation) => dispatch(getClassTimeTableList(gymLocation))
});

export default connect(mapStateToProps, mapDispatchToProps)(FacebookLogin);
