import {
  GET_GYMS_NAME_LIST_LOADING, GET_GYMS_NAME_LIST_FAILURE, GET_GYMS_NAME_LIST_SUCCESS
} from './CommonConstants';

const initialState = {
  gymsNameList: [],
  gymsNameListLoading: false
};

const getGymsNameListStart = (state) => ({
  ...state,
  gymsNameListLoading: true
});

const getGymsNameListSuccess = (state, action) => {
  if (action && action.payload && action.payload.data && action.payload.data.payload) {
    const result = action.payload.data.payload.map((item, index) => ({ label: item, value: `${item}_${index + (329 * 7)}`, type: 'location' }));
    state.gymsNameList = result;
  }
  state.gymsNameListLoading = false;
  return {
    ...state
  };
};

const getGymsNameListFailure = (state) => ({
  ...state,
  gymsNameListLoading: false
});

const homeReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_GYMS_NAME_LIST_LOADING: return getGymsNameListStart(state, action);
    case GET_GYMS_NAME_LIST_SUCCESS: return getGymsNameListSuccess(state, action);
    case GET_GYMS_NAME_LIST_FAILURE: return getGymsNameListFailure(state, action);
    default:
      return {
        ...state
      };
  }
};

export default homeReducer;
