import { connect } from 'react-redux';
import Header from './index';
import { logOutUser } from './HeaderActions';
import { updateReducerState } from '../../feature/Common/CommonActions';

const mapStateToProps = state => ({
  isLogin: state.auth.isLogin,
  logOutUserLoading: state.header.logOutUserLoading,
  toggalAuthModal: state.header.toggalAuthModal,
  authModalType: state.header.authModalType
});

const mapDispatchToProps = dispatch => ({
  logOutUser: () => dispatch(logOutUser()),
  updateReducerState: (reducerKey, key, value) => dispatch(updateReducerState(reducerKey, key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
