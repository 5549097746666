import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Dialog } from '@material-ui/core';
import { appRedirection } from '../../constants/globalConstants';
import history from '../../utils/history';
import { AppRoutes } from '../../constants/appRoutes';
import { VerifyBtn, DialogTitle } from './style';

const VerifyUser = (props) => {
  const [open, setOpen] = React.useState(true);
  const { verifyUserEmail, verifyUserPlatform, verifyUserLoading, verifyEmailError } = props;

  useEffect(() => {
    if (props && props.match && props.match.params && props.match.params.token) {
       verifyUserEmail(props.match.params.token);
    }
    // eslint-disable-next-line
  }, []);

const handleRedirection = () => {
  const newPageUrl = verifyUserPlatform ? appRedirection[verifyUserPlatform].URL : null;
  newPageUrl && window.open(newPageUrl, '_blank');
};

useEffect(() => {
  if(verifyEmailError) {
    history.push(AppRoutes.HOME);
  }
}, [verifyEmailError]);

  return (
    <Container text style={{ minHeight: '70vh', padding: '7.5vh 0' }}>
      {verifyUserLoading ? 'Loading...' : verifyEmailError ? null :
      <Dialog onClose={()=>setOpen(false)} open={open} disableBackdropClick disableEscapeKeyDown>
        <DialogTitle>  Your email has been verified successfully!</DialogTitle>
        <VerifyBtn onClick={() => handleRedirection()} >OK</VerifyBtn>
      </Dialog>
      }
    </Container>
  );
};

VerifyUser.propTypes = {
  verifyUserEmail: PropTypes.func,
  match: PropTypes.object,
  verifyUserPlatform: PropTypes.string,
  verifyUserLoading: PropTypes.bool,
  verifyEmailError: PropTypes.bool
};

export default VerifyUser;
