import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Select, MenuItem, FormControl, FormLabel, Input, Radio, Button, Box
} from '@material-ui/core';
import DayPicker from 'react-day-picker';
import { timePeriodDropDown } from '../../../utils/globalConstants';
import { CustomModalSection, CustomRadio, DatePickerBox } from '../assets/style';
import { CalenderButtonSvg } from '../../../utils/svgs';


const CustomBookForm = (props) => {
  const { customFormValue: { onOrNever, counts, selectedDays }, handleCustomValues,
    handleDateChange, handlerToggalCalender, toggalCalender } = props;

  return (
    <CustomModalSection>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormLabel variant="outlined">Repeat every</FormLabel >
            <Box className="label-box" display="flex" alignItems="flex-end">
              <FormControl>
                <Input
                    id="my-input"
                    aria-describedby="my-helper-text"
                    type="number"
                    value={counts}
                    onChange={e => handleCustomValues('counts', e.target.value)}
                    className="repeatEvery"
                />
              </FormControl>
              <FormControl className="multiselect-custom">
                <Box>
                  <Select
                      defaultValue="weeks"
                      onChange={e => handleCustomValues('timePeriod', e.target.value)}
                  >
                    {timePeriodDropDown.map((data) => (<MenuItem value={data.value} key={data.value}>{data.title}</MenuItem>))}
                  </Select>
                </Box>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              <FormControl>
                <FormLabel variant="outlined">Ends</FormLabel >
                <CustomRadio>
                  <Radio
                            checked={onOrNever === 'never'}
                            value="never"
                            type="radio"
                            name="Ends"
                            onChange={() => handleCustomValues('onOrNever', 'never')}
                  />
                  <label htmlFor="never">Never</label>
                  <Radio
                            checked={onOrNever === 'on'}
                            type="radio"
                            name="Ends"
                            value="on"
                            onChange={() => handleCustomValues('onOrNever', 'on')}
                  />
                  <label htmlFor="on">On</label>
                </CustomRadio>
              </FormControl>
              <FormControl>
                <Grid item xs={12} sm={12} className="customDatePicker">
                  <Button onClick={handlerToggalCalender}>
                    <CalenderButtonSvg />
                  </Button>
                  <DatePickerBox>
                    {toggalCalender &&
                      <DayPicker
                            selectedDays={selectedDays}
                            onDayClick={handleDateChange}
                      />}
                  </DatePickerBox>
                </Grid>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={1} />
    </CustomModalSection>
  );
};

CustomBookForm.propTypes = {
  customFormValue: PropTypes.object,
  toggalCalender: PropTypes.object,
  handleDateChange: PropTypes.func,
  handlerToggalCalender: PropTypes.func,
  handleCustomValues: PropTypes.func
};

export default CustomBookForm;
