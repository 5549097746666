import api from '../../api';
import {
  FACEBOOK_LOGIN
} from './FacebookLoginConstants';
import URLs from '../../constants/urls';

export const facebookLoginRequest = (value, token) => ({
  type: FACEBOOK_LOGIN,
  payload: api.post(URLs.FACEBOOK_LOGIN_URL, value, { headers: { Authorization: `Bearer ${token}` } })
});
