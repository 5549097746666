import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LayoutContainer from './hoc/Layout/LayoutContainer';
import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider } from '@material-ui/core/styles';
import HomeConatiner from './feature/Home/HomeContainer';
import ClassTimeTableConatiner from './feature/ClassTimeTable/ClassTimeTableContainer';
import MyScheduleContainer from './feature/MySchedule/MyScheduleContainer';
import ResetPasswordContainer from './feature/ResetPassword/ResetPasswordContainer';
import VerifyUserContainer from './feature/VerifyUser/VerifyUserContainer';
import PrivateRoute from './hoc/PrivateRoute';
import ErrorPage from './feature/ErrorPage';

import theme from './theme';
import { AppRoutes } from './constants/appRoutes';
import TermsOfUse from './feature/TermsOfUse';
import PrivacyPolicy from './feature/PrivacyPolicy';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={theme}>
        <LayoutContainer>
          <Switch>
            <Route exact path={AppRoutes.RESET_PASSWORD} component={ResetPasswordContainer} />
            <Route exact path={AppRoutes.TIME_TABLE} component={ClassTimeTableConatiner} />
            <PrivateRoute exact path={AppRoutes.SCHEDULE} component={MyScheduleContainer} />
            <Route exact path={AppRoutes.VERIFY_TOKEN} component={VerifyUserContainer} />
            <Route exact path={AppRoutes.HOME} component={HomeConatiner} />
            <Route exact path={AppRoutes.PRIVACY_POLICY} component={PrivacyPolicy} />
            <Route exact path={AppRoutes.TERMS_OF_USE} component={TermsOfUse} />
            <Route path="/" name="404 Not found" component={ErrorPage} />
          </Switch>
        </LayoutContainer>
      </MuiThemeProvider>
    </ThemeProvider>
  );
}

export default App;
