import { connect } from 'react-redux';
import VerifyUser from './index';
import { verifyUserEmail } from './VerifyUserActions';

const mapStateToProps = state => ({
  verifyUserLoading: state.verify.verifyUserLoading,
  verifyUserPlatform: state.verify.verifyUserPlatform,
  verifyEmailError: state.verify.verifyEmailError
});

const mapDispatchToProps = dispatch => ({
  verifyUserEmail: (token) => dispatch(verifyUserEmail(token))
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyUser);
