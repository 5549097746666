import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Menu } from '@material-ui/core';
import { SortByButtonSvg } from '../../../utils/svgs';
import { SortByBTN } from '../assets/style';
import { sortByPopularity } from '../../../utils/globalConstants';

const SortByRating = (props) => {
  const { sortedClasses, toggalSortMenu, handlerSetToggleDetails } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    handlerSetToggleDetails('toggalSortMenu');
  };

  const handleClose = () => {
    setAnchorEl(null);
    handlerSetToggleDetails('toggalSortMenu');
  };

  const handleSetDetails = (value) => {
    if (value) {
      sortedClasses(value);
    }
    setAnchorEl(null);
    handlerSetToggleDetails('toggalSortMenu');
  };

  return (
    <div>
      <SortByBTN
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={event => handleClick(event)}
      >
        <SortByButtonSvg />
        Sort By
      </SortByBTN>
      <Menu
        anchorEl={anchorEl}
        id="simple-menu"
        keepMounted
        open={toggalSortMenu}
        onClose={handleClose}
      >
        {sortByPopularity.map((data, index) => (<MenuItem key={index + 1} onClick={() => handleSetDetails(data.value)}>{data.title}</MenuItem>))}
      </Menu>
    </div >
  );
};


SortByRating.propTypes = {
  sortedClasses: PropTypes.func,
  handlerSetToggleDetails: PropTypes.func,
  toggalSortMenu: PropTypes.bool
};

export default SortByRating;
