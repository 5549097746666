import {
  LOGIN_REQUEST_LOADING, LOGIN_REQUEST_SUCCESS, LOGIN_REQUEST_FAILURE,
  REGISTER_USER_LOADING, REGISTER_USER_SUCCESS, REGISTER_USER_FAILURE,
  GET_USER_DETAILS_LOADING, GET_USER_DETAILS_SUCCESS, GET_USER_DETAILS_FAILURE,
  GYMBOX_LOGIN_LOADING, GYMBOX_LOGIN_SUCCESS, GYMBOX_LOGIN_FAILURE,
  FORGOT_PASSWORD_INITIATE_LOADING, FORGOT_PASSWORD_INITIATE_SUCCESS, FORGOT_PASSWORD_INITIATE_FAILURE
} from './AuthConstants';
import Messages from '../../constants/messages';
import { pushNotification } from '../../utils/notifications';
import { setInLocalStorage } from '../../utils/helpers';

const initialState = {
  userDetails: null,
  loginLoading: false,
  registerUserLoading: false,
  isLogin: false,
  userDetailsLoading: false,
  gymBoxLoginLoading: false,
  forgotPasswordLoading: false
};

const loginStart = (state) => ({
  ...state,
  loginLoading: true
});

const loginSuccess = (state, action) => {
  if (action.payload && action.payload.headers && action.payload.headers.authorization) {
    setInLocalStorage('token', action.payload.headers.authorization);
  }
  pushNotification(Messages.LOGIN_SUCCESS, 'success', 'TOP_CENTER', 1000);
  if (action.payload && action.payload.data && action.payload.data.status === 'SUCCESS' && action.payload.data.payload.user && action.payload.data.payload.user) {
    state.userDetails = action.payload.user;
  }
  state.isLogin = true;
  state.loginLoading = false;
  return ({
    ...state
  });
};

const loginFailure = (state, action) => {
  if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
    pushNotification(action.payload.response.data.error, 'error', 'TOP_CENTER', 2000);
  } else {
    pushNotification(Messages.LOGIN_FAILED, 'error', 'TOP_CENTER', 2000);
  }
  return ({
    ...state,
    loginLoading: false,
    isLogin: false
  });
};


const registerUserStart = (state) => ({
    ...state,
    registerUserLoading: true
  });


const registerUserSuccess = (state, action) => {
  if (action && action.payload && action.payload.status && action.payload.status === 201
    && action.payload.data && action.payload.data.payload) {
    pushNotification(action.payload.data.payload, 'success', 'TOP_CENTER', 1000);
  } else {
    pushNotification(Messages.REGISTER_SUCCESS, 'success', 'TOP_CENTER', 1000);
  }
  return ({
    ...state,
    registerUserLoading: false
  });
};

const registerUserFailure = (state, action) => {
  if (action.payload && action.payload.response && action.payload.response.data &&
    action.payload.response.data.error && action.payload.response.data.error.reason) {
    pushNotification(action.payload.response.data.error.reason, 'error', 'TOP_CENTER', 2000);
  } else {
    pushNotification(Messages.LOGIN_FAILED, 'error', 'TOP_CENTER', 2000);
  }
  return ({
    ...state,
    registerUserLoading: false
  });
};

// Get user information
const getUserDetailsStart = (state) => ({
  ...state,
  userDetailsLoading: true
});

const getUserDetailsSuccess = (state, action) => {

  if (action && action.payload && action.payload.data && action.payload.data.payload && action.payload.data.payload) {
    state.userDetails = action.payload.data.payload;
  }
  state.userDetailsLoading = false;
  return ({
    ...state
  });
};

const getUserDetailsFailure = (state) => ({
  ...state,
  userDetailsLoading: false
});

// Gym box login
const gymBoxLoginStart = (state) => ({
  ...state,
  gymBoxLoginLoading: true
});

const gymBoxLoginSuccess = (state) => ({
  ...state,
  gymBoxLoginLoading: false
});

const gymBoxLoginFailure = (state) => ({
  ...state,
  gymBoxLoginLoading: false
});

// Forgot password
const initiateForgotPassStart = (state) => ({
  ...state,
  forgotPasswordLoading: true
});

const initiateForgotPassSuccess = (state, action) => {
  if (action.payload && action.payload.data && action.payload.data.status && action.payload.data.status === 'SUCCESS' && action.payload.data.payload) {
    pushNotification(action.payload.data.payload, 'success', 'TOP_CENTER', 3000);
  } else {
    pushNotification(action.FORGOT_PASSWORD_SUCCESS, 'success', 'TOP_CENTER', 3000);
  }
  return ({
    ...state,
    forgotPasswordLoading: false
  });
};

const initiateForgotPassFailure = (state, action) => {
  if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
    pushNotification(action.payload.response.data.error, 'error', 'TOP_CENTER', 2000);
  } else {
    pushNotification(Messages.FORGOT_PASSWORD_FAILED, 'error', 'TOP_CENTER', 2000);
  }
  return ({
    ...state,
    forgotPasswordLoading: false
  });
};

const authReducer = (state = initialState, action = {}) => {
  switch (action.type) {

    case LOGIN_REQUEST_LOADING: return loginStart(state, action);
    case LOGIN_REQUEST_SUCCESS: return loginSuccess(state, action);
    case LOGIN_REQUEST_FAILURE: return loginFailure(state, action);
    case REGISTER_USER_LOADING: return registerUserStart(state, action);
    case REGISTER_USER_SUCCESS: return registerUserSuccess(state, action);
    case REGISTER_USER_FAILURE: return registerUserFailure(state, action);
    case GET_USER_DETAILS_LOADING: return getUserDetailsStart(state, action);
    case GET_USER_DETAILS_SUCCESS: return getUserDetailsSuccess(state, action);
    case GET_USER_DETAILS_FAILURE: return getUserDetailsFailure(state, action);
    case GYMBOX_LOGIN_LOADING: return gymBoxLoginStart(state, action);
    case GYMBOX_LOGIN_SUCCESS: return gymBoxLoginSuccess(state, action);
    case GYMBOX_LOGIN_FAILURE: return gymBoxLoginFailure(state, action);
    case FORGOT_PASSWORD_INITIATE_LOADING: return initiateForgotPassStart(state, action);
    case FORGOT_PASSWORD_INITIATE_SUCCESS: return initiateForgotPassSuccess(state, action);
    case FORGOT_PASSWORD_INITIATE_FAILURE: return initiateForgotPassFailure(state, action);
    default:
      return {
        ...state
      };
  }
};

export default authReducer;
