import styled from 'styled-components';

export const PasswordAccept = styled.div`
p.subHead{
    color: #49589B;
    font-family: Fira Sans;
    margin-bottom:0;
}
li{
      padding-top:1px;
      padding-bottom:1px;
      padding-left:0;
      font-family: Fira Sans;
      .MuiListItemText-root{
        margin:0;
      }
  }
  svg{
      height:20px;
      &.cross-red{
          fill:red;
      }
      &.success{
          fill:green;
      }
  }
`;
