import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { retrieveFromLocalStorage } from '../utils/helpers';

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      retrieveFromLocalStorage('token') ? (<Component {...props} />)
        :
        (<Redirect to={{ pathname: '/', state: { from: props.location } }} />)
    }
  />
);

PrivateRoute.propTypes = {
  location: PropTypes.object
};

export default PrivateRoute;
