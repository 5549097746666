import {
  GOOGLE_LOGIN_LOADING, GOOGLE_LOGIN_SUCCESS, GOOGLE_LOGIN_FAILURE
} from './GoogleLoginConstants';
import Messages from '../../constants/messages';
import { pushNotification } from '../../utils/notifications';

const initialState = {
  googleLoginLoading: false
};

// Forgot password
const googleLoginStart = (state) => ({
  ...state,
  googleLoginLoading: true
});

const googleLoginSuccess = (state) => ({
    ...state,
    googleLoginLoading: false
  });

const googleLoginFailure = (state, action) => {
  console.log('google fail', action.payload.response);
  if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.error) {
    pushNotification(action.payload.response.data.error, 'error', 'TOP_CENTER', 2000);
  } else {
    pushNotification(Messages.FORGOT_PASSWORD_FAILED, 'error', 'TOP_CENTER', 2000);
  }
  return ({
    ...state,
    googleLoginLoading: false
  });
};

const googleReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GOOGLE_LOGIN_LOADING: return googleLoginStart(state, action);
    case GOOGLE_LOGIN_SUCCESS: return googleLoginSuccess(state, action);
    case GOOGLE_LOGIN_FAILURE: return googleLoginFailure(state, action);
    default:
      return {
        ...state
      };
  }
};

export default googleReducer;
