import styled from 'styled-components';
import {
  Button
} from '@material-ui/core';

export const SortByBTN = styled(Button)
  `
    background: #ffffff !important;
    border: 0;
    color: #a1a1a1 !important;
    text-transform: capitalize !important;
    font-weight: 300 !important;
    padding: 0px 8px !important;
    margin-left: 22px !important;
    svg {
        margin-right: 8px;
    }
`;
