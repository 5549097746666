import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { retrieveFromLocalStorage } from '../../utils/helpers';
import homeImg from './assets/banner.png';
import { SeoHelmet } from '../../utils/seoHelmet';

const Home = (props) => {
  const { updateReducerState, isLogin } = props;

  useEffect(() => {
    if (!retrieveFromLocalStorage('token') && isLogin) {
      updateReducerState('auth', 'isLogin', false);
    }
     // eslint-disable-next-line
  }, []);

  return (
    <div style={{ textAlign: 'center' }}>
      <SeoHelmet title="Home" />
      <img width="100%" src={homeImg} alt="homeImg" />
    </div>
  );
};

Home.propTypes = {
  updateReducerState: PropTypes.func,
  isLogin: PropTypes.bool
};

export default Home;
