import styled from 'styled-components';

export const VerifyBtn = styled.button`
    max-width: 130px;
    width: 100%;
    margin: 0 auto;
    border: unset;
    border-radius: 3px;
    padding: 12px;
    color: white;
    background: #49589b;
    font-weight: 700;
    margin-bottom: 25px;
    cursor: pointer;
    `;

export const DialogTitle = styled.h2`
font-size: 1.4285714285714284rem;
font-family: Fira Sans,sans-serif;
font-weight: 500;
line-height: 1.6;
color: #49589b;
padding:15px;
@media(max-width:767px){
    font-size:16px;
}
`;
