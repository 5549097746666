import api from '../../api';
import {
  GOOGLE_LOGIN
} from './GoogleLoginConstants';
import URLs from '../../constants/urls';

export const googleLoginRequest = (token, headerToken) => ({
  type: GOOGLE_LOGIN,
  payload: api.post(URLs.GOOGLE_LOGIN_URL, token, { headers: { Authorization: `Bearer ${headerToken}` } })
});
