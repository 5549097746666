import React, { useState, Fragment, useEffect } from 'react';
import Slider from 'react-slick';
import _ from 'lodash';
import {
  Dialog, DialogContentText, DialogActions, DialogContent,
  DialogTitle, Backdrop, Select, MenuItem, Tooltip, CircularProgress
} from '@material-ui/core';
import { DateUtils } from 'react-day-picker';
import { gymScheduleDropDown } from '../../../utils/globalConstants';
import PropTypes from 'prop-types';
import {
  SliderWrapModal, SliderUIModal, MdSelectBTN, ModalBTN, AvatarUser, BtnModalClose,
  DialogBoxCustom, CustomBtnGroup, DetailObject
} from '../assets/style';
import { NameArrowSvg } from '../../../utils/svgs';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import CloseIcon from '@material-ui/icons/Close';
import Image1 from '../assets/01.png';
import Image2 from '../assets/02.png';
import Image3 from '../assets/03.png';
import CustomBookForm from './customBookForm';

const Modal = (props) => {
  const { openModal, handlerDetailModal, classDetails, timeTableArray, bookGymClass,
    preBookGymClass, bookClassLoading, gymClassId, setGymClassId, getClassTimeTableList } = props;

  const [modalDetails, setModalDetails] = useState({
    sliderIndex: 0,
    details: classDetails,
    sliderListArray: [],
    initialsIndex: 0,
    gymScheduleDropDown: 'notRepeat'
  });
  const [timeTableDetails, setTimeTableDetails] = useState({
    toggalCalender: false,
    sliderIndex: 0,
    classDetails: {}
  });
  const [customFormValue, setCustomFormValue] = useState({
    timePeriod: 'weeks',
    onOrNever: 'on',
    counts: 1,
    selectedDays: []
  });

  useEffect(() => {
    if (classDetails && timeTableArray.length) {
      let serchObj = { name: classDetails.name };
      if (classDetails && classDetails.instructorName) {
        serchObj = {
          ...serchObj,
          instructorName: classDetails.instructorName
        };
      }
      const resultArray = _.groupBy(timeTableArray, serchObj);
      if (resultArray && resultArray.true) {
        modalDetails.sliderListArray = resultArray.true;
        modalDetails.initialsIndex = resultArray.true.findIndex((value) => value.date === classDetails.date && value.time === classDetails.time);
        setModalDetails({
          ...modalDetails
        });
      }
    };
     // eslint-disable-next-line
  }, []);

  const changeSliderValue = (newIndex) => {
    modalDetails.sliderIndex = newIndex;
    modalDetails.details = modalDetails.sliderListArray[newIndex];
    setModalDetails({
      ...modalDetails
    });
  };

  const settingsModal = {
    dots: false,
    infinite: false,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    draggable: false,
    // autoplaySpeed: 20000,
    initialSlide: modalDetails.initialsIndex,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const handlerToggalCalender = () => {
    setTimeTableDetails({
      ...timeTableDetails,
      toggalCalender: !timeTableDetails.toggalCalender
    });
  };

  const handleDateChange = (day, { selected }) => {
    const { selectedDays } = customFormValue;
    if (selected) {
      const selectedIndex = selectedDays.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }
    setCustomFormValue({ ...customFormValue, selectedDays });
  };

  const showClassDetails = () => {
    const { sliderListArray, initialsIndex } = modalDetails;
    let detailsObj = {};
    if (Object.keys(details).length !== 0) {
      detailsObj = modalDetails.details;
    } else {
      // eslint-disable-next-line prefer-destructuring
      detailsObj = sliderListArray[initialsIndex];
    }

    const handleCustomValues = (name, value) => {
      if (name === 'counts' && value < 1) {
        return;
      }
      setCustomFormValue({ ...customFormValue, [name]: value });
    };

    const bookClass = (obj) => {
      setGymClassId(obj && obj.gymClassId ? obj.gymClassId : null);
      let gymDetails = {
        gymClassId: obj.gymClassId
      };

      if (modalDetails.gymScheduleDropDown === 'notRepeat') {
        bookGymClass('GymBox', gymDetails)
          .then(response => {
            if (response && response.action && response.action.payload && response.action.payload.data &&
              response.action.payload.data.status === 'SUCCESS') {
              handlerDetailModal();
              getClassTimeTableList('Bank');
            }
          });
      } else if (modalDetails.gymScheduleDropDown === 'custom') {
        console.log('custom');
      } else {
        gymDetails = {
          ...gymDetails,
          isAuto: true
        };
        preBookGymClass(gymDetails)
          .then(response => {
            if (response && response.action && response.action.payload && response.action.payload.data &&
              response.action.payload.data.status === 'SUCCESS') {
              handlerDetailModal();
              getClassTimeTableList('Bank');
            }
          });
      }
    };

    return (
      <Fragment>
        <DialogTitle classes={{ root: 'modal-title' }} onClose={handlerDetailModal} disableTypography>
          {/* {details.gymLocation}, Michigan */}
          {detailsObj.gymLocation}, {detailsObj.date}
          <BtnModalClose aria-label="close" onClick={handlerDetailModal} >
            <CloseIcon />
          </BtnModalClose>
        </DialogTitle>
        <DialogContent >
          <SliderUIModal>
            <Slider {...settingsModal} afterChange={(newIndex) => changeSliderValue(newIndex)} >
              {sliderListArray.map((value, index) => (
                <SliderWrapModal key={index}>
                  <span>{`${value.date},${value.time} (${value.duration})`}</span>
                </SliderWrapModal>
              ))}
            </Slider>
          </SliderUIModal>
          <div className="TableCloDis_name">
            <NameArrowSvg />
            {detailsObj.instructorName}
          </div>
          <AvatarGroup>
            <AvatarUser small alt="Remy Sharp" src={Image1} />
            <AvatarUser small alt="Remy Sharp" src={Image2} />
            <AvatarUser small alt="Remy Sharp" src={Image3} />
            <Tooltip title="Foo • Bar • Baz">
              <AvatarUser>+3</AvatarUser>
            </Tooltip>
          </AvatarGroup>
          <DetailObject><strong>{detailsObj.name}</strong></DetailObject>

          <DialogContentText gutterBottom>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
            in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
          </DialogContentText>
          <DialogContentText gutterBottom>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis
            lacus vel augue laoreet rutrum faucibus dolor auctor.
          </DialogContentText>
          <DialogContentText gutterBottom>
            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel
            scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus
            auctor fringilla.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogBoxCustom>
            <MdSelectBTN classes={{ root: 'custom-select' }}>
              {modalDetails.gymScheduleDropDown === 'custom' &&
                <CustomBookForm
                  customFormValue={customFormValue}
                  handleCustomValues={handleCustomValues}
                  handleDateChange={handleDateChange}
                  handlerToggalCalender={handlerToggalCalender}
                  toggalCalender={timeTableDetails.toggalCalender}
                />
              }
            </MdSelectBTN>
            <CustomBtnGroup>
              <Select
                value={modalDetails.gymScheduleDropDown}
                onChange={e => { setModalDetails({ ...modalDetails, gymScheduleDropDown: e.target.value }); }}
              >
                {gymScheduleDropDown.map((data) => (<MenuItem value={data.value} key={data.value}>{data.title}</MenuItem>))}
              </Select>
              {bookClassLoading && gymClassId && gymClassId === detailsObj.gymClassId ?
                <CircularProgress />
                :
                <ModalBTN color="primary" onClick={() => bookClass(detailsObj)}>
                  {detailsObj.status}
                </ModalBTN>
              }
            </CustomBtnGroup>
          </DialogBoxCustom>
        </DialogActions>
      </Fragment>
    );
  };

  const { details, sliderListArray } = modalDetails;
  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      classes={{ root: 'theme-modal' }}
      open={openModal && sliderListArray.length}
      onClose={handlerDetailModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      maxWidth="md"
      BackdropProps={{
        timeout: 500
      }}
    >
      {showClassDetails()}
    </Dialog >
  );
};


Modal.propTypes = {
  openModal: PropTypes.bool,
  handlerDetailModal: PropTypes.func,
  classDetails: PropTypes.object,
  timeTableArray: PropTypes.array,
  bookGymClass: PropTypes.func,
  preBookGymClass: PropTypes.func,
  bookClassLoading: PropTypes.bool,
  gymClassId: PropTypes.string,
  setGymClassId: PropTypes.func,
  getClassTimeTableList: PropTypes.func
};

export default Modal;
