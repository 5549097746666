import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import textContent from './ResetPasswordConstants';
import { InputBox } from '../../utils/FormUtils';
import Validator from '../../utils/Validator';
import {
    InputController,
    PasswordListWrap,
    Title
} from '../ResetPassword/assets/style';
import { FormWrap } from '../Auth/components/assets/style';
import history from '../../utils/history';
import { SeoHelmet } from '../../utils/seoHelmet';
import PasswordAcceptance from '../../common/PasswordAcceptance';
import SeoTexts from '../../constants/seoConstants';

const ResetPassword = props => {
    const { resetPasswordLoading } = props || {};
    // state manupulation
    const [passwordState, setPasswordState] = useState({
        password: '',
        confirmPassword: ''
    });
    const [error, setErrors] = useState({});

    // For reset form validation
    const _isValid = (field = null, value) => {
        if (field) {
            passwordState[field] = value;
        }
        const validate = Validator.createValidator(
            {
                password: ['strongPassword'],
                confirmPassword: ['required', 'match|password']
            },
            {
                password: passwordState.password,
                confirmPassword: passwordState.confirmPassword
            },
            field,
            {
                password: '',
                confirmPassword: ''
            }
        );
        return validate;
    };

    const onChangeHandler = (value, name) => {
        setPasswordState({ ...passwordState, [name]: value });
        validateOnBlur(name, value);
    };

    // Validations on blur
    const validateOnBlur = (name, value) => {
        const { errors } = _isValid(name, value);
        if (name === 'confirmPassword') {
            setErrors({
                ...error,
                confirmPassword: confirmPassword
                    ? errors?.confirmPassword
                    : undefined
            });
        } else {
            setErrors({ ...error, [name]: errors[name] });
        }
    };

    // handle reset password
    const handleSaveClick = () => {
        const {
            resetPasswordRequest,
            match: {
                params: { token }
            }
        } = props;
        if (token) {
            const { isValid } = _isValid();
            if (isValid) {
                const data = {
                    forgotPasswordToken: token,
                    password: passwordState.password
                };
                resetPasswordRequest(data).then(response => {
                    if (
                        response &&
                        response.value &&
                        response.value.status &&
                        response.value.status === 200
                    ) {
                        history.push('/');
                    }
                });
            } else {
                const { errors } = _isValid();
                setErrors({ ...errors });
            }
        }
    };
    const { password, confirmPassword } = passwordState;
    const errorCond =
        error && (error.password || error.confirmPassword) ? true : false;

    const isDisable = errorCond || !password || !confirmPassword;

    return (
      <Grid>
        <SeoHelmet title={SeoTexts.RESET_PASSWORD_TITLE} />
        <FormWrap className="reset-pass-form">
          <Title align="center" variant="h6" component="h2">
              Reset Your Password
          </Title>
          <InputController>
            <InputBox
                label={textContent.PASSWORD_FIELD_LABEL}
                placeholder={textContent.PASSWORD_FIELD_PLACEHOLDER}
                type={'password'}
                onChange={onChangeHandler}
                name={'password'}
                value={password}
            />
          </InputController>
          <PasswordListWrap className="mb-2 text-center">
            <PasswordAcceptance value={password} />
          </PasswordListWrap>
          <InputController className="input-box-controller">
            <InputBox
                errorMessage={error.confirmPassword}
                label={textContent.CONFIRM_PASSWORD_FIELD_PLACEHOLDER}
                placeholder={
                            textContent.CONFIRM_PASSWORD_FIELD_PLACEHOLDER
                        }
               type={'password'}
                onChange={onChangeHandler}
                name={'confirmPassword'}
                value={confirmPassword}
            />
          </InputController>
          <div className="text-center">
            <Button
                disabled={resetPasswordLoading || isDisable}
                variant="contained"
                color="secondary"
                onClick={handleSaveClick}
                className="forgot-reset-password-form__btn"
            >
              Save
            </Button>
          </div>
        </FormWrap>
      </Grid>
    );
};

ResetPassword.propTypes = {
    match: PropTypes.object,
    resetPasswordRequest: PropTypes.func
};

export default ResetPassword;
