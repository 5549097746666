import React from 'react';
import { Grid, Container } from '@material-ui/core';
import { SeoHelmet } from '../../utils/seoHelmet';
import SeoTexts from '../../constants/seoConstants';
import './style.js';
import { Privacysec } from './style';


const PrivacyPolicy = () => (
  <>
    <SeoHelmet title={SeoTexts.PRIVACY_POLICY_TITLE} />
    <Privacysec>
      <Container textAlign="center" className="privacy-sec">
        <Grid container spacing={3}>
          <Grid item xs={12} >
            <h1>Privacy Policy</h1>
          </Grid>
          <Grid item xs={12} className="content" >
            <p>
                  This Privacy Policy sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. For the purposes of the Data Protection Act 2018 and the General Data Protection Regulation (GDPR), GymClass is the data controller of personal information we hold about you.
            </p>
            <p>
                  Please read the following carefully to understand our views and practices regarding your personal data or personal information, as well as the rights you have in respect of GymClass’s use of your personal information. By visiting https://stage.gymclass.net/ you are accepting and consenting to the practices described in this Policy.
            </p>
            <br/>
            <h3>
                 What This Policy Covers
            </h3>
            <p>
                 This Policy applies to your use of GymClass’s products and services, including the website, email alerts, newsletters, other commercial messages through the communication channels you have chosen, blog posts, discussions and posts on our social media channels (our Services).
            </p>
            <br/>
            <h3>
                When we collect your personal information
            </h3>
            <p>
                RFF collects personal information about you when you:
            </p>
            <ul>
              <li>
                Visit and use our website;
              </li>
              <li>Register, subscribe for or sign in/up to our Services;
              </li>
              <li>
                  Send or receive email communications to or from RFF, send or receive commercial messages through the communication channels you have chosen (such as Whatsapp alerts or text messages) or click on any email communications from RFF;
              </li>
              <li>
                Voluntarily provide us personal information by phone, email, live chats, message boards, in letters, online forms and other correspondence;
              </li>
              <li>
                Contribute feedback, questions, comments, posts or discussions through our Services.
              </li>
            </ul>
            <br/>
            <h3>Personal information that we collect from you</h3>
            <p>When you subscribe to or use our Services, we may collect the following personal information from you:</p>
            <ul>
              <li>
                Contact information such as name, email address, country of residence, and mobile number, username and password;
              </li>
              <li>Billing information such as credit card information and billing address;
              </li>
              <li>
                Information about your transactions with us and our business partners (such as payment providers) through your use of our Services;
              </li>
              <li>
                Technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, internet service provider (ISP), time zone setting, browser plug-in types and versions, operating system and platform; referring/exit pages.
              </li>
              <li>
                Information about your website visit, including the full Uniform Resource Locators (URL), clickstream to, through and from our website (including date and time), page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs) and methods used to browse away from the page;
              </li>
              <li>
                Personal information that you give to us by filling in forms on our website, by corresponding with us by phone, e-mail or otherwise;
              </li>
              <li>
                Information through the use of third party web analytics services (such as Google Analytics) to provide information such as the number of visits to a particular page or the amount of time spent on a website.
              </li>
            </ul>
            <br/>
            <h3>How we use your personal information</h3>
            <ul>
              <li>
                To carry out our obligations arising from any contracts entered into between you and us and to provide you with the products or services you have requested from us
              </li>
              <li>When this is in accordance with your marketing communications preferences or alert settings, we may send you push notifications, emails and text messages from RFF relating to our Services. You can opt-out of our marketing communications at any time
              </li>
              <li>
                To provide you with information about goods or services we feel may interest you.
              </li>
              <li>
                To measure or understand the effectiveness of advertising we serve to you;
              </li>
              <li>
                To notify you about changes to our Services;
              </li>
              <li>
                To administer our website and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;
              </li>
              <li>
                To improve and promote our Services.
              </li>
            </ul>
            <br/>
            <h2>
              When we share and disclose information
            </h2>
            <ul>
              <li>
                RFF does not rent or sell personal information about you to third parties. However, we reserve the right to share the personal information we collect about you with third parties when we have your permission or under the following circumstances:
              </li>
              <li>
                If you are subscribing to our Services, we work with third parties and trusted business partners, such as Stripe, to securely collect your credit card, billing address and other verifying information. Our third party partners are required to follow generally accepted industry standards to protect the personal information they receive and/or process on our behalf.
              </li>
              <li>
                We may also provide data to our third party analytics partners, such as Google Analytics, for the purposes of tracking usage information and site traffic. We will share your personal information only as necessary for the third party to provide that service.
              </li>
              <li>
                We may transfer, share, or disclose your personal information to third parties as part of any corporate reorganization process such as a merger, acquisition, and/or a sale of all, substantially all, or part of our assets, and/or a licensing agreement with third parties.
              </li>
            </ul>
            <br/>
            <h2>
              Data retention
            </h2>
            <p>
               We will hold your personal information on our systems for as long as we have an ongoing legitimate business need to do so (for example, to provide you with a service you have requested or to comply with applicable legal, tax or accounting requirements).
            </p>
            <p>
              Where we process personal data for marketing purposes or with your consent, we process the data until you ask us to stop and for a short period after this (to allow us to implement your request)
            </p>
            <br/>
            <h2>
              Your data protection rights
            </h2>
            <h4 className="plan-icon">The right to be informed</h4>
            <p>We have told you in this policy what information we collect about you and how we use it. Feel free to ask us for more details.</p>
            <h4 className="plan-icon">
            The right of access
            </h4>
            <p> You can ask us at any time, free of charge, for a copy of the data we hold about you.</p>
            <h4 className="plan-icon">
            The right of rectification
            </h4>
            <p>If you think any of the information we hold about you is incorrect, you can ask us at any time and we’ll correct it.</p>
            <h4 className="plan-icon">
            The right of erasure
            </h4>
            <p> You can ask us at any time to delete the data we hold about you.</p>
            <h4 className="plan-icon">
            The right to restrict processing
            </h4>
            <p> Under certain circumstances (for example, if you suspect that the data we hold about you is incorrect), you can ask us to temporarily stop processing data about you.</p>
            <h4 className="plan-icon">
            The right to data portability
            </h4>
            <p>You can ask us at any time, free of charge, for a copy of the data we hold about you in a machine-readable format, for example, a CSV spreadsheet.</p>
            <h4 className="plan-icon">
            The right not to be subjected to automated decision-making (including profiling)
            </h4>
            <br/>
            <h3>
                Complaints
            </h3>
            <p>
                Initial complaints should be made to RFF at the email address above. If you remain unhappy, you can make a complaint to the UK’s regulator, the Information Commissioner’s Office https://ico.org.uk/
            </p>
          </Grid>
        </Grid>
      </Container>
    </Privacysec>
  </>
    );


export default PrivacyPolicy;
