import { combineReducers } from 'redux';
import CommonReducer from '../feature/Common/CommonReducer';
import AuthReducer from '../feature/Auth/AuthReducer';
import ClassTimeTableReducer from '../feature/ClassTimeTable/ClassTimeTableReducer';
import MyScheduleReducer from '../feature/MySchedule/MyScheduleReducer';
import HeaderReducer from '../shared/Header/HeaderReducer';
import ResetPasswordReducer from '../feature/ResetPassword/ResetPasswordReducer';
import FacebookLoginReducer from '../feature/FacebookLogin/FacebookLoginReducer';
import GoogleLoginReducer from '../feature/GoogleLogin/GoogleLoginReducer';
import VerifyUserReducer from '../feature/VerifyUser/VerifyUserReducer';

const appReducer = combineReducers({
    auth: AuthReducer,
    timeTable: ClassTimeTableReducer,
    mySchedule: MyScheduleReducer,
    common: CommonReducer,
    header: HeaderReducer,
    reset: ResetPasswordReducer,
    facebook: FacebookLoginReducer,
    google: GoogleLoginReducer,
    verify: VerifyUserReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'UPDATE_REDUCER_STATE') {
        if (action.payload.reducerKey && action.payload.key) {
            const { reducerKey, key, value = null } = action.payload;
            state = {
                ...state,
                [reducerKey]: {
                    ...state[reducerKey],
                    [key]: value
                }
            };
        }
    }
    return appReducer(state, action);
};

export default rootReducer;
