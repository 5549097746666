import React from 'react';

export const HeaderLogoSvg = () => (
  <svg width="30" height="19" viewBox="0 0 30 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.13208 8.52784H28.8679C29.4928 8.52784 30 9.03501 30 9.65992C30 10.2848 29.4928 10.792 28.8679 10.792H1.13208C0.50717 10.792 0 10.2848 0 9.65992C0 9.03501 0.50717 8.52784 1.13208 8.52784Z" fill="white" />
    <path d="M0.662157 0.320441H16.885C17.2505 0.320441 17.5472 0.700819 17.5472 1.1695C17.5472 1.63818 17.2505 2.01855 16.885 2.01855H0.662157C0.296647 2.01855 0 1.63818 0 1.1695C0 0.700819 0.296647 0.320441 0.662157 0.320441Z" fill="white" />
    <path d="M13.115 17.3019H29.3379C29.7034 17.3019 30.0001 17.6823 30.0001 18.1509C30.0001 18.6196 29.7034 19 29.3379 19H13.115C12.7495 19 12.4529 18.6196 12.4529 18.1509C12.4529 17.6823 12.7495 17.3019 13.115 17.3019Z" fill="white" />
  </svg>
);

export const FilterButtonSvg = () => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.625 3.13232H6.19197C6.47161 4.21168 7.44522 5.01465 8.61084 5.01465C9.77646 5.01465 10.7501 4.21168 11.0297 3.13232H19.375C19.7201 3.13232 20 2.85238 20 2.50732C20 2.16227 19.7201 1.88232 19.375 1.88232H11.0297C10.7501 0.802968 9.77646 0 8.61084 0C7.44522 0 6.47161 0.802968 6.19197 1.88232H0.625C0.279948 1.88232 0 2.16227 0 2.50732C0 2.85238 0.279948 3.13232 0.625 3.13232ZM8.61084 1.25C9.3042 1.25 9.86816 1.81396 9.86816 2.50732C9.86816 3.20068 9.3042 3.76465 8.61084 3.76465C7.91748 3.76465 7.35352 3.20068 7.35352 2.50732C7.35352 1.81396 7.91748 1.25 8.61084 1.25Z" fill="white" />
    <path d="M19.375 6.88232H18.0365C17.7569 5.80297 16.7833 5 15.6177 5C14.4521 5 13.4784 5.80297 13.1988 6.88232H0.625C0.279948 6.88232 0 7.16227 0 7.50732C0 7.85238 0.279948 8.13232 0.625 8.13232H13.1988C13.4784 9.21168 14.4521 10.0146 15.6177 10.0146C16.7833 10.0146 17.7569 9.21168 18.0365 8.13232H19.375C19.7201 8.13232 20 7.85238 20 7.50732C20 7.16227 19.7201 6.88232 19.375 6.88232ZM15.6177 8.76465C14.9243 8.76465 14.3604 8.20068 14.3604 7.50732C14.3604 6.81396 14.9243 6.25 15.6177 6.25C16.311 6.25 16.875 6.81396 16.875 7.50732C16.875 8.20068 16.311 8.76465 15.6177 8.76465Z" fill="white" />
    <path d="M19.375 11.8823H7.26512C6.98552 10.803 6.01196 10 4.847 10C3.68139 10 2.70777 10.803 2.42813 11.8823H0.625C0.279948 11.8823 0 12.1623 0 12.5073C0 12.8524 0.279948 13.1323 0.625 13.1323H2.42813C2.70777 14.2117 3.68139 15.0146 4.847 15.0146C6.01196 15.0146 6.98552 14.2117 7.26512 13.1323H19.375C19.7201 13.1323 20 12.8524 20 12.5073C20 12.1623 19.7201 11.8823 19.375 11.8823ZM4.847 13.7646C4.15365 13.7646 3.58968 13.2007 3.58968 12.5073C3.58968 11.814 4.15365 11.25 4.847 11.25C5.53955 11.25 6.10352 11.814 6.10352 12.5073C6.10352 13.2007 5.53955 13.7646 4.847 13.7646Z" fill="white" />
  </svg>
);

export const SortByButtonSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.1546 22.9953C18.1546 23.3288 18.4243 23.5985 18.7578 23.5985C19.0913 23.5985 19.361 23.3288 19.361 22.9953V2.45754L22.9703 6.06686C23.088 6.18455 23.24 6.2434 23.3969 6.2434C23.5539 6.2434 23.7059 6.18455 23.8236 6.06686C24.059 5.83147 24.059 5.45386 23.8236 5.21847L19.1893 0.584226C18.9638 0.358643 18.5665 0.358643 18.3409 0.584226L13.7067 5.21847C13.4713 5.45386 13.4713 5.83147 13.7067 6.06686C13.9421 6.30225 14.3197 6.30225 14.5551 6.06686L18.1644 2.45754V22.9953H18.1546Z" fill="#49589B" />
    <path d="M10.2934 17.934C10.058 17.6986 9.68042 17.6986 9.44503 17.934L5.83572 21.5433V1.00553C5.83572 0.672062 5.566 0.402344 5.23253 0.402344C4.89906 0.402344 4.62934 0.672062 4.62934 1.00553V21.5433L1.02493 17.934C0.789538 17.6986 0.411933 17.6986 0.176543 17.934C-0.0588476 18.1694 -0.0588476 18.547 0.176543 18.7824L4.81079 23.4166C4.92848 23.5343 5.08051 23.5932 5.23743 23.5932C5.39436 23.5932 5.54638 23.5343 5.66408 23.4166L10.2983 18.7824C10.5239 18.5519 10.5239 18.1694 10.2934 17.934Z" fill="#49589B" />
  </svg>
);


export const SearchButtonSvg = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.6083 24.0596L24.6084 24.0598C24.8769 24.3395 24.8686 24.7844 24.5887 25.0537L24.5884 25.054C24.4524 25.1844 24.2769 25.25 24.1014 25.25C23.9177 25.25 23.7328 25.1785 23.5944 25.0342L24.6083 24.0596ZM24.6083 24.0596L18.0429 17.2318C19.7044 15.4806 20.7264 13.1153 20.7264 10.5155C20.7264 5.13033 16.3461 0.75 10.9609 0.75C5.57564 0.75 1.19531 5.13033 1.19531 10.5155C1.19531 15.9007 5.57564 20.2811 10.9609 20.2811C13.2413 20.2811 15.3411 19.4944 17.0043 18.1803L23.594 25.0337L24.6083 24.0596ZM10.9609 18.8748C6.35157 18.8748 2.60155 15.1248 2.60155 10.5155C2.60155 5.90626 6.35157 2.15624 10.9609 2.15624C15.5701 2.15624 19.3201 5.90626 19.3201 10.5155C19.3201 15.1248 15.5697 18.8748 10.9609 18.8748Z" fill="#49589B" stroke="#49589B" strokeWidth="0.5" />
  </svg>
);

export const CalenderButtonSvg = () => (
  <svg width="20" height="19" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.7099 1.93103C20.4854 0.830828 19.5107 0 18.3448 0C18.0779 0 17.8621 0.215793 17.8621 0.482759C17.8621 0.749724 18.0779 0.965517 18.3448 0.965517C19.1433 0.965517 19.7931 1.61531 19.7931 2.41379C19.7931 3.21228 19.1433 3.86207 18.3448 3.86207C18.0779 3.86207 17.8621 4.07786 17.8621 4.34483C17.8621 4.61179 18.0779 4.82759 18.3448 4.82759C19.5102 4.82759 20.4854 3.99676 20.7099 2.89655H27.0345V7.24138H0.965517V2.89655H7.72414H9.17241C9.43938 2.89655 9.65517 2.68076 9.65517 2.41379C9.65517 2.14683 9.43938 1.93103 9.17241 1.93103H8.29572C8.49559 1.37007 9.02662 0.965517 9.65517 0.965517C10.4537 0.965517 11.1034 1.61531 11.1034 2.41379C11.1034 3.21228 10.4537 3.86207 9.65517 3.86207C9.38821 3.86207 9.17241 4.07786 9.17241 4.34483C9.17241 4.61179 9.38821 4.82759 9.65517 4.82759C10.9861 4.82759 12.069 3.74476 12.069 2.41379C12.069 1.08283 10.9861 0 9.65517 0C8.48979 0 7.51462 0.830828 7.29014 1.93103H0V8.2069V27.5172H28V8.2069V1.93103H20.7099ZM27.0345 26.5517H0.965517V8.2069H27.0345V26.5517Z" fill="#49589B" />
    <path d="M12.5523 0.965517C13.3508 0.965517 14.0006 1.61531 14.0006 2.41379C14.0006 3.21228 13.3508 3.86207 12.5523 3.86207C12.2854 3.86207 12.0696 4.07786 12.0696 4.34483C12.0696 4.61179 12.2854 4.82759 12.5523 4.82759C13.8833 4.82759 14.9661 3.74476 14.9661 2.41379C14.9661 1.08283 13.8833 0 12.5523 0C12.2854 0 12.0696 0.215793 12.0696 0.482759C12.0696 0.749724 12.2854 0.965517 12.5523 0.965517Z" fill="#49589B" />
    <path d="M15.4481 0.965517C16.2466 0.965517 16.8964 1.61531 16.8964 2.41379C16.8964 3.21228 16.2466 3.86207 15.4481 3.86207C15.1811 3.86207 14.9653 4.07786 14.9653 4.34483C14.9653 4.61179 15.1811 4.82759 15.4481 4.82759C16.7791 4.82759 17.8619 3.74476 17.8619 2.41379C17.8619 1.08283 16.7791 0 15.4481 0C15.1811 0 14.9653 0.215793 14.9653 0.482759C14.9653 0.749724 15.1811 0.965517 15.4481 0.965517Z" fill="#49589B" />
    <path d="M10.6205 12.069C10.8871 12.069 11.1032 11.8529 11.1032 11.5863C11.1032 11.3197 10.8871 11.1035 10.6205 11.1035C10.3538 11.1035 10.1377 11.3197 10.1377 11.5863C10.1377 11.8529 10.3538 12.069 10.6205 12.069Z" fill="#49589B" />
    <path d="M14.0001 12.069C14.2667 12.069 14.4829 11.8529 14.4829 11.5863C14.4829 11.3197 14.2667 11.1035 14.0001 11.1035C13.7335 11.1035 13.5173 11.3197 13.5173 11.5863C13.5173 11.8529 13.7335 12.069 14.0001 12.069Z" fill="#49589B" />
    <path d="M17.3797 12.069C17.6464 12.069 17.8625 11.8529 17.8625 11.5863C17.8625 11.3197 17.6464 11.1035 17.3797 11.1035C17.1131 11.1035 16.897 11.3197 16.897 11.5863C16.897 11.8529 17.1131 12.069 17.3797 12.069Z" fill="#49589B" />
    <path d="M20.7582 12.069C21.0248 12.069 21.2409 11.8529 21.2409 11.5863C21.2409 11.3197 21.0248 11.1035 20.7582 11.1035C20.4915 11.1035 20.2754 11.3197 20.2754 11.5863C20.2754 11.8529 20.4915 12.069 20.7582 12.069Z" fill="#49589B" />
    <path d="M24.1378 12.069C24.4044 12.069 24.6205 11.8529 24.6205 11.5863C24.6205 11.3197 24.4044 11.1035 24.1378 11.1035C23.8712 11.1035 23.655 11.3197 23.655 11.5863C23.655 11.8529 23.8712 12.069 24.1378 12.069Z" fill="#49589B" />
    <path d="M3.8624 15.9313C4.12902 15.9313 4.34516 15.7152 4.34516 15.4486C4.34516 15.182 4.12902 14.9658 3.8624 14.9658C3.59578 14.9658 3.37964 15.182 3.37964 15.4486C3.37964 15.7152 3.59578 15.9313 3.8624 15.9313Z" fill="#49589B" />
    <path d="M7.24082 15.9313C7.50744 15.9313 7.72357 15.7152 7.72357 15.4486C7.72357 15.182 7.50744 14.9658 7.24082 14.9658C6.9742 14.9658 6.75806 15.182 6.75806 15.4486C6.75806 15.7152 6.9742 15.9313 7.24082 15.9313Z" fill="#49589B" />
    <path d="M10.6205 15.9313C10.8871 15.9313 11.1032 15.7152 11.1032 15.4486C11.1032 15.182 10.8871 14.9658 10.6205 14.9658C10.3538 14.9658 10.1377 15.182 10.1377 15.4486C10.1377 15.7152 10.3538 15.9313 10.6205 15.9313Z" fill="#49589B" />
    <path d="M14.0001 15.9313C14.2667 15.9313 14.4829 15.7152 14.4829 15.4486C14.4829 15.182 14.2667 14.9658 14.0001 14.9658C13.7335 14.9658 13.5173 15.182 13.5173 15.4486C13.5173 15.7152 13.7335 15.9313 14.0001 15.9313Z" fill="#49589B" />
    <path d="M17.3797 15.9313C17.6464 15.9313 17.8625 15.7152 17.8625 15.4486C17.8625 15.182 17.6464 14.9658 17.3797 14.9658C17.1131 14.9658 16.897 15.182 16.897 15.4486C16.897 15.7152 17.1131 15.9313 17.3797 15.9313Z" fill="#49589B" />
    <path d="M20.7582 15.9313C21.0248 15.9313 21.2409 15.7152 21.2409 15.4486C21.2409 15.182 21.0248 14.9658 20.7582 14.9658C20.4915 14.9658 20.2754 15.182 20.2754 15.4486C20.2754 15.7152 20.4915 15.9313 20.7582 15.9313Z" fill="#49589B" />
    <path d="M24.1378 15.9313C24.4044 15.9313 24.6205 15.7152 24.6205 15.4486C24.6205 15.182 24.4044 14.9658 24.1378 14.9658C23.8712 14.9658 23.655 15.182 23.655 15.4486C23.655 15.7152 23.8712 15.9313 24.1378 15.9313Z" fill="#49589B" />
    <path d="M3.8624 19.3102C4.12902 19.3102 4.34516 19.0941 4.34516 18.8275C4.34516 18.5609 4.12902 18.3447 3.8624 18.3447C3.59578 18.3447 3.37964 18.5609 3.37964 18.8275C3.37964 19.0941 3.59578 19.3102 3.8624 19.3102Z" fill="#49589B" />
    <path d="M7.24082 19.3102C7.50744 19.3102 7.72357 19.0941 7.72357 18.8275C7.72357 18.5609 7.50744 18.3447 7.24082 18.3447C6.9742 18.3447 6.75806 18.5609 6.75806 18.8275C6.75806 19.0941 6.9742 19.3102 7.24082 19.3102Z" fill="#49589B" />
    <path d="M10.6205 19.3102C10.8871 19.3102 11.1032 19.0941 11.1032 18.8275C11.1032 18.5609 10.8871 18.3447 10.6205 18.3447C10.3538 18.3447 10.1377 18.5609 10.1377 18.8275C10.1377 19.0941 10.3538 19.3102 10.6205 19.3102Z" fill="#49589B" />
    <path d="M14.0001 19.3102C14.2667 19.3102 14.4829 19.0941 14.4829 18.8275C14.4829 18.5609 14.2667 18.3447 14.0001 18.3447C13.7335 18.3447 13.5173 18.5609 13.5173 18.8275C13.5173 19.0941 13.7335 19.3102 14.0001 19.3102Z" fill="#49589B" />
    <path d="M17.3797 19.3102C17.6464 19.3102 17.8625 19.0941 17.8625 18.8275C17.8625 18.5609 17.6464 18.3447 17.3797 18.3447C17.1131 18.3447 16.897 18.5609 16.897 18.8275C16.897 19.0941 17.1131 19.3102 17.3797 19.3102Z" fill="#49589B" />
    <path d="M20.7582 19.3102C21.0248 19.3102 21.2409 19.0941 21.2409 18.8275C21.2409 18.5609 21.0248 18.3447 20.7582 18.3447C20.4915 18.3447 20.2754 18.5609 20.2754 18.8275C20.2754 19.0941 20.4915 19.3102 20.7582 19.3102Z" fill="#49589B" />
    <path d="M24.1378 19.3102C24.4044 19.3102 24.6205 19.0941 24.6205 18.8275C24.6205 18.5609 24.4044 18.3447 24.1378 18.3447C23.8712 18.3447 23.655 18.5609 23.655 18.8275C23.655 19.0941 23.8712 19.3102 24.1378 19.3102Z" fill="#49589B" />
    <path d="M3.8624 23.1725C4.12902 23.1725 4.34516 22.9564 4.34516 22.6898C4.34516 22.4232 4.12902 22.207 3.8624 22.207C3.59578 22.207 3.37964 22.4232 3.37964 22.6898C3.37964 22.9564 3.59578 23.1725 3.8624 23.1725Z" fill="#49589B" />
    <path d="M7.24082 23.1725C7.50744 23.1725 7.72357 22.9564 7.72357 22.6898C7.72357 22.4232 7.50744 22.207 7.24082 22.207C6.9742 22.207 6.75806 22.4232 6.75806 22.6898C6.75806 22.9564 6.9742 23.1725 7.24082 23.1725Z" fill="#49589B" />
    <path d="M10.6205 23.1725C10.8871 23.1725 11.1032 22.9564 11.1032 22.6898C11.1032 22.4232 10.8871 22.207 10.6205 22.207C10.3538 22.207 10.1377 22.4232 10.1377 22.6898C10.1377 22.9564 10.3538 23.1725 10.6205 23.1725Z" fill="#49589B" />
    <path d="M14.0001 23.1725C14.2667 23.1725 14.4829 22.9564 14.4829 22.6898C14.4829 22.4232 14.2667 22.207 14.0001 22.207C13.7335 22.207 13.5173 22.4232 13.5173 22.6898C13.5173 22.9564 13.7335 23.1725 14.0001 23.1725Z" fill="#49589B" />
    <path d="M17.3797 23.1725C17.6464 23.1725 17.8625 22.9564 17.8625 22.6898C17.8625 22.4232 17.6464 22.207 17.3797 22.207C17.1131 22.207 16.897 22.4232 16.897 22.6898C16.897 22.9564 17.1131 23.1725 17.3797 23.1725Z" fill="#49589B" />
  </svg>
);

export const NameArrowSvg = () => (
  <svg width="18" height="18" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.55293 2.78613H3.44678V4.21519H7.55293V2.78613Z" fill="#49589B" />
    <path d="M0.67925 2.38376V2.52892C0.625513 2.49608 0.566618 2.47361 0.500039 2.47361C0.302776 2.47361 0.14325 2.63339 0.14325 2.83034V4.17018C0.14325 4.36713 0.302776 4.52692 0.500039 4.52692C0.566618 4.52692 0.625513 4.50445 0.67925 4.47161V4.61676C0.67925 4.98071 0.952197 5.2776 1.30409 5.32182V1.67871C0.952197 1.72292 0.67925 2.01982 0.67925 2.38376Z" fill="#49589B" />
    <path d="M10.5001 2.47361C10.4335 2.47361 10.3746 2.49608 10.3209 2.52892V2.38376C10.3209 2.01982 10.0479 1.72292 9.69611 1.67871V5.32192C10.048 5.27766 10.3209 4.98082 10.3209 4.61687V4.47171C10.3747 4.50455 10.4336 4.52703 10.5001 4.52703C10.6974 4.52703 10.8569 4.36724 10.8569 4.17029V2.83045C10.8569 2.6334 10.6974 2.47361 10.5001 2.47361Z" fill="#49589B" />
    <path d="M2.37513 0.554688C1.98108 0.554688 1.66113 0.874635 1.66113 1.26916V5.73142C1.66113 6.12595 1.98113 6.4459 2.37513 6.4459C2.76966 6.4459 3.08966 6.12595 3.08966 5.73142V1.26916C3.08966 0.874635 2.76966 0.554688 2.37513 0.554688Z" fill="#49589B" />
    <path d="M8.62493 0.554688C8.2304 0.554688 7.9104 0.874635 7.9104 1.26916V5.73142C7.9104 6.12595 8.2304 6.4459 8.62493 6.4459C9.01898 6.4459 9.33893 6.12595 9.33893 5.73142V1.26916C9.33893 0.874635 9.01898 0.554688 8.62493 0.554688Z" fill="#49589B" />
  </svg>
);
