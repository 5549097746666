import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import env from '../../utils/env_variables';
import GoogleLoginButton from 'react-google-login';
import { BtnGoogle } from './assets/style';
import googleIcon from './assets/googlePlus.svg';
import { pushNotification } from '../../utils/notifications';
import Messages from '../../constants/messages';
import { setInLocalStorage } from '../../utils/helpers';
const GoogleLogin = (props) => {
  const { googleLoginRequest, updateReducerState, getUserDetails, initializeUser, toggalAuthModal,
    getClassTimeTableList } = props;

  const handleGoogleLoginSuccess = (googleResponse) => {
    if (googleResponse && googleResponse.accessToken && googleResponse.tokenId) {
      googleLoginRequest({ access_token: googleResponse.accessToken }, googleResponse.tokenId)
        .then(response => {
          console.log('response google', response);
          if (response && response.action && response.action.payload && response.action.payload.data
            && response.action.payload.data.status && response.action.payload.data.status === 'SUCCESS'
            && response.action.payload.headers && response.action.payload.headers.authorization) {
            setInLocalStorage('token', response.action.payload.headers.authorization);
            updateReducerState('auth', 'isLogin', true);
            pushNotification(Messages.LOGIN_SUCCESS, 'success', 'TOP_CENTER', 1000);
            updateReducerState('header', 'toggalAuthModal', !toggalAuthModal);
            getUserDetails()
              .then(response => {
                if (response && response.value && response.value.data && response.value.data.payload &&
                  response.value.data.payload.gymCredentials && response.value.data.payload.gymCredentials.length) {
                  initializeUser();
                }
              })
              .catch(error => {
                if (error && error.response && error.response.status &&
                  error.response.status === 401 && props && props.history && props.history.location &&
                  props.history.location.pathname === '/') {
                  updateReducerState('auth', 'isLogin', false);
                }
              });
            if (props && props.history && props.history.location &&
              props.history.location.pathname === '/time-table') {
              getClassTimeTableList('Bank');
            }
          }
        });
    }
  };

  const handleGoogleLoginFailure = () => {
    console.log('error');
  };

  return (
    <GoogleLoginButton
      buttonText={
        <Fragment>
          <BtnGoogle className="google-btn" variant="contained" color="secondary"><img src={googleIcon} alt="googleIcon" />Google</BtnGoogle>
        </Fragment>
      }
      clientId={env.GOOGLE_CLIENT_ID}
      onSuccess={handleGoogleLoginSuccess}
      onFailure={handleGoogleLoginFailure}
      cookiePolicy={'single_host_origin'}
    // className={`ui google plus ${sinUpClass}   ${this.props.buttonSize || 'big'} fluid button`}
    />
  );
};

GoogleLogin.propTypes = {
  googleLoginRequest: PropTypes.func,
  updateReducerState: PropTypes.func,
  getUserDetails: PropTypes.func,
  initializeUser: PropTypes.func,
  getClassTimeTableList: PropTypes.func,
  toggalAuthModal: PropTypes.bool,
  history: PropTypes.object
};

export default GoogleLogin;
