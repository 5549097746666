const Messages = {
  // 401 unauthhorized
  SESSION_EXPIRE: 'Session expire. Please login again!!',

  // Login case
  LOGIN_SUCCESS: 'Login success',
  LOGIN_FAILED: 'Login failed',

  // Register case
  REGISTER_SUCCESS: 'Registartion success',
  REGISTER_FAILED: 'Registartion failure',

  // Forgot password case
  FORGOT_PASSWORD_SUCCESS: 'If this email id is registered with us, a reset password link will be sent',
  FORGOT_PASSWORD_FAILED: 'Failed to initiate forgot password process. Please try again!!',

  // Reset password case
  RESET_PASSWORD_FAILED: 'Failed to reset password. Please try again!!',
  RESET_PASSWORD_SUCCESS: 'Password Updated successfully!!',
  // Logout case
  LOGOUT_SUCCESS: 'Logout success!!',

  BOOK_CLASS_FAILED: 'Booking class failed',
  BOOK_CLASS_SUCCESS: 'Book class successfully',
  CLASS_CANCEL_SUCCESS: 'Class cancel successfully',
  CLASS_BOOK: 'This class is not recurring, Plese cancel class using "Cancel this class"',
  PREBOOK_CLASS: 'This class is recurring, Plese cancel class using "Cancel Recurring"',
  FACEBOOK_LOFIN_FAILED: 'Login Failed. Please try again!',
  GOOGLE_LOGIN_FAILED: 'Login Failed. Please try again!',
  TIME_TABLE_DATA_NOT_AVAILABLE: 'There is no class for this date',
  EMAIL_VERIFY_SUCCESS: 'Email verified. Please login to continue.',

  PASSWORD_CRITERIA: 'Password must contain the following:',
  ATLEAST_ONE_LOWERCASE: 'At least one lowercase letter',
  ATLEAST_ONE_CAPITAL: 'At least one capital (uppercase) letter',
  ATLEAST_ONE_NUMBER: 'At least one number',
  MINIMUM_8_CHARS: 'Minimum 8 characters'
};

export default Messages;
