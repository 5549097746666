import styled from 'styled-components';
import {
    Typography,
    Button,
    Table,
    Avatar,
    Drawer,
    Dialog,
    FormControl,
    IconButton
} from '@material-ui/core';

export const BtnModalClose = styled(IconButton)
    `
    position: absolute !important;
    right: 20px;
    top: 15px;
    
`;

export const ModalBTN = styled(Button)
    `
    background: ${({ theme }: any) => theme.palette.primary.main} !important;    
    border: 0;
    min-width: 130px;
    color: #ffffff !important;
    text-transform: capitalize !important;    
    padding: 7px 14px !important;   
    
`;

export const SearchComponent = styled.div
    `  
    
    input{
        transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        width: 1px;
        box-sizing: content-box;
        animation-name: mui-auto-fill-cancel;
        -webkit-tap-highlight-color: transparent;
        border-bottom: 1px solid #ffffff;    
    }
    &.searchInputhidden{
        input{
            width: 1px;
        }        
    } 
    &.searchInputShow{
        
        input{
            width: auto;
            border-bottom: 1px solid #F3F3F3;    
        }
    }
    @media (max-width: 768px) {
        
     
        .MuiInputBase-root {
            width:90%;
            input {
                width:100%;
            }
        }
    }
    @media (max-width: 414px) {
        .MuiInputBase-root {
            width: 78%;
        }
    }
`;

export const LoadingWrap = styled.div
    `  
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
`;



export const MdSelectBTN = styled(FormControl)
    `  
    width:100%;
    .MuiSelect-root{
        background: #EEEEEE;
        padding: 10px 14px !important;        
        padding-right: 24px !important;
        min-width: 130px;
        font-size: 15px;
    }
    .MuiInput-underline:after, .MuiInput-underline:before{
      content: none !important;
    }
 
    
`;
export const AvatarUser = styled(Avatar)
    `
    width: 34px !important;
    height: 34px !important;
    margin-left: -10px !important;
`;
export const DialogTheme = styled(Dialog)
    `
    width: 34px !important;
    height: 34px !important;
    margin-left: -10px !important;
`;
export const FilterModal = styled(Drawer)
    `
    .FilterWrapUI {        
        overflow-y: auto;
        padding-top: 70px;
        padding-bottom: 70px;
        box-sizing: border-box;
    }
`;

export const ThemeTable = styled(Table)
    `
    td {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #313b67;
        border-bottom: 2px solid #f3f3f3;
    }
`;

export const TimeCol = styled.div`
    span {
        margin-left: 4px;
        color: ${({ theme }: any) => theme.palette.secondary.main};
    }
`;

export const TableCloDis = styled.div`
    strong {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: ${({ theme }: any) => theme.palette.secondary.main};
        text-transform: uppercase;
    }
    .small-star {
        transform: scale(0.8) translate(-7px, 6px);
    }
    .TableCloDis_name {
        font-style: italic;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #a3a8c2;
        margin-top: 3px;
        svg {
            margin-right: 6px;
            path {
                fill:#49589B !important;
            }
        }
    }
    .TableCloDis_content {
        font-family: Fira Sans;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        margin-top: 8px;
        color: ${({ theme }: any) => theme.palette.secondary.main};
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width:300px;
        position: relative;
        padding-right: 64px;
        span{
            position: absolute;
            right: 0;
            color: ${({ theme }: any) => theme.palette.secondary.main};
            cursor: pointer;
            &:hover{
                color: ${({ theme }: any) => theme.palette.primary.main};                
                text-shadow: 0.2px 0.2px 1px ${({ theme }: any) => theme.palette.primary.main};
            }
        }
    }
`;

export const SliderUI = styled.div`
    padding: 0 45px;
    @media (max-width: 414px) {
        padding: 0 75px;
    }
    .slick-prev,
    .slick-next {
        /* background: #ffe9e3; */
        border: 1px solid #DDDDDD;
        width: 42px;
        height: 35px;
        border-radius: 5px;
        right: -40px;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        &:before,
        &:before {
            opacity: 1;
            width: 0;
            height: 0;
            border-left: 12px solid #49589B;
            border-bottom: 6px solid transparent;
            border-top: 6px solid transparent;
            font-size: 0;
        }
        &:hover{
            box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
        }
    }
    .slick-prev {
        transform: translate(0, -50%) rotate(180deg);
        left: -40px;
    }
`;


export const SliderUIModal = styled.div`
    padding: 0 45px;
    max-width: 312px;
    box-sizing: border-box;
    margin-bottom: 18px;
    .slick-prev,
    .slick-next {
        background: #fff;
        width: 26px;
        height: 25px;
        border-radius: 5px;
        border: 1px solid #DDDDDD;
        right: -40px;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        &:before,
        &:before {
            opacity: 1;
            width: 0;
            height: 0;
            border-left: 10px solid #313B67;
            border-bottom: 6px solid transparent;
            border-top: 6px solid transparent;
            font-size: 0;
        }
        &:hover{
            box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
        }
    }
    .slick-prev {
        transform: translate(0, -50%) rotate(180deg);
        left: -40px;
    }
    
`;


export const SliderWrap = styled.div`
    color: ${({ theme }: any) => theme.palette.secondary.main};
    text-align: center;
    position:relative;
    padding-bottom: 9px;
    cursor:pointer;
    &:focus{
        outline:none;
    }
    &:after{
        content: "";
        border-radius: 5px;        
        height: 2px;
        display: block;
        max-width: 55%;
        left: 0;
        right: 0;
        margin: 0 auto;
        position: absolute;
        bottom: 0px;
    }
    &.sliderActive{
        color: ${({ theme }: any) => theme.palette.primary.main};
        :after{
            background: ${({ theme }: any) => theme.palette.primary.main};
        }
        span{
            color: ${({ theme }: any) => theme.palette.primary.main};
        }

    }
    strong {
        display: block;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 2px;
    }
    span {
        color: ${({ theme }: any) => theme.text.disabled};
        font-size: 14px;
        font-weight: 100;
    }
`;
export const SliderWrapModal = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: normal;
    color: #313B67;    
    text-align: center;
    position: relative;
    top:10px;
`;

export const SiteTitle = styled(Typography)
    `
    color: #434343;
    font-weight: 500;
    font-size: 28px;
`;

export const FilterBTN = styled(Button)
    `
    background: ${({ theme }: any) => theme.palette.secondary.main}!important;    
    border: 0;
    width: 93px;
    color: #ffffff !important;
    text-transform: capitalize !important;
    font-weight: 300 !important;
    padding: 4px 8px !important;
&:hover {
    box-shadow: none !important;
}
    svg {
        margin-left: 8px;
    }   
 
`;

export const SortByBTN = styled(Button)
    `
    background: #ffffff !important;
    border: 0;
    color: #a1a1a1 !important;
    text-transform: capitalize !important;
    font-weight: 300 !important;
    padding: 0px 8px !important;
    margin-left: 22px !important;
    svg {
        margin-right: 8px;
    }
`;
export const CalenderBTN = styled(Button)
    `
    background: #ffffff !important;
    max-width: 42px !important;
    min-width: 42px !important;
    height: 36px;
    padding: 0px !important;
    border: 1px solid #dddddd !important;
    box-sizing: border-box;    
    .hidden-calender{
        position:absolute;
        visibility: hidden;
    }
`;

export const CalenderBTNModule = styled.div
    `
    background: #ffffff !important;
    max-width: 42px !important;
    min-width: 24px !important;
    height: 36px;
    padding: 0px !important;    
    box-sizing: border-box;   
    margin: 11px auto; 
    margin-right: 0;
    .hidden-calender{
        position:absolute;
        visibility: hidden;
    }
`;




export const TableBTN = styled(Button)
    `
    background: #dbefde !important;
    min-width: 140px !important;
    height: 34px;
    padding: 0px !important;
    border: 0 !important;
    box-sizing: border-box;    
`;

export const CustomModalSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  label {
    font-size: 14px !important; 
    font-weight: 400 !important;
    color:#A1A1A1;
  }
  .MuiFormControl-root {
    .customDatePicker {
      position: relative;
        button {
            margin-top: 15px;
        }
      .MuiInput-formControl {
        margin-top: 0;
      }
      .openCalender {
        background: transparent;
        border: 0;
        position: absolute;
        right: 5px;
        top: 0px;
        width: 40px;
        height: 35px;
      }
      label {
        display: none;
      }
      .MuiFormLabel-root {
        width: auto;
        margin-top: 0;
      }
    }
  }
  .multiselect-custom {
    .MuiSelect-root {
      min-width: 100px;
      border-radius: 5px;
    }
  }
  .label-box {
      margin-top:3px;
  }
  .repeatEvery {
    width:41px;
    margin-right: 14px;
    input {
        height: 40px;
        border: 1px solid #EEEEEE;
        width: 41px;
        border-radius: 5px;
        text-indent: 16px;
        background: #EEEEEE;
        padding: 0;
        font-size: 15px;
    }
  }
  .MuiFormLabel-filled {
    font-size: 14px;
    font-weight: 700;
  }
`;

export const DialogBoxCustom = styled.div`
    width:100%;
    
  .MuiInputLabel-outlined {
    transform: translate(5px, -21px) scale(1);
  }
`;

export const CustomBtnGroup = styled.div`
margin-top: 20px;
    .MuiInputBase-root {
    margin-right: -15px;
    }
    .MuiSelect-root {
    background: #EEEEEE;
    padding: 10px 14px !important;
    padding-right: 24px !important;
    min-width: 130px;
    font-size: 15px;
    }
    .MuiInputBase-root {
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: inline-flex;
    position: relative;
    font-size: 1.1428571428571428rem;
    box-sizing: border-box;
    align-items: center;
    font-family: Fira Sans,sans-serif;
    line-height: 1.1875em;
    border-radius:5px;
    
    }
    .MuiTouchRipple-root {
        background:#49589B;
        
    }
    .MuiButton-label {
        color:#fff;
        position: relative;
        z-index: 2;
    }
    .MuiInput-underline:before {
    content: none;
    }
    .MuiButton-textPrimary {
    margin-left: 26px;
    width: 120px;
    }
    .MuiCircularProgress-root {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: auto;
        height: 54px;
        overflow-y: auto;
    }
`;

export const DetailObject = styled.div`
    margin: 4px 0;
    strong {
        font-weight: 500;
        font-size: 18px;
        color: #313B67;
        text-transform:uppercase;
    }
`;

export const DatePickerBox = styled.div`
    background: #fff;
    position: absolute;
    top: -160px;
    left: -20px;
    z-index: 2;
    border: 1px solid #ddd;
    border-radius: 5px;
`;



export const CustomRadio = styled.div`

`;

export const FilterPopper = styled.div`
    position: absolute;
    z-index: 10;
    left: -1px;
    top: 58px;
    border-top: none;
    @media (max-width: 767px) {
        width: 100%;
    }
    @media (max-width: 414px) {
        width: 100%;
    }
    @media (max-width: 375px) {
        width: 92%;
    }
    strong {
        display: block;
        padding-left: 5px;
        font-size: 12px;
        color: #313B67;
        margin-bottom: 6px;
    }
    button {
        font-size: 14px;
        color: #A1A1A1;
        font-weight: normal;
        border: 1px solid #E0E0E0;
        margin-right: 10px;
        margin-bottom: 10px;
        &.selected {
            background: rgba(73, 88, 155, 0.1);
            color: #49589B;
            border: 1px solid #49589B;
        }
    }
    .bg-wrapper {
        background: #fff;
        box-shadow: 0px 12px 14px rgba(0,0,0,0.21);
        border: 1px solid #ddd;
        border-radius: 0 0 5px 5px;
        padding: 20px;
        width: 94.9%;
        border-top: 0;
       
        @media (max-width: 768px) {
            width: 94.3%;
        }
        @media (max-width: 630px) {
            width: 93.2%;
        }
        @media (max-width: 414px) {
            width: 90%;
        }
    }
    .btnSearch {
        background: #49589B !important;
        float:right;
        width:101px;
        border-radius: 5px;
        .MuiButton-label {
            color:#fff !important;
        }
        @media (max-width: 414px) { 
            float:none;
        }
    }
`;

export const TagWrap = styled.div`
    position: absolute;
    top: 3px;
    right: 2px;
    width: 87%;
    height: 54px;
    overflow-y: auto;
    @media (max-width: 1024px) {
        position: relative;
    }
    .css-1okebmr-indicatorSeparator {
        background-color: transparent !important;
    }
    .css-yk16xz-control {
        border:none;
    }
    .css-1hb7zxy-IndicatorsContainer {
        display:none;
    }
    .css-1rhbuit-multiValue {
        background: rgba(244, 189, 172, 0.2);
        border-radius: 5px;    
        color: #49589B;
    }
    .css-12jo7m5 {
        color: #49589B;
        font-size: 12px;
        font-family: Fira Sans;
        font-weight: normal;
    }
    .css-1hwfws3 {
        height:50px;
        overflow-y:auto;
    }
    .tagSelect, .tagSelect * {
        outline: none;
    }
 
`;

export const FilterWrapBox = styled.div`
    position: relative;
    padding: 4px 5px;
    border-radius: 5px 5px 0 0;
    height: 50px;
    display: flex;
    align-items: center;
    z-index: 4;
&.active {
    background: #fff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.21); 
    .tag-btn{
        background: #ffffff !important;
        .MuiButton-label {
            color: #49589B;
            svg {
                path {
                    fill: #49589B;
                }
            }
        }
    }  
    .overlay {
        display:block
    }
}

`;
export const BackdropWrapper = styled.div`
    display:none;
    position: fixed;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background: transparent;
`;
