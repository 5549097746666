import api from '../../api';
import URLs from '../../constants/urls';
import { GET_GYMS_NAME_LIST, UPDATE_REDUCER_STATE } from './CommonConstants';

export const getGymsList = (gymBrand) => ({
  type: GET_GYMS_NAME_LIST,
  payload: api.get(`${URLs.GET_GYMS_NAME}${gymBrand}`)
});

export const updateReducerState = (reducerKey, key, value) => ({
  type: UPDATE_REDUCER_STATE,
  payload: { reducerKey, key, value }
});
