import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, CircularProgress, Typography } from '@material-ui/core';
import textContent from '../../../constants/staticText';
import { InputBox } from '../../../utils/FormUtils';
import Validator from '../../../utils/Validator';
import { AuthImgWrap, BtnWhite, FormWrap, InputController, BtnPrimary } from '../components/assets/style';
import { SeoHelmet } from '../../../utils/seoHelmet';

const ForgotPasswordForm = (props) => {

  // state manupulation
  const [forgotState, setForgotState] = useState({
    email: ''
  });
  const [error, setErrors] = useState({});

  // For forgot password form validation
  const _isValid = (field = null, value) => {
    if (field) {
      forgotState[field] = value;
    }
    const validate = Validator.createValidator(
      {
        email: ['required', 'email']
      },
      {
        email: forgotState.email
      },
      field,
      {
        email: ''
      }
    );

    return validate;
  };

  // Validations on blur
  const validateOnBlur = (name, value) => {
    const { errors } = _isValid(name, value);
    setErrors({ ...error, [name]: errors[name] });
  };

  const handleSubmitClick = () => {
    const { initiateForgotPassword, toggalAuthModal } = props;
    const { isValid } = _isValid();
    if (isValid) {
      initiateForgotPassword({ email: forgotState.email })
        .then(response => {
          if (response) {
            updateReducerState('header', 'toggalAuthModal', !toggalAuthModal);
          }
        });
    }
  };

  const onChangeHandler = (value, name) => {
    setForgotState({
      ...forgotState,
      [name]: value
    });
    validateOnBlur(name, value);
  };

  const { forgotPasswordLoading, updateReducerState } = props;
  return (
    <Grid container spacing={1} className="forgotModal">
      <SeoHelmet title="Forgot-password" />
      <Grid item md={5} xs={12} className="reorder">
        <AuthImgWrap>
          <Box className="rightWrap">
            <p className="noAccount">You already have an account</p>
            <BtnWhite onClick={() => updateReducerState('header', 'authModalType', 'login')}>Login</BtnWhite>
          </Box>
        </AuthImgWrap>
      </Grid>
      <Grid item md={7} xs={12}>
        <FormWrap>
          <Typography align="center" variant="h6" component="h2">Find your email</Typography>
          <p className="subhead">Enter your recovery email</p>
          <InputController>
            <InputBox
              errorMessage={error.email}
              label={textContent.EMAIL_FIELD_LABEL}
              placeholder={textContent.EMAIL_FIELD_PLACEHOLDER}
              type={'text'}
              name={'email'}
              onChange={onChangeHandler}
              value={forgotState.email}
              maxLength={30}
            />
          </InputController>
          <InputController>
            {
              forgotPasswordLoading ?
                <CircularProgress />
                :
                <BtnPrimary variant="contained" color="secondary" onClick={handleSubmitClick} >Submit</BtnPrimary>
            }
          </InputController>
        </FormWrap>
      </Grid>
    </Grid>
  );
};

ForgotPasswordForm.propTypes = {
  initiateForgotPassword: PropTypes.func,
  updateReducerState: PropTypes.func,
  forgotPasswordLoading: PropTypes.bool,
  toggalAuthModal: PropTypes.bool
};

export default ForgotPasswordForm;
