import styled from 'styled-components';


export const Privacysec = styled.div`
  text-align:center;
  padding-top:40px;
  max-width:915px;
  margin:0 auto;
  h1{
    font-size: 26px;
    line-height: 1;
    color: #49589b;
    margin: 0 0 20px;
    font-family: var(--primary-font)!important;
  }
  h2{
    color: #49589b;
    line-height: 1.5;
    font-size: 22px;
    font-weight: bolder;
    text-align:left;

  }
  h3{
    color: #4f4f4f;
    line-height: 1.5;
    font-size: 14px;
    font-weight: bolder;
    text-align:left;

  }
  .color-light{
        color: #bdbdbd;
        font-size: 14px;
  }
  h4{
    color:  #4f4f4f;
    font-size: 16px;
    font-weight: 600;
    text-align:left;
    &.plan-icon{
      position:relative ;
      padding-left: 27px;
      &:before{
          content: "";
          display: block;
          width: 18px;
          height: 12.35px;
          background-image: url(https://staging-beta220322.rewardflightfinder.com/static/media/flight.1aa9d9ca.svg);
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          left: 0;
          top: 5px;
        }
    }
  }
  .content{
    p{
      font-size:14px;
      line-height: 1.5;
      color: #4f4f4f;
      margin-bottom: 15px;
      text-align:left;
    }
    ul{
      padding-left:0;
      li{
        position: relative;
        list-style: none;
        font-size: 14px;
        line-height: 1.5;
        color: #4f4f4f;
        margin-bottom: 15px;
        text-align: left;
        padding-left: 30px;
        &:before{
          content: "";
          display: block;
          width: 18px;
          height: 12.35px;
          background-image: url(https://staging-beta220322.rewardflightfinder.com/static/media/flight.1aa9d9ca.svg);
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          left: 0;
          top: 8px;
        }
      }
    }
  }
`;
