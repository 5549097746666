import styled from 'styled-components';

export const DrawerSidebar = styled.div`
    min-width:250px;
    padding-top:35px;
    .MuiListItemText-root{
        padding-left: 0;
        padding-right: 0;
    }
    .MuiTypography-body1{
        font-size: 17px;
        font-weight: 400;
        color: ${({ theme }) => theme.palette.secondary.main};
        padding-left:30px;
    }
    .MuiListItem-gutters {
        padding-left: 0;
        padding-right: 0;
    }
`;

