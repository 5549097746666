import React, { useState, Fragment } from 'react';
import {
  Dialog, DialogContentText, DialogActions, DialogContent,
  DialogTitle, Backdrop, Select, MenuItem, Tooltip
} from '@material-ui/core';
import { gymScheduleDropDown } from '../../../utils/globalConstants';
import PropTypes from 'prop-types';
import { MdSelectBTN, ModalBTN, AvatarUser, BtnModalClose } from '../assets/style.js';
import { NameArrowSvg } from '../../../utils/svgs';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import CloseIcon from '@material-ui/icons/Close';
import Image1 from '../assets/01.png';
import Image2 from '../assets/02.png';
import Image3 from '../assets/03.png';
import CustomBookForm from '../../ClassTimeTable/components/customBookForm';
import { DateUtils } from 'react-day-picker';

const Modal = (props) => {
  const { toggalModal, handlerSetToggleDetails, selectedGymDetails } = props;
  const [modalDetails, setmodalDetails] = useState({
    gymScheduleValue: 'notRepeat',
    toggalCalender: false
  });

  const [customFormValue, setCustomFormValue] = useState({
    timePeriod: 'weeks',
    onOrNever: 'on',
    counts: 1,
    selectedDays: []
  });

  const getDataAccordingSelectedButton = (selectedGymDetails) => {
    if (selectedGymDetails.buttonType === 'bookRecurring') {
      return (
        <Fragment>
          <MdSelectBTN classes={{ root: 'custom-select' }}>
            <Select
              value={modalDetails.gymScheduleValue}
              onChange={e => setmodalDetails({ ...modalDetails, gymScheduleValue: e.target.value })}
            >
              {gymScheduleDropDown.map((data) => (<MenuItem value={data.value} key={data.value}>{data.title}</MenuItem>))}
            </Select>
          </MdSelectBTN>
          <ModalBTN color="primary">
            {selectedGymDetails.status}
          </ModalBTN>
        </Fragment>
      );
    }
  };


  const handleCustomValues = (name, value) => {
    if (name === 'counts' && value < 1) {
      return;
    }
    setCustomFormValue({ ...customFormValue, [name]: value });
  };

  const handleDateChange = (day, { selected }) => {
    const { selectedDays } = customFormValue;
    if (selected) {
      const selectedIndex = selectedDays.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }
    setCustomFormValue({ ...customFormValue, selectedDays });
  };

  const handlerToggalCalender = () => {
    setmodalDetails({
      ...modalDetails,
      toggalCalender: !modalDetails.toggalCalender
    });
  };

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      classes={{ root: 'theme-modal' }}
      open={toggalModal && selectedGymDetails}
      onClose={() => handlerSetToggleDetails('toggalModal')}
      closeAfterTransition
      BackdropComponent={Backdrop}
      maxWidth="md"
      BackdropProps={{
        timeout: 500
      }}
    >{selectedGymDetails ?
      <Fragment>
        <DialogTitle classes={{ root: 'modal-title' }} onClose={() => handlerSetToggleDetails('toggalModal')} disableTypography>
          Bank, Michigan
          <BtnModalClose aria-label="close" onClick={() => handlerSetToggleDetails('toggalModal')} >
            <CloseIcon />
          </BtnModalClose>
        </DialogTitle>
        <DialogContent >
          <div className="TableCloDis_name">
            <NameArrowSvg />
            {selectedGymDetails.instructorName}
          </div>
          <AvatarGroup>
            <AvatarUser small alt="Remy Sharp" src={Image1} />
            <AvatarUser small alt="Remy Sharp" src={Image2} />
            <AvatarUser small alt="Remy Sharp" src={Image3} />
            <Tooltip title="Foo • Bar • Baz">
              <AvatarUser>+3</AvatarUser>
            </Tooltip>
          </AvatarGroup>
          <strong>{selectedGymDetails.name}</strong>

          <DialogContentText gutterBottom>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
            in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
          </DialogContentText>
          <DialogContentText gutterBottom>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis
            lacus vel augue laoreet rutrum faucibus dolor auctor.
          </DialogContentText>
          <DialogContentText gutterBottom>
            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel
            scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus
            auctor fringilla.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MdSelectBTN classes={{ root: 'custom-select' }}>
            {modalDetails.gymScheduleValue === 'custom' &&
              <CustomBookForm
                customFormValue={customFormValue}
                handleCustomValues={handleCustomValues}
                handleDateChange={handleDateChange}
                handlerToggalCalender={handlerToggalCalender}
                toggalCalender={modalDetails.toggalCalender}
              />
            }
          </MdSelectBTN>
          {getDataAccordingSelectedButton(selectedGymDetails)}
        </DialogActions>
      </Fragment>
      :
      <span>Loading...</span>
      }
    </Dialog >
  );
};


Modal.propTypes = {
  toggalModal: PropTypes.bool,
  handlerSetToggleDetails: PropTypes.func,
  selectedGymDetails: PropTypes.object
};

export default Modal;
