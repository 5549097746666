import { connect } from 'react-redux';
import GoogleLogin from './index';
import { googleLoginRequest } from './GoogleLoginActions';
import { updateReducerState } from '../../feature/Common/CommonActions';
import { getClassTimeTableList } from '../ClassTimeTable/ClassTimeTableActions';
import { initializeUser, getUserDetails } from '../Auth/AuthActions';

const mapStateToProps = state => ({
  googleLoginLoading: state.google.googleLoginLoading,
  toggalAuthModal: state.header.toggalAuthModal
});

const mapDispatchToProps = dispatch => ({
  googleLoginRequest: (token, headerToken) => dispatch(googleLoginRequest(token, headerToken)),
  updateReducerState: (reducerKey, key, value) => dispatch(updateReducerState(reducerKey, key, value)),
  initializeUser: () => dispatch(initializeUser()),
  getUserDetails: () => dispatch(getUserDetails()),
  getClassTimeTableList: (gymLocation) => dispatch(getClassTimeTableList(gymLocation))
});

export default connect(mapStateToProps, mapDispatchToProps)(GoogleLogin);
