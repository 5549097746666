import styled from 'styled-components';
import LoginImage from '../assets/loginImg.png';

export const AuthImgWrap = styled.div`
    background-image: url(${LoginImage});
    height: 100%;
    position:relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-size: cover;
    
&::before {
    content:"";
    position:absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: 0.5;
    @media (max-width: 852px) {
        opacity:1;
    }
}

    .noAccount {
        color: #FFFFFF;
        font-size:14px;
        position: relative;
    }
    .rightWrap {
        padding-bottom:44px;
        text-align: center;
        button:hover {
            background:#ffffff;
            box-shadow:none;
        }
    }
`;

export const FormWrap = styled.div`
   &.reset-pass-form{
    button {
    background: #49589B;
    color: white;
    }
   }
    padding:48px 46px;

    @media (max-width: 768px) {
        .MuiDialog-scrollPaper{
            justify-content: initial;
        }
    }
    @media (max-width: 414px) {
        padding:48px 30px;
    }
    @media (max-width: 375px) {
        padding:48px 14px;
    }
    h2 {
       
        color: #49589B;
        font-family: Fira Sans;
      
    }
    .subhead {
        font-size: 14px;
        color: #A1A1A1;
        text-align:center;
        margin-top: 5px;
    }
    .orDivider {
        position: relative;
        font-family: Fira Sans;
        color: #A1A1A1;
        margin-top: 13px;
    }
    .orDivider::before {
        content:"";
        position: absolute;
        width: 119px;
        height: 0;
        border: 1px dashed #E0E0E0;
        left: 0;
        top: 9px;
        @media (max-width: 852px) {
            width: 44%;
        }
    }
    .orDivider::after {
        content:"";
        position: absolute;
        width: 119px;
        height: 0;
        border: 1px dashed #E0E0E0;
        right: 0;
        top: 9px;
        @media (max-width: 852px) {
            width: 44%;
        }
    }
    .pt-36 {
        padding-top:36px;
        @media (max-width: 414px) {
            padding-top:0;
        }
    }
    .password-field {
        padding-bottom:5px;
    }
    .pb-26 {
        padding-bottom:26px;
    }
    .btn-controller {
        
        @media (max-width: 414px) {
            .kep-login-facebook {
                width: 98%;
            }
            flex-direction:column;
            align-items:center;
            span {
                width: 90%;
                margin: 7px auto;
            }
        }
        button {
            @media (max-width: 414px) {
                width: 100%;
            }
            @media (max-width: 375px) {
                margin-right:6px;
            }
            span {
                padding:0 !important;
                box-shadow:none !important;
            }
            box-shadow:none !important;
        }
        img {
            position: relative;
            left: -5px;
        }
        .google-btn.MuiButton-root:hover {
            background:#d03429 !important;
            box-shadow:none;
        }
        svg {
            display:none;
        }
    }
    .kep-login-facebook {
        padding: 0 !important;
        border: none !important;
        background-color: transparent !important;
    }
`;
export const InputController = styled.div`
    padding-bottom:13px;
    position: relative;
    /* margin-bottom: 14px; */
    .MuiTypography-root {
        width: 85px;
        margin: auto;
        margin-left: 67px;
    }
    .error-message {
        position:absolute;
        top: 36px;
        left: 10px;
        background: #fff;
        width: auto !important;
        padding: 0 5px;
        margin: 0 !important;

    }
    .MuiInput-root {
        width:100%;
    }
    .MuiInput-underline:before {
        display:none;
    }
    
    input {
        width: 254px;
        margin: auto;
        height: 44px;
        border-radius: 5px;
        border: 1px solid #E0E0E0;
        padding: 0 14px;
    &::placeholder {
        font-size:14px;
    }
        @media (max-width: 852px) {
            width: 100%;
        }
    }
    .MuiInput-underline.Mui-error:after {
        border: 1px solid #f44336;
        width: auto;
        margin: auto;
        height: 44px;
        border-radius: 5px;
    }
    .error-message {
        display: flex;
        width: 303px;
        margin: 5px auto;
        font-size: 12px;
        color: #f44336;
    }
    .MuiInput-underline.Mui-focused:after {
        width: auto;
        margin: auto;
        height: 44px;
        border: 2px solid #f4bdac;
        pointer-events: none;
        border-radius: 5px;
    }
 
`;

export const BtnPrimary = styled.div`
    background: #49589B;
    border: 0;
    border-radius: 5px;
    color: white;
    height: 44px;
    font-size: 14px;
    padding: 0 30px;
    min-width: 85px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    
`;

export const BtnWhite = styled.div`
    background: #ffffff;
    border: 0;
    border-radius: 5px;
    color: #49589B;
    height: 44px;
    font-weight: 500;
    font-size: 14px;
    padding: 0 30px;
    min-width: 85px;
    text-transform: capitalize;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const BtnGoogle = styled.div`
    background: #F44236;
    border: 0;
    border-radius: 5px;
    color: #FFFFFF;
    height: 44px;
    font-weight: 500;
    font-size: 14px;
    padding: 0 30px;
    min-width: 85;
    text-transform: capitalize;
    display: flex;
    align-items: center;
`;
