import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Validator from '../../../utils/Validator';
import { Grid, CircularProgress } from '@material-ui/core';
import { InputBox } from '../../../utils/FormUtils';
import textContent from '../AuthConstants';
import { withRouter } from 'react-router-dom';
import { AuthImgWrap, FormWrap, InputController, BtnWhite, BtnPrimary } from '../components/assets/style';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import FacebookLogin from '../../FacebookLogin/FacebookLoginContainer';
import GoogleLogin from '../../GoogleLogin/GoogleLoginContainer';
import { SeoHelmet } from '../../../utils/seoHelmet';

const LoginForm = (props) => {

  // state manupulation
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: ''
  });

  const [error, setErrors] = useState({});

  // For login form validation
  const _isValid = (field = null, value) => {
    if (field) {
      loginDetails[field] = value;
    }
    const validate = Validator.createValidator(
      {
        email: ['required', 'email'],
        password: ['required', 'minLength|4']
      },
      {
        email: loginDetails.email,
        password: loginDetails.password
      },
      field,
      {
        email: '',
        password: ''
      }
    );

    return validate;
  };

  // Validations on blur
  const validateOnBlur = (name, value) => {
    const { errors } = _isValid(name, value);
    setErrors({ ...error, [name]: errors[name] });
  };

  const handleSignInClick = () => {
    const { isValid } = _isValid();
    if (isValid) {
      const { login, updateReducerState, initializeUser, getUserDetails, toggalAuthModal, getClassTimeTableList } = props;
      const loginData = {
        email: loginDetails.email,
        password: loginDetails.password
      };
      login(loginData)
        .then(response => {
          if (response && response.value && response.value.data && response.value.data.status === 'SUCCESS')
            getUserDetails()
              .then(response => {
                if (response && response.value && response.value.data && response.value.data.payload &&
                  response.value.data.payload.gymCredentials && response.value.data.payload.gymCredentials.length) {
                  initializeUser();
                }
              })
              .catch(error => {
                if (error && error.response && error.response.status &&
                  error.response.status === 401 && props && props.history && props.history.location &&
                  props.history.location.pathname === '/') {
                  updateReducerState('auth', 'isLogin', false);
                }
              });
          if (props && props.history && props.history.location &&
            props.history.location.pathname === '/time-table') {
            getClassTimeTableList('Bank');
          }
          updateReducerState('header', 'toggalAuthModal', !toggalAuthModal);
        });
    } else {
      const { errors } = _isValid();
      setErrors({ ...errors });
    }
  };

  const onChangeHandler = (value, name) => {
    setLoginDetails({ ...loginDetails, [name]: value });
    validateOnBlur(name, value);
  };

  const { email, password } = loginDetails;
  const { updateReducerState, loginLoading, facebookLoginLoading, googleLoginLoading } = props;
  return (
    <Grid container spacing={1}>
      <SeoHelmet title="Login" />
      <Grid item md={5} xs={12} className="reorder">
        <AuthImgWrap>
          <Box className="rightWrap">
            <p className="noAccount">
              Don’t have an account ?
            </p>
            <BtnWhite onClick={() => updateReducerState('header', 'authModalType', 'register')}> Register</BtnWhite>
          </Box>
        </AuthImgWrap>
      </Grid>
      <Grid item md={7} xs={12}>
        <FormWrap>
          <Typography align="center" variant="h6" component="h2">Welcome to Gymbook</Typography>
          <p className="subhead">Please login to your account</p>
          <InputController>
            <InputBox
              errorMessage={error.email}
              label={textContent.EMAIL_FIELD_LABEL}
              placeholder={textContent.EMAIL_FIELD_PLACEHOLDER}
              type={'text'}
              name={'email'}
              onChange={onChangeHandler}
              value={email}
              maxLength={30}
            />
          </InputController>
          <InputController className="password-field">
            <InputBox
              errorMessage={error.password}
              label={textContent.PASSWORD_FIELD_LABEL}
              placeholder={textContent.PASSWORD_FIELD_PLACEHOLDER}
              type={'password'}
              onChange={onChangeHandler}
              name={'password'}
              value={password}
              maxLength={30}
            />
          </InputController>
          <Typography align="right" className="pb-26">
            <Link onClick={() => updateReducerState('header', 'authModalType', 'forgot')} color="textSecondary">
              Forgot Password
            </Link>
          </Typography>
          <InputController>
            <Typography align="center">
              {loginLoading ?
                <CircularProgress />
                :
                <BtnPrimary variant="contained" color="secondary" onClick={() => handleSignInClick()}>Login</BtnPrimary>
              }
            </Typography>
          </InputController>
          <Typography className="orDivider" align="center">
            Or
          </Typography>
          <Box className="pt-36 btn-controller" display="flex" justifyContent="space-between">
            {facebookLoginLoading ?
              <CircularProgress />
              :
              <FacebookLogin />
            }
            {googleLoginLoading ?
              <CircularProgress />
              :
              <GoogleLogin />}
          </Box>
        </FormWrap>
      </Grid>
    </Grid>
  );
};

LoginForm.propTypes = {
  login: PropTypes.func,
  updateReducerState: PropTypes.func,
  initializeUser: PropTypes.func,
  getUserDetails: PropTypes.func,
  toggalAuthModal: PropTypes.bool,
  history: PropTypes.object,
  getClassTimeTableList: PropTypes.func,
  loginLoading: PropTypes.bool,
  facebookLoginLoading: PropTypes.bool,
  googleLoginLoading: PropTypes.bool
};

export default withRouter(LoginForm);
