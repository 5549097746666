import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import HeaderContainer from '../../shared/Header/HeaderContainer';
// import FooterContainer from '../../shared/Footer/FooterContainer';
import { retrieveFromLocalStorage } from '../../utils/helpers';

const Layout = props => {
  const { children, isLogin, updateReducerState, getUserDetails, userDetails, initializeUser } = props;

  useEffect(() => {
    if (!isLogin && retrieveFromLocalStorage('token')) {
      updateReducerState('auth', 'isLogin', true);
    }

    if (retrieveFromLocalStorage('token') && !userDetails) {
      getUserDetails()
        .then(response => {
          if (response && response.value && response.value.data && response.value.data.payload &&
            response.value.data.payload.gymCredentials && response.value.data.payload.gymCredentials.length) {
            initializeUser();
          }
        });
    }
     // eslint-disable-next-line
  }, []);

  return (
    <div>
      <HeaderContainer />
      {children}
      {/* <FooterContainer /> */}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.object,
  isLogin: PropTypes.bool,
  updateReducerState: PropTypes.func,
  getUserDetails: PropTypes.func,
  userDetails: PropTypes.object,
  initializeUser: PropTypes.func
};

export default Layout;
