import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Toolbar, Typography, Drawer, Backdrop, Grid, CircularProgress } from '@material-ui/core';
import { BottomBoxCover, Loginlink, HeaderButton, Logoutlink, StyledDialog } from './assets/style';
import { ModalClose } from '../Header/assets/style';
import logo from './assets/logo.svg';
import CloseIcon from '@material-ui/icons/Close';
import history from '../../utils/history';
import SideBar from './components/sideBar';
import { HeaderLogoSvg } from '../../utils/svgs';
import AuthContainer from '../../feature/Auth/AuthContainer';
import Box from '@material-ui/core/Box';
import { withRouter } from 'react-router-dom';

const Header = (props) => {
  const { logOutUser, logOutUserLoading, isLogin, updateReducerState, toggalAuthModal } = props;
  const [sideBarvisbility, setSideBarvisbility] = useState(false);

  const toggleDrawer = () => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setSideBarvisbility(!sideBarvisbility);
  };

  const handlerLogOut = () => {
    logOutUser()
      .then(resposne => {
        if (resposne && resposne.value && resposne.value.data && resposne.value.data.status === 'SUCCESS') {
          updateReducerState('auth', 'isLogin', false);
          history.push('/');
        }
      })
      .catch(error => {
        if (error && error.response && error.response.status && error.response.status === 401 &&
          props && props.history && props.history.location &&
          props.history.location.pathname === '/') {
          updateReducerState('auth', 'isLogin', false);
        }
      });
  };

  const toogalLoginModal = () => {
    updateReducerState('header', 'authModalType', 'login');
    updateReducerState('header', 'toggalAuthModal', !toggalAuthModal);
  };

  return (
    <BottomBoxCover position="fixed" classes={{ root: 'headerUI' }}>
      <Toolbar>
        <Typography variant="h6" classes={{ root: 'logo' }} onClick={() => history.push('/')}>
          <img src={logo} alt="logo" />
        </Typography>
        {!isLogin ?
          <Loginlink classes={{ root: 'custom-btn' }} onClick={toogalLoginModal}>Login</Loginlink>
          :
          logOutUserLoading ?
            <CircularProgress />
            :
            <Logoutlink classes={{ root: 'custom-btn' }} onClick={handlerLogOut}>Logout</Logoutlink>
        }

        <HeaderButton color="default" classes={{ root: 'custom-btn' }} onClick={toggleDrawer()}>
          <HeaderLogoSvg />
        </HeaderButton>
      </Toolbar>

      <Drawer anchor="right" open={sideBarvisbility} onClose={toggleDrawer()}>
        <SideBar
          toggleDrawer={toggleDrawer}
          isLogin={isLogin}
        />
      </Drawer>
      <StyledDialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        classes={{ root: 'theme-modal', paper: 'userSignModal' }}
        open={toggalAuthModal}
        onClose={toogalLoginModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        maxWidth="md"
        BackdropProps={{
          timeout: 500
        }}
      >
        <Grid item xs={12} md={12}>
          <Box position="relative">
            <ModalClose aria-label="close" onClick={toogalLoginModal}>
              <CloseIcon />
            </ModalClose>
          </Box>
          <AuthContainer />
        </Grid>
      </StyledDialog >
    </BottomBoxCover >
  );
};

Header.propTypes = {
  logOutUser: PropTypes.func,
  isLogin: PropTypes.bool,
  updateReducerState: PropTypes.func,
  toggalAuthModal: PropTypes.bool,
  history: PropTypes.object,
  logOutUserLoading: PropTypes.bool
};

export default withRouter(Header);
