import styled from 'styled-components';
import { AppBar, Button, Dialog } from '@material-ui/core';


export const BottomBoxCover = styled(AppBar)`    
    color: #313b67;
    font-size: 16px;
    line-height: 19px;
    ${'' /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important; */}
    justify-content: center !important;
    .logo {
        flex: 1;
        margin-top: 7px;
    }
    .custom-btn {
        font-family: 'Fira Sans' !important;
        text-transform: capitalize;
        font-size: 16px !important;
        margin-right: 20px;
    }
    
`;
export const Loginlink = styled(Button)``;
export const closeIcon = styled(Button)``;
export const ModalClose = styled.div`
    position:absolute;
    right: 15px;
    top: 10px;
    svg {
        cursor: pointer;
        path {
            fill: #49589B;
        }
    }
`;


export const Logoutlink = styled(Button)``;

export const HeaderButton = styled(Button)`
    background: #49589b !important;
    max-width: 45px;
    min-width: 45px !important;
    height: 45px;
`;

export const StyledDialog = styled(Dialog)`
    .userSignModal {
        width: 100%;
        flex-direction: row;
        overflow-y: inherit;
        .forgotModal {
            min-height:400px;
        }
        @media (max-width: 852px) {
            .reorder {
                order:2
            }
        }
        
    
`;
