import {
  VERIFY_USER_LOADING, VERIFY_USER_SUCCESS, VERIFY_USER_FAILURE
} from './VerifyUserConstants';
import { pushNotification } from '../../utils/notifications';

const initialState = {
  verifyUserLoading: false,
  verifyUserPlatform: '',
  verifyEmailError: false
};

const verifyUserStart = (state) => ({
  ...state,
  verifyUserLoading: true
});

const verifyUserSuccess = (state, action) => {
  // if (action && action.payload && action.payload.status && action.payload.status === 200) {
  //   pushNotification(Messages.EMAIL_VERIFY_SUCCESS, 'success', 'TOP_CENTER', 3000);
  // }
  const { payload } = action.payload.data || {};
  const verifyEmailPayload = payload?.platform || '';
  return ({
    ...state,
    verifyUserLoading: false,
    verifyUserPlatform: verifyEmailPayload,
    verifyEmailError: false
  });
};

const verifyUserFailure = (state, action) => {
  console.log('action', action);
  pushNotification('Something wentwrong!!', 'error', 'TOP_CENTER', 3000);
  return ({
    ...state,
    verifyUserLoading: false,
    verifyUserPlatform: '',
    verifyEmailError: true
  });
};

const verifyUserReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case VERIFY_USER_LOADING: return verifyUserStart(state, action);
    case VERIFY_USER_SUCCESS: return verifyUserSuccess(state, action);
    case VERIFY_USER_FAILURE: return verifyUserFailure(state, action);
    default:
      return {
        ...state
      };
  }
};

export default verifyUserReducer;
