import { connect } from 'react-redux';
import MySchedule from './index';
import { getMyScheduleList } from './MyScheduleActions';
import { getGymsList } from '../Common/CommonActions';
import { cancelClass, cancelPrebookClass } from './MyScheduleActions';

const mapStateToProps = state => ({
  myScheduleList: state.mySchedule.myScheduleList,
  myScheduleLoading: state.mySchedule.myScheduleLoading,
  gymsNameList: state.common.gymsNameList,
  cancelClassLoading: state.mySchedule.cancelClassLoading
});

const mapDispatchToProps = dispatch => ({
  getMyScheduleList: () => dispatch(getMyScheduleList()),
  getGymsList: (gymBrand) => dispatch(getGymsList(gymBrand)),
  cancelClass: (gymBrand, cancelDetails) => dispatch(cancelClass(gymBrand, cancelDetails)),
  cancelPrebookClass: (cancelDetails) => dispatch(cancelPrebookClass(cancelDetails))
});

export default connect(mapStateToProps, mapDispatchToProps)(MySchedule);
