import React from 'react';
import { TableRow, TableCell, Tooltip } from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Rating from '@material-ui/lab/Rating';
import { retrieveFromLocalStorage } from '../../../utils/helpers';
import Image1 from '../assets/01.png';
import Image2 from '../assets/02.png';
import Image3 from '../assets/03.png';

import { TableBTN, TableCloDis, TimeCol, AvatarUser } from '../assets/style.js';
import { NameArrowSvg } from '../../../utils/svgs';

const Table = (props) => {
  const { timeTableList, handlerSetToggleDetails, timeTableDates, handlerDetailModal,
    dateValue, userDetails, updateReducerState, toggalAuthModal } = props;

  const content = 'Bike & Beats is the class to make spin everyone’s thing';
  let classTimeTableData = [];
  if (dateValue) {
    classTimeTableData = timeTableList[dateValue];
  } else if (timeTableDates && timeTableDates[0] && timeTableDates[0].value) {
    classTimeTableData = timeTableList[timeTableDates[0].value];
  }

  const checkUserLogin = (details) => {
    if (!retrieveFromLocalStorage('token')) {
      updateReducerState('header', 'authModalType', 'login');
      updateReducerState('header', 'toggalAuthModal', !toggalAuthModal);
      return;
    }
    if (userDetails && userDetails.gymCredentials && userDetails.gymCredentials.length) {
      handlerDetailModal(details);
    } else {
      handlerSetToggleDetails('toggalGymBoxModal');
    }
  };

  return (
    classTimeTableData && classTimeTableData.map((details, index) => (
      <TableRow key={index} hover>
        <TableCell>
          <TimeCol>
            {details.time}
            <span>{details.duration}</span>
          </TimeCol>
        </TableCell>
        <TableCell>
          <TableCloDis>
            <strong>{details.name}</strong>
            <Rating name="half-rating" defaultValue={2.5} precision={0.2} size="small" classes={{ root: 'small-star' }} />
            <div className="TableCloDis_name">
              <NameArrowSvg />
              {details.instructorName}
            </div>
            {content.length > 50 ?
              <React.Fragment>
                <p className="TableCloDis_content">Bike & Beats is the class to make spin everyone’s thing Bike & Beats is the class to make spin everyone’s thing <span onClick={() => checkUserLogin(details)}>Read more</span></p>
              </React.Fragment>
              :
              <p className="TableCloDis_content">content</p>
            }
          </TableCloDis>
        </TableCell>
        <TableCell> {details.gymLocation}</TableCell>
        <TableCell>
          <AvatarGroup>
            <AvatarUser small alt="Remy Sharp" src={Image1} />
            <AvatarUser small alt="Remy Sharp" src={Image2} />
            <AvatarUser small alt="Remy Sharp" src={Image3} />
            <Tooltip title="Foo • Bar • Baz">
              <AvatarUser>+3</AvatarUser>
            </Tooltip>
          </AvatarGroup>
        </TableCell>
        <TableCell align="right">
          <TableBTN variant="contained" onClick={() => checkUserLogin(details)}>  {details.status} </TableBTN>
        </TableCell>
      </TableRow>
    ))
  );
};

export default Table;
