import { connect } from 'react-redux';
import ResetPassword from './index';
import { resetPasswordRequest } from './ResetPasswordActions';

const mapStateToProps = state => ({
  resetPasswordLoading: state.reset.resetPasswordLoading
});

const mapDispatchToProps = dispatch => ({
  resetPasswordRequest: (data) => dispatch(resetPasswordRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
