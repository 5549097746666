import React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';
import { SideBarNavBeforeLogin, SideBarNavAfterLogin } from '../../../utils/globalConstants';
import { DrawerSidebar } from '../assets/sideBarStyle';
import history from '../../../utils/history';

const SideBar = (props) => {

  const { toggleDrawer, isLogin } = props;
  return (
    <DrawerSidebar
      className="newUi"
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      <List>
        {isLogin ?
          SideBarNavAfterLogin.map((data, index) => (
            <ListItem button key={index + 2}>
              <ListItemText primary={data.Link} onClick={() => history.push(data.path)} />
            </ListItem>
          )) :
          SideBarNavBeforeLogin.map((data, index) => (
            <ListItem button key={index + 2}>
              <ListItemText primary={data.Link} onClick={() => history.push(data.path)} />
            </ListItem>
          ))
        }
      </List>
    </DrawerSidebar>
  );
};

SideBar.propTypes = {
  toggleDrawer: PropTypes.func,
  isLogin: PropTypes.bool
};

export default SideBar;
