import React from 'react';
import { Input } from '@material-ui/core';
import PropTypes from 'prop-types';

const InputBox = ({ type, value, label, disabled, width, autoComplete, name, placeholder, onChange, errorMessage, maxLength }) => (
  <React.Fragment>
    <Input
      error={errorMessage ? true : false}
      label={label || null}
      placeholder={placeholder || ''}
      type={type || 'text'}
      name={name}
      width={width || null}
      disabled={disabled || false}
      onChange={(e) => onChange(e.target.value, e.target.name)}
      value={value}
      maxLength={maxLength}
      autoComplete={autoComplete || 'new-password'}
    />
    <span style={{ display: errorMessage ? 'flex' : 'none' }} className="error-message">
      {errorMessage}
    </span>
  </React.Fragment>
);

InputBox.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.number,
  autoComplete: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func
};

export {
  InputBox
};
