import {
  GET_TIME_TABLE_LIST_LOADING, GET_TIME_TABLE_LIST_SUCCESS, GET_TIME_TABLE_LIST_FAILURE,
  BOOK_GYM_CLASS_LOADING, BOOK_GYM_CLASS_SUCCESS, BOOK_GYM_CLASS_FAILURE,
  PRE_BOOK_GYM_CLASS_LOADING, PRE_BOOK_GYM_CLASS_SUCCESS, PRE_BOOK_GYM_CLASS_FAILURE,
  FILTER_SELECTED_GYMS_DATA_LOADING, FILTER_SELECTED_GYMS_DATA_SUCCESS, FILTER_SELECTED_GYMS_DATA_FAILURE
} from './ClassTimeTableConstants';
import _ from 'lodash';
import { pushNotification } from '../../utils/notifications';
import Messages from '../../constants/messages';
import moment from 'moment';

const initialState = {
  timeTableArray: [],
  timeTableList: {},
  timeTableDates: [],
  timeTableLoading: false,
  bookClassLoading: false
};

const getTimeTableListStart = (state) => ({
  ...state,
  timeTableLoading: true
});

const getTimeTableListSuccess = (state, action) => {
  if (action.payload && action.payload.data && action.payload.data.payload) {
    state.timeTableArray = action.payload.data.payload;
    const timeTableListObj = _.groupBy(action.payload.data.payload, item => moment(item.gymClassStartTime).format('DD-MM-YYYY'));
    const datesArray = Object.keys(timeTableListObj);
    const arrayObj = [];
    if (datesArray.length) {
      datesArray.map((item) => {
        const momentObj = moment(item, 'DD-MM-YYYY');
        const obj = {
          week: moment(momentObj).format('dddd'),
          date: moment(momentObj).format('Do MMM, YY'),
          value: item
        };
        return arrayObj.push(obj);
      });
    }
    return ({
      ...state,
      timeTableLoading: false,
      timeTableList: timeTableListObj,
      timeTableDates: arrayObj
    });
  }
  return ({
    ...state,
    timeTableLoading: false
  });
};

const getTimeTableListFailure = (state) => ({
  ...state,
  timeTableLoading: false
});

// Book gym class
const bookGymClassStart = (state) => ({
  ...state,
  bookClassLoading: true
});

const bookGymClassSuccess = (state, action) => {
  if (action && action.payload && action.payload.data && action.payload.data.status === 'SUCCESS') {
    pushNotification(Messages.BOOK_CLASS_SUCCESS, 'success', 'TOP_CENTER', 3000);
  }
  return ({
    ...state,
    bookClassLoading: false
  });
};

const bookGymClassFailure = (state, action) => {
  if (action.payload && action.payload.response && action.payload.response.data
    && action.payload.response.data.error) {
    pushNotification(action.payload.response.data.error, 'error', 'TOP_CENTER', 3000);
  } else {
    pushNotification(Messages.BOOK_CLASS_FAILED, 'error', 'TOP_CENTER', 3000);
  }
  return ({
    ...state,
    bookClassLoading: false
  });
};

//  Pre book gym class
const preBookGymClassStart = (state) => ({
  ...state,
  bookClassLoading: true
});

const preBookGymClassSuccess = (state, action) => {
  if (action && action.payload && action.payload.data && action.payload.data.status === 'SUCCESS') {
    pushNotification(Messages.BOOK_CLASS_SUCCESS, 'success', 'TOP_CENTER', 3000);
  }
  return ({
    ...state,
    bookClassLoading: false
  });
};

const preBookGymClassFailure = (state, action) => {
  if (action.payload && action.payload.response && action.payload.response.data
    && action.payload.response.data.error) {
    pushNotification(action.payload.response.data.error, 'error', 'TOP_CENTER', 2000);
  } else {
    pushNotification(Messages.BOOK_CLASS_FAILED, 'error', 'TOP_CENTER', 2000);
  }
  return ({
    ...state,
    bookClassLoading: false
  });
};

// Filter selected gyms data
const filterSelectedGymsDataStart = (state) => ({
  ...state,
  timeTableLoading: true
});

const filterSelectedGymsDataSuccess = (state, action) => {
  if (action && action.data) {
    state.timeTableArray = action.data;
    const timeTableListObj = _.groupBy(action.data, item => moment(item.gymClassStartTime).format('DD-MM-YYYY'));
    const datesArray = Object.keys(timeTableListObj);
    const arrayObj = [];
    if (datesArray.length) {
      datesArray.map((item) => {
        const momentObj = moment(item, 'DD-MM-YYYY');
        const obj = {
          week: moment(momentObj).format('dddd'),
          date: moment(momentObj).format('Do MMM, YY'),
          value: item
        };
       return arrayObj.push(obj);
      });
    }
    return ({
      ...state,
      timeTableLoading: false,
      timeTableList: timeTableListObj,
      timeTableDates: arrayObj
    });
  }
  return ({
    ...state,
    timeTableLoading: false
  });
};

const filterSelectedGymsDataFailure = (state) => ({
  ...state,
  timeTableLoading: false
});

const classTimeTableReducer = (state = initialState, action = {}) => {
  switch (action.type) {

    case GET_TIME_TABLE_LIST_LOADING: return getTimeTableListStart(state, action);
    case GET_TIME_TABLE_LIST_SUCCESS: return getTimeTableListSuccess(state, action);
    case GET_TIME_TABLE_LIST_FAILURE: return getTimeTableListFailure(state, action);
    case BOOK_GYM_CLASS_LOADING: return bookGymClassStart(state, action);
    case BOOK_GYM_CLASS_SUCCESS: return bookGymClassSuccess(state, action);
    case BOOK_GYM_CLASS_FAILURE: return bookGymClassFailure(state, action);
    case PRE_BOOK_GYM_CLASS_LOADING: return preBookGymClassStart(state, action);
    case PRE_BOOK_GYM_CLASS_SUCCESS: return preBookGymClassSuccess(state, action);
    case PRE_BOOK_GYM_CLASS_FAILURE: return preBookGymClassFailure(state, action);
    case FILTER_SELECTED_GYMS_DATA_LOADING: return filterSelectedGymsDataStart(state, action);
    case FILTER_SELECTED_GYMS_DATA_SUCCESS: return filterSelectedGymsDataSuccess(state, action);
    case FILTER_SELECTED_GYMS_DATA_FAILURE: return filterSelectedGymsDataFailure(state, action);
    default:
      return {
        ...state
      };
  }
};

export default classTimeTableReducer;
